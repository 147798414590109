import React, { useState } from 'react'

import { dbdate, dError, get, post, tSuccess } from 'unno-comutils'
import { Checkbox, Input, InputDate } from 'unno-comutils/form'
import { Modal } from 'unno-comutils/ui'

export default function Paylot (props: any) {
    const [form, setForm] = useState<any>(null)

    // ----- ACTION

    const loadData = () => {
        if (props.id > 0) {
            get('book_paylot/get/' + props.id).then(({ ok, data }) => {
                if (ok) setForm(data)
                else props.onClose()
            })
        }
        else setForm(null)
    }

    const saveData = (c: any) => {
        const saveData = {
            dateDue: dbdate(form.dateDue),
            money: form.money,
            isPay: form.isPay ? 1 : 0,

            datePay: dbdate(form.datePay),
            moneyPay: form.moneyPay,
            moneyFine: form.moneyFine,
            fineNote: form.fineNote,
            moneyService: form.moneyService,
            moneyOver: form.moneyOver,
            moneyDiscount: form.moneyDiscount
        }

        post('book_paylot/save/' + props.id, saveData).then(d => {
            if (d.ok) {
                tSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
                props.onSave(d.id)
            }
            else dError(d)
        }).finally(c)
    }

    // ----- EVENT

    const onChange = (update: any) => setForm({ ...form, ...update })

    // ----- RENDER

    return <Modal title={'งวดที่ ' + form?.lot + (form?.lotSplit ? '/' + form.lotSplit : '')} noCloseBackdrop open={props.id !== null} onClose={props.onClose}
                  onOpenEnd={loadData} onCloseEnd={() => setForm({})} footerSave={saveData}>
        {!!form && <>
            <div className="row">
                <InputDate value={form.dateDue} label={'วันที่นัด'} classBox="col w-4/12" onChange={(dateDue) => onChange({ dateDue })}/>
                <Input value={form.money} label={'ยอดเงินนัด'} classBox="col w-4/12" right type="number" unit="บาท" onChange={(money) => onChange({ money })}/>
                <div className="ml-auto"><Checkbox text={'จ่ายแล้ว'} checked={form.isPay} onChange={isPay => onChange({ isPay })}/></div>
            </div>
            {form.isPay && <>
                <div className="row">
                    <InputDate value={form.datePay} label={'วันที่จ่าย'} classBox="col w-4/12" onChange={(datePay) => onChange({ datePay })}/>
                    <Input value={form.moneyPay} label={'ยอดจ่าย'} classBox="col w-4/12" right type="number" unit="บาท" onChange={(moneyPay) => onChange({ moneyPay })}/>
                </div>

                <div className="row">
                    <Input value={form.moneyFine} label={'ค่าปรับ'} classBox="col w-4/12" right type="number" unit="บาท" onChange={(moneyFine) => onChange({ moneyFine })}/>
                    <Input value={form.fineNote} label={'หมายเหตุค่าปรับ'} classBox="col w-8/12" multiline onChange={(fineNote) => onChange({ fineNote })}/>
                </div>

                <div className="row">
                    <Input value={form.moneyService} label={'ค่าบริการ'} classBox="col w-4/12" right type="number" onChange={(moneyService) => onChange({ moneyService })}/>
                    <Input value={form.moneyOver} label={'เงินเกิน'} classBox="col w-4/12" right type="number" onChange={(moneyOver) => onChange({ moneyOver })}/>
                    <Input value={form.moneyDiscount} label={'ส่วนลด'} classBox="col w-4/12" right type="number" onChange={(moneyDiscount) => onChange({ moneyDiscount })}/>
                </div>
            </>}
        </>}
    </Modal>
}