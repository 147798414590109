import React, { useEffect, useMemo, useState } from 'react'

import { clsNames, date, dbdate, dConfirm, debounce, dError, dSuccess, get, num, post, qsget, qsset, tSuccess, useUser } from 'unno-comutils'
import { Button, ButtonSave, createPaging, Icon, Modal, PageTitle, Paging, Wait } from 'unno-comutils/ui'
import { Checkbox, fromRangeParam, InputDateRange, InputSearchRow, Select, textRange } from 'unno-comutils/form'
import Dropdown, { DropdownItem } from 'unno-comutils/Dropdown'
import Datagrid, { columnIconClick, IDatagridColumns } from 'unno-comutils/Datagrid'

import { hhash, hhashRemove, hhashUpdate } from '../../utils'
import { useMeta } from '../../app'
import { DEFAULT_DOCUMENT } from '../../service/var.default'
import { documentChange, documentFormat } from '../../service/book'
import { CUSTOMER_GENDERs, DOCUMENT_PHOTOs } from '../../service/var'
import { ButtonCell } from '../../components/cell'
import { CustomerFormPay, CustomerPaySuccess, DEFAULT_PAY_SUCCESS } from '../account/customer'

import { FormAttach, FormBook, FormCustomer, FormInsurance, FormPay, FormPaylot, FormVehicle, ViewPaylot } from './components'
import { onChangeCustomer } from './components/customer'
import { calAge, dataBookSave } from './data'
import { FormEtc } from './components/etc'
import { FormOlddoc } from './components/olddoc'
import DetailItem from '../../components/test/DetailItem'
import { FormLookup } from './components/lookup'
import { HistoryModal, PrintView } from './view'
import InputSearch from '../../components/test/InputSearch'

export const visBlock = (doc: any) => [0, 1, 9, 10].indexOf(doc?.state?.id || 0) < 0

export default function BookWait () {
    const meta = useMeta()
    const user = useUser()

    const [wait, setWait] = useState(true)
    const [lists, setLists] = useState([])
    const [paging, setPaging] = useState(createPaging(1))

    const [tags, setTags] = useState<any>([])
    const [search, setSearch] = useState('')
    const [searchDate, setSearchDate] = useState<any>('')
    const [searchAgent, setSearchAgent] = useState<any>('')
    const [searchEmployee, setSearchEmployee] = useState<any>('')
    const [searchState, setSearchState] = useState<any>('')

    const [form, setForm] = useState<any>(null)
    const [formPay, setFormPay] = useState<any>(null)

    const [formPrint, setFormPrint] = useState(0)
    const [formRequestSign, setFormRequestSign] = useState(null)
    const [formSendDoc, setFormSendDoc] = useState(null)
    const [formPrintOld, setFormPrintOld] = useState(null)

    const [paySuccess, setPaySuccess] = useState<any>(DEFAULT_PAY_SUCCESS)

    // ----- ACTION

    const loadList = debounce((page?: any) => {
        setWait(true)
        if (!page) page = paging
        const qs = { page: page?.page || 1, search: search, date: dbdate(searchDate), agent: searchAgent?.id, employee: searchEmployee?.id, state: searchState?.id }
        get('book/list', { ...qs, wait: 1 }).then(({ ok, datas, paging }) => {
            if (ok) {
                const h = hhash()
                if (!h) qsset(qs)

                const tags = []
                if (searchDate) tags.push({ color: 'blue', text: 'วันที่แจ้งงาน ' + textRange(searchDate), onClear: () => setSearchDate('') })
                if (searchAgent) tags.push({ icon: 'user-secret', text: 'ตัวแทน ' + searchAgent.name, onClear: () => setSearchAgent('') })
                if (searchEmployee) tags.push({ icon: 'user-tie', text: 'พนักงาน ' + searchEmployee.name, onClear: () => setSearchEmployee('') })
                if (searchState) tags.push({ icon: 'check-square', text: 'สถานะ ' + searchState.name, onClear: () => setSearchState('') })

                setTags(tags)

                setLists(datas)
                setPaging(paging)
            }
        }).finally(() => setWait(false))
    }, 500)

    const dHash = debounce(() => {
        const h = hhash()
        if (h && h.type === 'wait_edit') setForm(h.id)
    }, 500)

    const deletaData = (id: any) => {
        dConfirm('ยืนยันการลบข้อมูลนี้').then(() => {
            get('book/drop/' + id).then(d => {
                if (d.ok) {
                    loadList()
                    setForm(null)
                }
                else dError(d)
            })
        })
    }

    const saveData = (pay: any) => {
        setFormPay(null)
        setPaySuccess(pay)
    }

    const bookTransfer = (id: any) => {
        if (user.allow('book.wait.transfer')) {
            dConfirm('ยืนยันการโอนข้อมูลไปสมุดรายวัน').then(() => {
                setWait(true)
                post('book_wait/transfer/' + id, null).then(d => {
                    if (d.ok) {
                        loadList()
                        if (d.id > 0) {
                            tSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
                            setFormPay(d.id)
                        }
                        else {
                            dSuccess('บันทึกข้อมูลเรียบร้อยแล้ว และรายการนี้ทำจ่ายไปแล้ว')
                        }
                    }
                    else dError(d)
                }).finally(() => setWait(false))
            })
        }
    }

    const requestPaylot = (id: any) => {
        dConfirm('ยืนยันการสร้าง งวดจ่าย และส่งไลน์หาลูกค้า').then(() => {
            get('book_wait/request_pay/' + id).then(d => {
                if (d.ok) {
                    tSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
                }
                else dError(d)
            })
        })
    }

    // ----- MEMO

    useEffect(() => {
        const qs = qsget()
        const { agent, employee, state } = qs
        if (qs.search) setSearch(qs.search)
        if (qs.page) setPaging(createPaging(num(qs.page)))
        if (qs.date) setSearchDate(fromRangeParam(qs.date))

        if (agent || employee) {
            get('app/qs', { agent, employee }).then(d => {
                if (d.ok) {
                    if (d.agent) setSearchAgent(d.agent)
                    if (d.employee) setSearchEmployee(d.employee)
                }
            })
        }

        dHash()
        // if (isDev) setForm(2651)
    }, [])

    useEffect(() => {
        loadList()
    }, [searchDate, searchAgent, searchEmployee, searchState])

    // ----- RENDER

    const tableDatas = useMemo(() => {
        return lists.map((d: any) => ({
            ...d,
            state: d.stateName,
            date: date(d.date),
            agent: d.agentName,
            employee: d.employeeName,
            metaInsuretype: d.insuretypeName,
            company: d.companyName,
            dateStart: date(d.dateStart, 'S'),
            dateEnd: date(d.dateEnd, 'S'),
            moneyTotal: num(d.moneyTotal, 2),
            customer: d.customerName,
            customerGender: !!d.customerPayGender ? (CUSTOMER_GENDERs.find(g => g.id === d.customerPayGender)?.name || '-') : '-',
            customerAge: !!d.date && !!d.customerPayBirth ? calAge(d.date, d.customerPayBirth) : '-',
        }))
    }, [lists])

    const tableColumns = useMemo<IDatagridColumns[]>(() => {
        const columns: any = [
            { name: '_rownum', label: '#', width: 40, style: 'c', pinned: 'left' },
            { name: 'state', label: 'สถานะ', width: 130, style: 'c' },

            //{ name: 'btn_print_old', label: '', width: 80, renderer: (_: any, d: any) => <Button onClick={() => setFormPrintOld(d.id)}>พิมพ์เก่า</Button> },

            { name: 'btn_req_sign', label: '', width: 110, renderer: (_: any, d: any) => <Button success onClick={() => setFormRequestSign(d.id)}>ส่งขอลายเซ็นต์</Button> },
            { name: 'btn_sendcomp', label: '', width: 120, renderer: (_: any, d: any) => <Button primary onClick={() => setFormSendDoc(d.id)}>แจ้งงาน บ.ประกัน</Button> },
            { name: 'btn_req_pay', label: '', width: 110, renderer: (_: any, d: any) => <Button success onClick={() => requestPaylot(d.id)}>ส่งไลน์จ่ายเงิน</Button> },
            user.allow('book.wait.docprint')
            && { name: 'btn_print', label: '', width: 60, renderer: (_: any, d: any) => <Button onClick={() => setFormPrint(d.id)}>พิมพ์</Button> },

            { name: 'date', label: 'วันที่แจ้งงาน', width: 100, style: 'c', follow: true },
            { name: 'customer', label: 'ชื่อลูกค้า', width: 250, follow: true },
            {
                name: 'rooms',
                label: 'ห้องแชท',
                width: 70,
                renderer: (_: any, d: any) => d.rooms.map((room: any) => <Icon key={'room_' + room.id} className={'mx-1'}
                                                                               name={room.provider.provider === 'fb' ? 'facebook blue' : 'line green'}
                                                                               brand href={'/chat/' + room.id} targetBlank/>)
            },
            { name: 'customerGender', label: 'เพศผู้ผ่อน', width: 70, style: 'c' },
            { name: 'customerAge', label: 'อายุผู้ผ่อน', width: 70, style: 'c' },
            { name: 'customerType', label: 'ประเภทลูกค้า', width: 150, style: 'c' },
            { name: 'employee', label: 'พนักงาน', width: 90 },
            { name: 'agent', label: 'ตัวแทน', width: 90 },
            { name: 'vehicleLicense', label: 'ทะเบียน', width: 120, style: 'c', follow: true },
            { name: 'metaInsuretype', label: 'ประเภทประกัน', width: 150, style: 'c', follow: true },

            { name: 'carBrand', label: 'ยี่ห้อ', width: 100, style: 'c' },
            { name: 'carGeneration', label: 'รุ่น', width: 100, style: 'c' },
            { name: 'carYear', label: 'ปี', width: 70, style: 'c' },
            { name: 'carCc', label: 'CC', width: 70, style: 'c' },
            { name: 'carSubGeneration', label: 'รุ่นย่อย', width: 200 },

            { name: 'company', label: 'บริษัทประกัน', width: 270, follow: true },
            { name: 'dateStart', label: 'เริ่มคุ้มครอง', width: 100, style: 'c' },
            { name: 'dateEnd', label: 'สิ้นสุดคุ้มครอง', width: 100, style: 'c' },
            { name: 'moneyTotal', label: 'เบี้ยประกันภัยรวม', width: 110, style: 'r', type: 'money' },
            { name: 'paytype', label: 'วิธีชำระ', width: 80, style: 'c' },
        ].filter(Boolean)

        if (user.allow('book.wait.transfer')) {
            columns.push({
                name: 'btn_transfer',
                label: '',
                width: 120,
                renderer: (_: any, d: any) => <ButtonCell value={d} state={'green'} text={'โอนไปสมุดรายวัน'} hot-renderer onClick={bookTransfer}/>
            })
        }

        columns.push(columnIconClick({ name: 'btn_edit', icon: 'cog', pinned: 'right', onClick: (_, p: any) => setForm(p.id) }))

        return columns
    }, [user])

    const urlParam = qsset({ search: search, date: dbdate(searchDate), agent: searchAgent?.id, employee: searchEmployee?.id, state: searchState?.id, }, true)

    return <>
        <PageTitle icon="feather" title="งานขายใหม่">
            <InputSearch tags={tags} value={search} onChange={setSearch} onRefresh={() => loadList()} onPressEnter={loadList} refreshButton={{ label: 'ตกลง' }}>
                <InputSearchRow label="วันที่แจ้งงาน" children={<InputDateRange icon fixWidth value={searchDate} onChange={setSearchDate}/>}/>
                <InputSearchRow label="ตัวแทน" children={<Select value={searchAgent} url="/ac/agent" onChange={(_, v) => setSearchAgent(v)}/>}/>
                <InputSearchRow label="พนักงาน" children={<Select value={searchEmployee} url="/ac/employee" onChange={(_, v) => setSearchEmployee(v)}/>}/>
                <InputSearchRow label="สถานะ" children={<Select value={searchState} options={meta?.DOCUMENT_STATEs || []} onChange={(_, v) => setSearchState(v)}/>}/>
            </InputSearch>

            {user.allow('book.wait.export_summary') && searchDate && <Dropdown right button={<Button secondary icon={'download'} className="ml-4"/>}>
                {/*<DropdownItem icon={filePdf} label={'สรุปคำสั่งซื้อ'} href={'/d/book_wait.pdf?' + urlParam} targetBlank/>*/}
                <DropdownItem icon={'file-spreadsheet green'} label={'สรุปคำสั่งซื้อ'} href={'/d/book_wait.xlsx?' + urlParam} targetBlank/>
                <DropdownItem icon={'file-spreadsheet green'} label={'ส่งออกคำสั่งซื้อ'} href={'/a/book_wait/book_export?' + urlParam} targetBlank/>
            </Dropdown>}

            <Button success className="ml-3" onClick={() => setForm(0)}>เพิ่มรายการใหม่</Button>
        </PageTitle>

        <Datagrid wait={wait} name={'book_wait'} datas={tableDatas} columns={tableColumns}/>

        <Paging paging={paging} onChange={loadList}/>

        <BookWaitForm id={form} onSave={loadList} onDrop={deletaData} open={!!form} onClose={() => setForm(null)}/>

        <CustomerFormPay id={formPay} onSave={saveData} onClose={() => setFormPay(null)}/>
        <CustomerPaySuccess {...paySuccess} onClose={() => setPaySuccess(DEFAULT_PAY_SUCCESS)}/>

        <PrintView id={formPrint} onClose={() => setFormPrint(0)}/>

        <FormRequestSign id={formRequestSign} onClose={() => setFormRequestSign(null)}/>
        <FormSendCompanyDoc id={formSendDoc} onClose={() => setFormSendDoc(null)}/>

        <FormPrintOld id={formPrintOld} onClose={() => setFormPrintOld(null)}/>
    </>
}

function FormRequestSign (props: { id: number | null, onClose: () => void }) {
    const [signed, setSigned] = useState([])

    const onWaitSign = (customer: number) => {
        dConfirm('ยืนยันการส่งข้อมูล').then(() => {
            post('book/send_wait_sign/' + props.id, { customer, docs: signed }).then((d: any) => {
                if (d.ok) {
                    tSuccess('ส่งไลน์เรียบร้อยแล้ว')
                    props.onClose()
                }
            })
        })
    }

    const SIGN_ITEMs = useMemo(() => [
        { value: 'req', name: 'ใบคำขอทำประกัน' },
        { value: 'poa', name: 'หนังสือมอบอำนาจ' },
        { value: 'paylot', name: 'ใบคำขอแบ่งชำระ' },
        { value: 'cancel', name: 'ใบขอยกเลิกกรมธรรม์' },
        { value: 'checkdoc', name: 'บันทึกตรวจสภาพรถ' },
        { value: 'deduct', name: 'หนังสือทราบเงื่อนไขดีดักท์' },
    ], [])

    return <Modal title={'ขอลายเซ็นต์'} sm open={props.id !== null} onClose={() => props.onClose()} noCloseBackdrop
                  footer={<>
                      <Button primary className={'flex-1'} onClick={() => onWaitSign(1)}>เจ้าของ</Button>
                      <Button primary className={'flex-1 ml-2'} onClick={() => onWaitSign(2)}>ผู้ผ่อน</Button>
                      <Button primary className={'flex-1 ml-2'} onClick={() => onWaitSign(3)}>เจ้าของ + ผู้ผ่อน</Button>
                  </>}>
        <Checkbox m0 checked={signed} items={SIGN_ITEMs} onChange={setSigned} vertical/>
    </Modal>
}

function FormSendCompanyDoc (props: any) {
    const [wait, setWait] = useState(false)

    const [data, setData] = useState<any>(null)

    const loadData = () => {
        setWait(true)
        get('book/get/' + props.id).then((d: any) => {
            if (d.ok) {
                if (d.data.company) {
                    const dDOCUMENT_PHOTOs = [{ key: 'customer_idcard', label: 'บัตรประชาชน' }, ...DOCUMENT_PHOTOs.map((o: any) => ({
                        ...o,
                        label: (o.vehicle ? 'ภาพรถ ' : '') + o.label
                    }))]
                    const dDOCUMENT_PHOTO_FILEs = d.data.photos
                    if (d.data.customer && d.data.customer.photos && d.data.customer.photos.length > 0)
                        dDOCUMENT_PHOTO_FILEs.push({ ...d.data.customer.photos[0], tag: 'customer_idcard' })

                    const { sendDocuments } = d.data.company
                    setData({
                        vehicleLicense: d.data.vehicleLicense,
                        metaInsuretype: d.data.metaInsuretype,
                        company: d.data.company.name,
                        sendEmail: d.data.company.sendEmail,
                        sendDocuments: dDOCUMENT_PHOTOs.filter((o: any) => sendDocuments.indexOf(o.key) >= 0).map((o: any) => ({
                            ...o,
                            photos: dDOCUMENT_PHOTO_FILEs?.filter((file: any) => file.tag === o.key)
                        })) || []
                    })
                }
                else {
                    setData(null)
                }
            }
        }).finally(() => setWait(false))
    }

    const sendData = (c: any) => {
        dConfirm('ยืนยันการส่งเมล์').then(() => {
            post('book/send_email/' + props.id, { sendDocuments: data.sendDocuments }).then((d: any) => {
                if (d.ok) {
                    tSuccess('ส่งแล้ว')
                }
            }).finally(c)
        }).catch(c)
    }

    const onClose = () => {
        setData(null)
    }

    return <Modal title={'ส่งเอกสารบริษัทประกัน'} sm open={props.id !== null} onClose={props.onClose} onOpenEnd={loadData} onCloseEnd={onClose}
                  footer={data && data.sendEmail && <ButtonSave onSave={sendData}>ส่งเมล์</ButtonSave>}>
        {wait && <Wait/>}
        {data && <>
            <div className="book-company-senddoc-detail -orange">
                <DetailItem label={'ทะเบียน'}>{data.vehicleLicense}</DetailItem>
                <DetailItem label={'ประเภท'}>{data.metaInsuretype?.name || '?'}</DetailItem>
            </div>
            <div className="book-company-senddoc-detail">
                <DetailItem label={'บริษัท'}>{data.company}</DetailItem>
                <DetailItem label={'อีเมล์/ไลน์'} className="_pills">{data.sendEmail.split(',').map((s: string, x: number) => {
                    if (s.includes('@')) return <span key={'s_' + x} className="_pill-email" title={'Email: ' + s}>{s}</span>
                    if (s.length > 30) return <span key={'s_' + x} className="_pill-line" title={'Line: ' + s}>{s}</span>
                    return null
                }).filter(Boolean)}</DetailItem>
            </div>
            {data.sendDocuments.map((d: any) => <DetailItem key={'item_' + d.key} label={d.label} className="book-company-senddoc">
                {d.photos.length > 0 ? d.photos.map((o: any, x: number) => <a key={'p_' + x} href={o.url} target="_blank">{o.name}</a>) : <span className="red">ไม่มีข้อมูล</span>}
            </DetailItem>)}
        </>}
    </Modal>
}

function FormPrintOld (props: { id: number | null, onClose: () => void }) {
    const [checked, setChecked] = useState([])
    const [hasSign, setHasSign] = useState(false)
    const [isSign, setIsSign] = useState(false)

    // ----- ACTION

    // ----- EVENT

    const onPrintOpen = () => {
        setHasSign(false)
        get('book/check_sign/' + props.id).then((d: any) => setHasSign(d.ok))
    }

    // ----- MEMO

    const PRINT_ITEMs = useMemo(() => [
        { value: 'req', name: 'ใบคำขอทำประกัน ป.1' },
        { value: 'req_biz', name: 'ใบคำขอทำประกัน ป.1 (ส่งบริษัท)' },
        { value: 'req2p', name: 'ใบคำขอทำประกัน ป.2+' },
        { value: 'req2p_biz', name: 'ใบคำขอทำประกัน ป.2+ (ส่งบริษัท)' },
        { value: 'req3', name: 'ใบคำขอทำประกัน ป.3' },
        { value: 'req3_biz', name: 'ใบคำขอทำประกัน ป.3 (ส่งบริษัท)' },
        { value: 'req3p', name: 'ใบคำขอทำประกัน ป.3+' },
        { value: 'req3p_biz', name: 'ใบคำขอทำประกัน ป.3+ (ส่งบริษัท)' },
        //{ value: 'req_smk', name: 'ใบแจ้งงาน บ.สหมงคล' },
        { value: 'paylot', name: 'แบบฟอร์มใบคำขอแบ่งชำระ (เจ้าของรถ)' },
        { value: 'paylot_poa', name: 'แบบฟอร์มใบคำขอแบ่งชำระ (+ผู้ผ่อน)' },
        { value: 'poa_biz', name: 'หนังสือมอบอำนาจ (เจ้าของรถ ให้ บ.)' },
        { value: 'poa', name: 'หนังสือมอบอำนาจ (+ผู้ผ่อน)' },
        { value: 'checkdoc', name: 'บันทึกตรวจสภาพรถ (เจ้าของรถ)' },
        { value: 'checkdoc_poa', name: 'บันทึกตรวจสภาพรถ (+ผู้ผ่อน)' },
        { value: 'checkup', name: 'ใบตรวจสภาพรถ' },
        { value: 'checkup_mt', name: 'ใบตรวจสภาพรถ บ.มิตรแท้' },
        { value: 'checkup_chubb', name: 'ใบตรวจสภาพรถ CHUBB' },
        //{ value: 'checkup_akn', name: 'ใบตรวจสภาพรถ บ.อาคเนย์' },
        { value: 'cancel', name: 'หนังสือขอยกเลิกกรมธรรม์ (เจ้าของรถ)' },
        { value: 'cancel_poa', name: 'หนังสือขอยกเลิกกรมธรรม์ (+ผู้ผ่อน)' },
        { value: 'deduct', name: 'หนังสือทราบเงื่อนไขดีดักท์' },
        { value: 'deduct_mt', name: 'หนังสือทราบเงื่อนไขดีดักท์ มิตรแท้' },
        //{ value: 'confirm', name: 'เอกสารยืนยันความคุ้มครอง ป.1' },
    ], [])

    // ----- RENDER

    const footer = <>
        {!!checked && checked.length > 0 && <>
            <Button dark className="" href={'/d/document_contact.pdf?id=' + props.id + '&show=' + checked.join(',') + (isSign ? '&sign=1' : '')} targetBlank>PDF</Button>
            <Button dark className="ml-2" href={'/d/document_contact.pdf?id=' + props.id + '&show=' + checked.join(',') + (isSign ? '&sign=1' : '') + '&img=1'}
                    targetBlank>รูปภาพ</Button>
            {hasSign && <Checkbox className="ml-2" text={'ลายเซ็นต์'} checked={isSign} onChange={setIsSign}/>}
        </>}
    </>

    return <>
        <Modal title={'พิมพ์สัญญา'} open={props.id != null} onOpenEnd={onPrintOpen} onClose={props.onClose} noCloseBackdrop footer={footer}>
            {!!props.id && <Checkbox m0 checked={checked} items={PRINT_ITEMs} onChange={setChecked} vertical/>}
        </Modal>
    </>
}

// -----

export function BookWaitForm (props: any) {
    const meta = useMeta()
    const user = useUser()

    const [form, setForm] = useState<any>(null)
    const [formPay, setFormPay] = useState<any>(null)

    const [history, setHistory] = useState(null)

    const [paySuccess, setPaySuccess] = useState<any>(DEFAULT_PAY_SUCCESS)

    const [sideUpdate, setSideUpdate] = useState<any>(null)

    const loadData = () => {
        if (props.id > 0) {
            get('book_wait/get/' + props.id).then(({ ok, data }) => {
                if (ok) {
                    documentFormat(data)
                    if (data.__payMeta && data.metaPaylot)
                        data.__payMeta.rate = data.metaPaylot.rate
                    data.__paylotCount = data.paylotCount
                    data.wait = true
                    setForm(data)
                    setSideUpdate(null)
                    hhashUpdate('wait_edit,' + data.id)
                }
            })
        }
        else {
            const data: any = DEFAULT_DOCUMENT()
            data.wait = true
            if (props.customer) {
                data.customer = props.customer
            }
            else if (!user.allow('book.wait.customer')) {
                data.customer = { _create: 'customer' }
                data.customerPay = { _create: 'customerPay' }
            }
            setSideUpdate(null)
            setForm(data)
        }
    }

    const saveData = (check = false, newDoc = false, c?: () => void) => {
        let data = form
        data.check = check
        data.sideUpdate = sideUpdate

        const saveData = dataBookSave(data)
        post('book/save_wait/' + (newDoc ? 0 : props.id), { ...saveData, verify: 'verify_book_order' }).then(d => {
            if (d.ok) {
                tSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
                props.onSave(d.id)
                props.onClose()
                onClose()
                if (d.transferId) setFormPay(d.transferId)
            }
            else dError(d)
        }).finally(c)
    }

    const saveDataPaySuccess = (pay: any) => {
        setFormPay(null)
        setPaySuccess(pay)
    }

    // ----- EVENT

    const onChange = (update: any, method = '') => {
        let _doc = { ...form, ...update }
        _doc = !!method ? documentChange(_doc, update, method, { COMPANYs: meta.COMPANYs, formDate: _doc.date }) : _doc
        if (update.hasOwnProperty('paylots'))
            _doc.__edit = true
        setForm({ ..._doc })
    }

    const onSaveNew = () => {
        dConfirm('ยืนยันการบันทึกเป็นรายการใหม่').then(() => {
            saveData(false, true)
        })
    }

    const onSaveTransfer: any = () => {
        if (user.allow('book.wait.transfer')) {
            dConfirm('ยืนยันการโอนข้อมูลไปสมุดรายวัน').then(() => {
                saveData(true)
            })
        }
    }

    const onClose = () => {
        hhashRemove()
        setForm(null)
    }

    // ----- MEMO

    const isAdmin = useMemo(() => user.roles.indexOf('__admin') >= 0, [user])

    const visibleBlock = useMemo(() => visBlock(form), [form])

    const userAllow = useMemo(() => !user.allow('book.wait.customer'), [user])

    // ----- RENDER

    const footer = <>
        <Button icon={'trash'} error onClick={() => props.onDrop(form.id)}>ลบ</Button>
        {isAdmin && <Icon button name={'history'} className="ml-2" onClick={() => setHistory(form.id)}/>}

        {!props.sideBar && user.allow('book.wait.transfer')
            && <Button icon={'share-square'} primary outline className="ml-auto mr-2" onClick={() => onSaveTransfer(true)}>บันทึกและส่งไปสมุดรายวัน</Button>}
        {!props.sideBar && <Button icon={'save'} success className="mr-2 -right" onClick={onSaveNew}>บันทึกเป็นรายการใหม่</Button>}
    </>

    const modalProps: any = {
        title: props.id > 0 ? 'แก้ไขเอกสาร' : 'เพิ่มเอกสารใหม่',
        noCloseBackdrop: true,
        open: props.id !== null,
        className: clsNames('document-wait-modal', props.sideBar && 'chat-sidebar'),
        onClose: props.onClose,
        onOpenEnd: loadData,
        onCloseEnd: onClose,
        footer: form && form.id > 0 && footer,
        footerSave: (c: () => void) => saveData(false, false, c)
    }

    if (props.sideBar) {
        modalProps.noBackdrop = true
        modalProps.openEffect = 'right'
        modalProps.startPosition = { right: 0, top: 0 }
        modalProps.className = 'chat-sidebar'
    }

    return <>
        <Modal xl {...modalProps}>
            {!!form && <>
                <FormBook wait noExpress document={form} onChange={onChange}/>
                <FormCustomer wait document={form} force={userAllow} onChange={onChange} onChangeCustomer={(customer: any) => onChangeCustomer(customer, setSideUpdate)}/>
                <FormLookup document={form} onChange={onChange}/>
                <FormVehicle create wait document={form} onChange={onChange}/>
                <FormOlddoc oldData={form?.oldData} onChange={onChange}/>
                {visibleBlock && <FormInsurance create wait document={form} onChange={onChange}/>}
                {visibleBlock && <FormPay create document={form} onChange={onChange}/>}
                {visibleBlock && (form.paylotPrepare
                    ? <FormPaylot document={form} onChange={onChange}/>
                    : <ViewPaylot wait document={form} datas={form?.paylots || []}/>)}
                <FormAttach create wait document={form} onChange={onChange}/>
                {visibleBlock && <FormEtc create wait document={form} onChange={onChange}/>}
            </>}
        </Modal>

        <CustomerFormPay id={formPay} onSave={saveDataPaySuccess} onClose={() => setFormPay(null)}/>
        <CustomerPaySuccess {...paySuccess} onClose={() => setPaySuccess(DEFAULT_PAY_SUCCESS)}/>
        <HistoryModal name={history ? 'doc_' + history : null} onClose={() => setHistory(null)}/>
    </>
}
