import React from 'react'

import { clsNames, date, num } from 'unno-comutils'
import { Checkbox, InputDate, Select } from 'unno-comutils/form'
import { Button, Icon } from 'unno-comutils/ui'

import { CustomerView } from './view'
import { customerChange } from '../service/book'

export function TextNew (props: any) {
    const { value } = props

    if (props.cellProperties && props.cellProperties.row < 1) {
        props.TD.className = '-header'
        return <div className={'c'}>{value}</div>
    }
    if (props.cellProperties)
        props.TD.className = props.cellProperties.className
    return <div className={'c ' + (value ? ' green' : ' blue')}>{value ? 'งานใหม่' : 'ต่ออายุ'}</div>
}

export function BookForm (props: any) {
    const { data, setForm, onChange, onCustomer } = props

    const onCustomerChange = (data: any, met: any) => {
        if (met === 'customer' || met === 'customerPay') {
            customerChange(data).then(customer => {
                const update: any = {}
                update[met] = customer
                setForm((prev: any) => ({ ...prev, ...update }))
            })
        }
    }

    return <>
        <div className="row">
            <InputDate icon noClear label="วันที่" classBox="col w-2/12" value={data.date} onChange={v => onChange({ date: v })}/>
            <Select label="ตัวแทน" url={'ac/agent'} classBox="col w-2/12" value={data.agent} onChange={(id, data) => onChange({ agent: data })}/>
            <Select label="พนักงาน" url={'ac/employee'} classBox="col w-2/12" value={data.employee} onChange={(id, data) => onChange({ employee: data })}/>

            {!props.noExpress && <Select label="ใบเสร็จเร่งด่วน" classBox={'col w-4/12 ml-auto'}
                                         set={'acs'}
                                         url={'ac/document_express'} value={data.express}
                                         onChange={(id, data) => onChange({ express: data, customer: data?.customer })}/>}
        </div>

        <div className="row -mt-4 mb-6">
            <div className="col w-6/12">
                <div className="mb-2 grey-blue">ลูกค้า</div>
                <div className="flex items-start no-boxmargin">
                    <Select url={'ac/customer'} className="w-fill" value={data.customer} onChange={(_, v) => onCustomerChange(v, 'customer')}/>
                    <Button icon="plus" success className="btn-icon ml-2" onClick={() => onCustomer({ type: 1, data: 0 })}/>
                </div>
                {data.customer?.number && <CustomerView customer={data.customer} onEdit={(id: any) => onCustomer({ type: 1, data: id })}/>}
            </div>
            <div className="col w-6/12">
                <div className="mb-2 grey-blue">ผู้ผ่อน</div>
                <div className="flex items-start no-boxmargin">
                    <Checkbox text="เหมือนลูกค้า" className="mr-2" checked={data.sameCustomer || false} onChange={v => onChange({ sameCustomer: !!v })}/>
                    {!data.sameCustomer && <>
                        <Select url={'ac/customer'} className="w-fill" value={data.customerPay} onChange={(_, v) => onCustomerChange(v, 'customerPay')}/>
                        <Button icon="plus" success className="btn-icon ml-2" onClick={() => onCustomer({ type: 2, data: 0 })}/>
                    </>}
                </div>
                {!data.sameCustomer && data.customerPay?.number && <CustomerView customer={data.customerPay} onEdit={(id: any) => onCustomer({ type: 2, data: id })}/>}
            </div>
        </div>
    </>
}

export function BookDocumentPaylot (props: any) {
    const { document, datas } = props
    const renderPaymentStatus = (p: any) => {
        if (p.isPay)
            return <div className={'_status green'}>จ่ายแล้ว</div>
        return <div className={'_status red'}>ยังไม่จ่าย</div>
    }

    return <div className={clsNames('document-paylot', props.className)}>
        <div className={'_header'}>
            <div className={'_count -lot_' + document.paylotCount}>{document.paylotCount > 1 ? `ผ่อน ${document.paylotCount} งวด` : 'เงินสด'}</div>
            <div className="_summary">
                จ่ายแล้ว <span className={'green'}>{num(document.paylotMoneyPay, 2)}</span> บาท ,
                จาก <span className={'blue'}>{num(document.paylotMoney, 2)}</span> บาท ,
                ค้างจ่าย <span className={'red'}>{num(document.paylotMoney - document.paylotMoneyPay, 2)}</span> บาท
            </div>
        </div>

        <div className="_lots">
            <div className={'_head'}>
                <div className={'_lot'}>งวดที่</div>
                <div className={'_due'}>นัดชำระ</div>
                <div className={'_pay'}>จ่าย</div>
                <div className={'_note'}>หมายเหตุ</div>
            </div>
            {datas && datas.map((p: any) => {
                const payDetails = []

                let moneyNet = p.moneyNet
                if (p.moneyPay) payDetails.push(`ค่าเบี้ยประกัน ${num(p.moneyPay, 2)} บาท`)
                if (p.moneyService) payDetails.push(`ค่าบริการ ${num(p.moneyService, 2)} บาท`)
                if (p.moneyFine) payDetails.push(`ค่าปรับ ${num(p.moneyFine, 2)} บาท` + (p.fineNote ? ` (งวด ${p.fineNote})` : ''))
                if (p.moneyOver) payDetails.push(`เงินเกิน ${num(p.moneyOver, 2)} บาท`)
                if (p.moneyDiscount) payDetails.push(`ส่วนลดดอกเบี้ย -${num(p.moneyDiscount, 2)} บาท`)

                if (p.isPayangent && document.payagent && document.agentMoney > 0) {
                    payDetails.push(`หักค่าคอม ${num(p.agentMoney, 2)} บาท`
                        + (p.agentMoneyTax ? ` (ภาษี ณ ที่จ่าย ${num(p.agentMoneyTax, 2)} บาท)` : ''))
                }

                const canEdit = !!props.onEdit

                return <div key={`lot_${p.id}`} className={'_item' + (canEdit ? ' -can-edit' : '')}
                            onClick={() => canEdit && props.onEdit(p.id)}>
                    <div className={'_lot'}>
                        <div
                            className={'_number'}>{p.lot > 0 ? `${p.lot}${p.lotSplit > 0 ? `/${p.lotSplit}` : ''}` : '-'}</div>
                        {renderPaymentStatus(p)}
                    </div>
                    <div className={'_due'}>
                        {p.lot > 0 && <div className={'_box'}>
                            <div className="_date">{date(p.dateDue, 'S')}</div>
                            <div className="_money">
                                <div className={'_label'}>ยอดเงิน</div>
                                <div className={'_value'}>{num(p.money, 2)}</div>
                            </div>
                        </div>}
                    </div>
                    <div className={'_pay'}>
                        {p.isPay && <div className={'_box'}>
                            <div className="_date">{date(p.datePay, 'S')}</div>
                            <div className="_money">
                                <div className={'_label'}>ยอดเงิน</div>
                                <div className={'_value'}>{num(moneyNet, 2)}</div>
                            </div>
                            <div className={'_detail'}>
                                <ol>{payDetails.map((t, i) => <li key={`paydetail_${i}`}>{t}</li>)}</ol>
                            </div>
                        </div>}
                    </div>
                    <div className={'_note'}>
                        <div className={'_text'}>{p.note}</div>
                        {p.files && p.files.some((f: any) => f.tag === 'copy') && <div className={'_files'}>{p.files
                            .filter((f: any) => f.tag === 'copy')
                            .map((f: any, x: number) => <Icon key={'file_' + x} name="file-invoice" className="_link tclick" href={f.url} targetBlank/>)}</div>}
                    </div>
                </div>

            })}
        </div>
    </div>
}
