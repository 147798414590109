import React, { useEffect, useMemo, useState } from 'react'

import { clsNames, date, dbdate, dConfirm, debounce, dError, get, isDev, isEmpty, num, post, qsget, qsset, tSuccess } from 'unno-comutils'
import { Button, createPaging, Icon, List, ListBody, ListContainer, ListHead, Modal, PageTitle, Paging, Wait } from 'unno-comutils/ui'
import { Checkbox, fromRangeParam, Input, InputDate, InputDateRange, InputSearch, InputSearchRow, Select, UploadButton } from 'unno-comutils/form'
import Datatable from 'unno-comutils/Datatable'
import Datagrid, { columnIconClick, IDatagridColumns, IDatagridNestedColumns } from 'unno-comutils/Datagrid'

import { cellClsRender, CheckboxCell, CheckboxCellNew } from '../../components/cell'
import { hhash, hhashRemove, hhashUpdate, reduceCol, textValue } from '../../utils'
import { DEFAULT_ACCOUNT_COMPANY } from '../../service/var.default'
import { createTags } from '../../service/search'
import { PAYWAY_TYPEs } from '../../service/var.meta'
import { useMeta } from '../../app'

export default function Company () {
    const meta = useMeta()

    const [wait, setWait] = useState(true)

    const [lists, setLists] = useState<any>([])

    const [form, setForm] = useState<any>(null)
    const [formNew, setFormNew] = useState<any>(null)

    const [tags, setTags] = useState<any>([])
    const [searchDate, setSearchDate] = useState<any>('')
    const [searchCompany, setSearchCompany] = useState<any>('')

    const [paging, setPaging] = useState(createPaging(1))

    // ----- ACTION

    const loadList = debounce((page?: any) => {
        if (!page) page = paging
        const qs = { page: page?.page || 1, date: dbdate(searchDate), company: searchCompany?.id, }
        setWait(true)
        get('account/company/list', qs).then(({ ok, datas, paging }) => {
            if (ok) {
                setTags(createTags({ searchDate, setSearchDate, searchCompany, setSearchCompany }))

                setLists(datas)
                setPaging(paging)

                const h = hhash()
                if (!h) qsset(qs)
            }
        }).finally(() => setWait(false))
    }, 500)

    const dHash = debounce(() => {
        const h = hhash()
        if (h && h.type === 'company') setForm(h.id)
    }, 500)

    // ----- MEMO

    useEffect(() => {

        const qs = qsget()
        if (qs.date) setSearchDate(fromRangeParam(qs.date))
        if (qs.page) setPaging(createPaging(num(qs.page)))
        if (qs.company) {
            get('app/qs', { company: qs.company }).then(d => {
                if (d.ok && d.company) setSearchCompany(d.company)
            })
        }
        dHash()
    }, [])

    useEffect(() => {
        loadList()
    }, [searchDate, searchCompany])

    const tableDatas = useMemo(() => {
        return lists.map((d: any) => {
            d.date = date(d.date, 'S')
            d.monneyTotal = d.companyMoney - d.companyMoneyRevoke
            return d
        })
    }, [lists])

    const tableColumns = useMemo<IDatagridColumns[]>(() => {
        const columns: IDatagridColumns[] = [
            { name: 'date', label: 'วันที่แจ้ง', width: 80, style: 'c -grey-blue-text' },
            { name: 'company', label: 'บริษัท', width: 300, fill: true },
            { name: 'count', label: 'รายการ', width: 70, style: 'c' },
            { name: 'companyMoney', label: 'เงินนำส่ง', width: 100, format: 'number.2', style: 'r -blue-text' },
            { name: 'companyMoneyRevoke', label: 'เงินคืน', width: 100, format: 'number.2', style: 'r -red-text' },
            { name: 'monneyTotal', label: 'เงินนำส่งสุทธิ', width: 100, format: 'number.2', style: 'r -green-text' },
            { name: 'note', label: 'หมายเหตุ', width: 300 },
            {
                name: 'btn_export',
                label: '',
                width: 70,
                style: 'c',
                pinned: 'right',
                renderer: (_: any, d: any) => <Button href={'/d/account_company.pdf?id=' + d.id} targetBlank>ใบสรุป</Button>
            },
            {
                name: 'btn_export_send',
                label: '',
                width: 70,
                style: 'c',
                pinned: 'right',
                renderer: (_: any, d: any) => <Button href={'/d/account_company_send.pdf?id=' + d.id} targetBlank>ใบนำส่ง</Button>
            },
            columnIconClick({ name: 'btn_edit', icon: 'cog', pinned: 'right', onClick: (_, p: any) => setForm(p.id), }),
        ]

        if (isDev)
            columns.push(columnIconClick({ name: 'btn_edit_new', icon: 'cog blue', pinned: 'right', onClick: (_, p: any) => setFormNew(p.id), }))

        return columns
    }, [])

    // ----- RENDER

    return <>
        <PageTitle icon="envelope-open-dollar" title="ตัดจ่ายค่าเบี้ยบริษัทประกันภัย">
            <InputSearch tags={tags} onRefresh={() => loadList()}>
                <InputSearchRow label="วันที่" children={<InputDateRange icon fixWidth value={searchDate} onChange={setSearchDate}/>}/>
                <InputSearchRow label={'บริษัท'} children={<Select options={meta.COMPANYs} value={searchCompany} onChange={(_, v) => setSearchCompany(v)}/>}/>
            </InputSearch>
            <Button success className={'ml-4'} onClick={() => setForm(0)}>เพิ่มรายการใหม่</Button>
        </PageTitle>

        <Datagrid wait={wait} wrapText name={'account_company'} datas={tableDatas} columns={tableColumns}/>

        <Paging paging={paging} onChange={loadList}/>
        <CompanyForm id={form} onSave={loadList} onClose={() => setForm(null)}/>
        <CompanyNewForm id={formNew} onSave={loadList} onClose={() => setFormNew(null)}/>
    </>
}

export function CompanyForm (props: any) {
    const meta = useMeta()

    const [wait, setWait] = useState(false)

    const [form, setForm] = useState<any>({})
    const [company, setCompany] = useState<any>({})

    const [documents, setDocuments] = useState<any>([])

    const [search, setSearch] = useState('')
    const [searchOnlyDue, setSearchOnlyDue] = useState(false)

    const [formUpload, setFormUpload] = useState<any>(null)

    // ----- ACTION

    const loadDataCompany = (id?: any, ob?: any) => {
        setWait(true)
        get('setting/company/get/' + id).then((d: any) => {
            if (d.ok) {
                const { data } = d
                const workcodes = data.agentBrokers?.map((b: any) => ({ id: b.code, name: b.code + ' : ' + b.name })) || []
                const banks = data.banks?.map((b: any) => ({ id: b.number, name: b.number + ' : ' + b.account })) || []
                setCompany({ ...data, workcodes, banks })

                if (ob) {
                    const update: any = {}
                    if (!(ob?.bank?.id) || !banks.find((o: any) => o.id === ob?.bank?.id)) update.bank = (banks && banks[0].id) || ''
                    if (!(ob?.workcode) || !workcodes.find((o: any) => o.id === ob?.workcode)) update.workcode = (workcodes && workcodes[0].id) || ''

                    if (Object.keys(update).length > 0)
                        onChange(update)
                }
                loadDoc(data.id)
            }
        }).finally(() => setWait(false))
    }

    const loadData = () => {
        if (props.id > 0) {
            setWait(true)
            setDocuments([])
            get('account/company/get/' + props.id).then(({ ok, data }) => {
                if (ok) {
                    const { documents, ...o } = data
                    setForm(o)
                    loadDataCompany(o.company?.id, o)
                    hhashUpdate('company,' + data.id)
                }
                else props.onClose()
            }).finally(() => setWait(false))
        }
        else {
            setForm({ ...DEFAULT_ACCOUNT_COMPANY })
            setDocuments([])

            /*
            if (isDev) {
                setTimeout(() => {
                    onChange({ company: { id: 1010, name: 'วิริยะ' } })
                    loadDataCompany(1010)
                }, 1000)
            }
            */
        }
    }

    const loadDoc = debounce((company: any) => {
        setWait(true)
        get('account/company/documents', { id: props.id, date: date(form.date, 'DB'), onlyDue: searchOnlyDue, company }).then((d: any) => {
            if (d.ok) {
                setDocuments(d.documents
                    .sort((a: any, b: any) => {
                        if (a.checked !== b.checked) return a.checked ? -1 : 1
                        return a.dateStart > b.dateStart ? 1 : -1
                    })
                    .map((o: any) => {
                        const companyYields = o.companyYields ? o.companyYields.reduce((s: any, y: any) => {
                            s['yield_' + y.id] = y.money
                            if (y.taxHold) s['yield_' + y.id + '_taxHold'] = y.taxHold
                            if (y.taxVat) s['yield_' + y.id + '_taxVat'] = y.taxVat
                            return s
                        }, {}) : {}

                        return {
                            ...o,
                            customer: o.customer?.name || '',
                            metaInsuretype: o.metaInsuretype?.name || '',
                            companyDue: date(o.companyDue),
                            dateStart: date(o.dateStart),
                            revokeDate: date(o.revokeDate),
                            ...companyYields,
                            checked: { id: o.id, check: !!o.checked }
                        }
                    }))
            }
        }).finally(() => setWait(false))
    }, 250)

    const saveData = (c: any) => {
        const saveData = {
            id: form.id,
            date: date(form.date, 'DB'),
            company: form.company?.id,
            workcode: form.workcode,
            bank: form.bank?.id || form.bank || '',
            type: form.type,
            note: form.note,
            documents: documents.filter((d: any) => d.checked && d.checked.check)
        }

        post('account/company/save/' + props.id, saveData).then(d => {
            if (d.ok) {
                tSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
                props.onSave(d.id)
                props.onClose()
            }
            else dError(d)
        }).finally(c)
    }

    const deleteData = () => {
        dConfirm('ยืนยันการลบข้อมูล').then(() => {
            get('account/company/delete/' + props.id).then(d => {
                if (d.ok) {
                    tSuccess('ลบข้อมูลเรียบร้อยแล้ว')
                    props.onSave()
                    props.onClose()
                }
                else dError(d)
            })
        })
    }

    // ----- EVENT

    const onChange = (update: any) => {
        if (update.hasOwnProperty('company')) {
            update.bank = ''
            loadDataCompany(update.company.id)
        }
        setForm((o: any) => ({ ...o, ...update }))
    }

    const _doMatch = (file: any, company: any) => {
        setWait(true)
        post('account/company/match', { file, company }).then(d => {
            if (d.ok) {
                setFormUpload({ ...d, file })
            }
            else dError(d)
        }).finally(() => setWait(false))
    }

    const onMatch = (d: any) => {
        if (d.ok && d.files && d.files.length > 0) {
            _doMatch(d.files[0].file, form.company?.id || 0)
        }
        else dError(d)
    }

    const onUploadRefresh = () => {
        if (formUpload && formUpload.file && formUpload.company && formUpload.company.id)
            _doMatch(formUpload.file, formUpload.company.id)
    }

    const onUploadSave = (datas: any) => {
        setFormUpload(null)
        if (datas && datas.length > 0) {
            setDocuments((prevs: any) => prevs.map((p: any) => {
                const up = datas.find((d: any) => p.id === d.id)
                if (up) {
                    p.checked = { id: up.id, check: true }
                    const objs = Object.keys(up)
                    for (const k in objs) {
                        const key = objs[k]
                        if (key === 'moneyInsure' || key === 'moneyTotal' || key.startsWith('yield_')) {
                            if (up[key] !== p[key]) {
                                p[key] = up[key]
                                addDocumentChange(p, key)
                            }
                        }
                    }
                }
                return p
            }).sort((a: any, b: any) => (a.checked.check === b.checked.check) ? 0 : a.checked.check ? -1 : 1))
        }
    }

    const readOnly = !!props.readOnly

    const onCheck = (id: any, v: any) => readOnly ? {} : setDocuments((o: any) => o.map((d: any) => id === d.id ? { ...d, checked: v } : d))

    const addDocumentChange = (doc: any, key: any) => {
        if (!doc.hasEdits) doc.hasEdits = []
        if (doc.hasEdits.indexOf(key) < 0) doc.hasEdits.push(key)
        return doc
    }

    const onDocumentChange = (dt: any, ds: any, s: any, isRevoke = false) => {
        if (ds && (s === 'edit' || s === 'CopyPaste.paste')) {
            ds.forEach((item: any) => {
                if (dt[item[0]]) {
                    const index = documents.findIndex((x: any) => x.id === dt[item[0]].id)
                    if (index >= 0 && documents[index]) { //  && documents[index].canEdit
                        if (item[2] !== item[3]) {
                            if (!(isRevoke && (item[1] === 'moneyInsure' || item[1] === 'moneyTotal')))
                                addDocumentChange(documents[index], item[1])

                            documents[index][item[1]] = item[3] || ''

                            if (isRevoke) {
                                if (item[1] === 'moneyInsure') {
                                    const moneyStamptax = num(documents[index].moneyStamptax)
                                    const revokeMoneyNet = !isEmpty(item[3]) ? num(item[3]) : 0
                                    const revokeMoneyTotal = revokeMoneyNet + moneyStamptax + (revokeMoneyNet + moneyStamptax) * 0.07

                                    documents[index].revokeMoneyNet = num(revokeMoneyNet)
                                    documents[index].revokeMoneyTotal = num(revokeMoneyTotal)

                                    addDocumentChange(documents[index], 'revokeMoneyNet')
                                    addDocumentChange(documents[index], 'revokeMoneyTotal')
                                }
                                else if (item[1] === 'moneyTotal') {
                                    documents[index].revokeMoneyTotal = !isEmpty(item[3]) ? num(item[3]) : 0
                                    addDocumentChange(documents[index], 'revokeMoneyTotal')
                                }
                            }

                            else {
                                if (item[1] === 'moneyInsure') {
                                    documents[index].moneyStamptax = Math.ceil(item[3] * 0.004)
                                    documents[index].moneyTax = (num(item[3]) + documents[index].moneyStamptax) * 0.07
                                    documents[index].moneyTotal = num(item[3]) + documents[index].moneyStamptax + documents[index].moneyTax
                                    addDocumentChange(documents[index], 'moneyTotal')
                                }
                                else if (item[1].startsWith('yield_')) {

                                    if (typeof item[3] === 'string' && item[3].trim().endsWith('%')) {
                                        item[3] = (documents[index].moneyInsure * num(item[3])).toFixed(2)
                                        documents[index][item[1]] = item[3] || ''
                                    }

                                    const ys = item[1].split('_')
                                    const edited: any = {}
                                    if (documents[index].hasEdits) documents[index].hasEdits.forEach((e: any) => edited[e] = documents[index][e])

                                    post('book/cal_yield/' + num(ys[1]), { extra: ys.length > 2 ? ys[2] : '', doc: dt[item[0]].id, index, value: num(item[3]), edited }).then(g => {
                                        if (g.ok) {
                                            if (g.updates && g.updates.length) {
                                                setDocuments(documents.map((o: any, oi: any) => {
                                                    if (oi === g.index) {
                                                        g.updates.forEach((u: any) => {
                                                            if (o[u.field] !== u.value) {
                                                                o[u.field] = u.value
                                                                addDocumentChange(o, u.field)
                                                            }
                                                        })
                                                    }
                                                    return o
                                                }))
                                            }
                                        }
                                    })
                                }
                            }
                        }
                    }
                }
            })
            setDocuments([...documents])
        }
    }

    const onClose = () => {
        hhashRemove()
        setForm({})
    }

    // -----

    const _yieldColumns = function (company: any) {
        const yieldColumns: any = []
        if (company && !!company.yieldDatas) {
            const ys: any = []
            Object.keys(company.yieldDatas).forEach(c1 => {
                Object.keys(company.yieldDatas[c1]).forEach(c2 => {
                    const c2n = num(c2)
                    if (ys.indexOf(c2n) < 0 && c2n > 0) ys.push(c2n)
                })
            })

            meta.YIELDs.sort((a: any, b: any) => a.id - b.id).forEach((yg: any) => yg.items.forEach((y: any) => {
                if (ys.indexOf(y.id) >= 0) {
                    yieldColumns.push({
                        width: 100,
                        type: 'money',
                        data: 'yield_' + y.id,
                        text: y.name,
                        className: 'r ' + (yg.id ? '-red' : '-green'),
                        taxHold: y.taxHold,
                        taxVat: y.taxVat,
                        edit: yg.id === 11 && !readOnly
                    })

                    if (y.taxHold > 0) {
                        yieldColumns.push({
                            width: 100,
                            type: 'money',
                            data: 'yield_' + y.id + '_taxHold',
                            text: 'หักภาษี ' + y.taxHold + '%',
                            className: 'r -down ' + (yg.id ? '-red' : '-green'),
                            edit: yg.id === 11 && !readOnly
                        })
                    }
                    if (y.taxVat > 0) {
                        yieldColumns.push({
                            width: 100,
                            type: 'money',
                            data: 'yield_' + y.id + '_taxVat',
                            text: 'Vat ' + y.taxVat + '%',
                            className: 'r -up ' + (yg.id ? '-red' : '-green'),
                            edit: yg.id === 11 && !readOnly
                        })
                    }
                }
            }))
        }
        return yieldColumns
    }

    const { tableColumns, tableDatas, tableSetting, selected, selectedDocs } = useMemo(() => {
        const yieldColumns = _yieldColumns(company)
        const tableColumns = [
            { data: 'rownum', text: '#', width: 40, className: 'c' },
            { data: 'checked', text: '', className: 'c', width: 30, component: <CheckboxCell hot-renderer headerCount={2} onClick={onCheck}/> },
            { data: 'companyDue', text: 'ดิวชำระ', width: 110, className: 'c' },
            { data: 'dateStart', text: 'เริ่มคุ้มครอง', width: 110, className: 'c' },
            { data: 'insuranceNumber', text: 'เลขที่กรมธรรม์', width: 200, edit: !readOnly },
            { data: 'vehicleLicense', text: 'ทะเบียน', width: 110, className: 'c' },
            { data: 'customer', text: 'ชื่อลูกค้า', width: 200 },
            { data: 'metaInsuretype', text: 'ประเภทประกัน', width: 100, className: 'c' },
            { data: 'moneyInsure', text: 'เบี้ยประกันภัยสุทธิ', width: 100, className: 'r -blue', type: 'money', edit: !readOnly },
            { data: 'moneyTotal', text: 'เบี้ยประกันภัยรวม', width: 100, className: 'r', type: 'money' },
            ...yieldColumns,
            { data: 'companyMoneySend', text: 'ยอดชำระ', width: 100, className: 'r -blue', type: 'money' },
            { data: 'note', text: 'หมายเหตุ', width: 200 }
        ].map(d => ({ ...d, noWrap: true }))

        const tableHeaders: any = [{}, {}]
        const tableMerges: any = []
        tableColumns.forEach((d, x) => {
            if (d.data.startsWith('yield_')) {
                if (d.data.endsWith('taxHold') || d.data.endsWith('taxVat')) {
                    tableHeaders[1][d.data] = d.text
                }
                else {
                    tableHeaders[0][d.data] = d.text
                    if (d.taxHold > 0 || d.taxHold > 0) {
                        tableHeaders[1][d.data] = 'ยอดเงิน'
                        tableMerges.push({ row: 0, col: x, rowspan: 1, colspan: 1 + (d.taxHold > 0 ? 1 : 0) + (d.taxVat > 0 ? 1 : 0) })
                    }
                    else {
                        tableMerges.push({ row: 0, col: x, rowspan: 2, colspan: 1 })
                    }
                }
            }
            else {
                tableHeaders[0][d.data] = d.text
                tableMerges.push({ row: 0, col: x, rowspan: 2, colspan: 1 })
            }
        })

        const tableDatas = [
            ...tableHeaders,
            ...documents && documents.filter((d: any) => !d.isRevoke).map((d: any, i: any) => ({ rownum: i + 1, ...d })).filter((d: any) => d.checked.check || !readOnly)
        ]

        //console.log(tableDatas)

        const _tableDatas = search && search.length > 0
            ? tableDatas.filter((d, x) => x < 2 || d.insuranceNumber?.includes(search) || d.customer.includes(search))
            : tableDatas

        const tableSetting = {
            colHeaders: () => '.',
            rowHeaderWidth: 6,
            rowHeaders: () => '.',
            fixedRowsTop: 2,
            fixedColumnsLeft: 2,
            mergeCells: tableMerges,
            afterChange: (ds: any, s: any) => onDocumentChange(_tableDatas, ds, s),
            cells: (row: any, col: any) => {
                if (row < 2) return { className: clsNames(tableColumns[col]?.className, '-header') }
                if (_tableDatas[row].payOnce) return { className: clsNames(tableColumns[col]?.className, '-red-text') }
                return cellClsRender(_tableDatas, tableColumns, row, col)
            }
        }

        const selected = tableDatas && tableDatas.reduce((sum, d) => d.checked?.check ? [sum[0] + num(d.companyMoneySend), sum[1] + 1] : sum, [0, 0])
        const selectedDocs = tableDatas && tableDatas.filter(d => d.checked?.check).map(d => d.id)

        return { tableColumns, tableDatas: _tableDatas, tableSetting, selected, selectedDocs }
    }, [props.meta, readOnly, company, search, documents, onCheck])

    const { revokeColumns, revokeDatas, revokeSetting, selectedRevoke } = useMemo(() => {
        const yieldColumns = _yieldColumns(company)
        const revokeColumns = [
            { data: 'rownum', text: '#', width: 40, className: 'c' },
            { data: 'checked', text: '', className: 'c', width: 30, component: <CheckboxCell hot-renderer onClick={onCheck}/> },
            { data: 'revokeDate', text: 'วันที่ยกเลิก', width: 110, className: 'c' },
            { data: 'dateStart', text: 'เริ่มคุ้มครอง', width: 110, className: 'c' },
            { data: 'insuranceNumber', text: 'เลขที่กรมธรรม์', width: 200 },
            { data: 'vehicleLicense', text: 'ทะเบียน', width: 110, className: 'c' },
            { data: 'customer', text: 'ชื่อลูกค้า', width: 200 },
            { data: 'metaInsuretype', text: 'ประเภทประกัน', width: 100, className: 'c', type: 'money' },
            { data: 'moneyInsure', text: 'เบี้ยประกันภัยสุทธิ', width: 100, className: 'r -blue', type: 'money', edit: !readOnly },
            { data: 'moneyTotal', text: 'เบี้ยประกันภัยรวม', width: 100, className: 'r -blue', type: 'money', edit: !readOnly },
            ...yieldColumns,
            { data: 'revokeMoneyCal', text: 'เงินคืน (คำนวน)', width: 100, className: 'r', type: 'money' },
            { data: 'revokeMoney', text: 'ยอดเงินคืน', width: 100, className: 'r -blue', type: 'money', edit: !readOnly },
            { data: 'companyMoneyRevoke', text: 'ค่ายกเลิก', width: 100, className: 'r -blue', type: 'money', edit: !readOnly },
            { data: 'revokeNote', text: 'หมายเหตุ', width: 200 }
        ].map(d => ({ ...d, noWrap: true }))

        const revokeDatas = [
            revokeColumns.reduce(reduceCol, {}),
            ...documents && documents.filter((d: any) => d.isRevoke).map((d: any, i: any) => {
                return ({
                    rownum: i + 1,
                    ...d,
                    moneyInsure: !isEmpty(d.revokeMoneyNet) ? d.revokeMoneyNet : d.moneyInsure,
                    moneyTotal: !isEmpty(d.revokeMoneyTotal) ? d.revokeMoneyTotal : d.moneyTotal,
                })
            }).filter((d: any) => d.checked.check || !readOnly)
        ]

        const revokeSetting = {
            colHeaders: () => '.',
            rowHeaderWidth: 6,
            rowHeaders: () => '.',
            fixedRowsTop: 1,
            fixedColumnsLeft: 2,
            afterChange: (ds: any, s: any) => onDocumentChange(revokeDatas, ds, s, true),
            cells: (row: any, col: any) => {
                if (row === 0) return { className: clsNames(revokeColumns[col]?.className, '-header') }
                return cellClsRender(revokeDatas, revokeColumns, row, col)
            }
        }

        const selectedRevoke = revokeDatas && revokeDatas.reduce((sum, d) => d.checked?.check ? [sum[0] + num(d.revokeMoney), sum[1] + 1] : sum, [0, 0])

        return { revokeColumns, revokeDatas, revokeSetting, selectedRevoke }
    }, [documents, readOnly, company, onCheck])

    const propMeodal = readOnly ? {} : { footerSave: saveData, footerDrop: props.id > 0 && deleteData }

    // ----- RENDER

    return <>
        <Modal title="ตัดจ่ายค่าเบี้ยบริษัทประกันภัย" full noCloseBackdrop open={props.id !== null} onClose={props.onClose} onOpenEnd={loadData}
               onCloseEnd={onClose} {...propMeodal}>
            {wait && <Wait/>}
            {readOnly
                ? <div className="flex">
                    <Input readOnly icon="calendar-alt" label="วันที่ตัดจ่าย" className="w-40" center value={date(form.date)}/>
                    <Input readOnly label="บริษัท" className="ml-4 w-fill" value={form.company?.name}/>
                    <Input readOnly label="รหัสแจ้งงาน" className="ml-4 w-fill" value={textValue(company.workcodes, form.workcode)}/>
                    <Input readOnly label="วิธีชำระ" className="ml-4 w-fill" value={textValue(PAYWAY_TYPEs, form.type)}/>
                    <Input readOnly label="รหัสแจ้งงาน" className="ml-4 w-fill" value={textValue(company.banks, form.bank)}/>
                </div>
                : <div className="flex">
                    <InputDate icon label="วันที่ตัดจ่าย" fixWidth noClear value={form.date} onChange={v => onChange({ date: v })}/>
                    <Select label="บริษัท" className="ml-4 w-fill" noClear options={meta.COMPANYs} value={form.company} onChange={(_, v) => onChange({ company: v })}/>
                    <Select label="รหัสแจ้งงาน" className="width-300 ml-4" noClear options={company.workcodes || []} value={form.workcode}
                            onChange={v => onChange({ workcode: v })}/>
                    <Select label="วิธีชำระ" className="w-40 ml-4" noClear options={PAYWAY_TYPEs} value={form.type} onChange={v => onChange({ type: v })}/>
                    <Select label="เลขที่บัญชี" classBox="width-300 ml-4" noClear options={company.banks || []} value={form.bank} onChange={(_, v) => onChange({ bank: v })}/>
                </div>}
            {readOnly
                ? <Input readOnly label="หมายเหตุ" value={form.note}/>
                : <Input label="หมายเหตุ" multiline={3} defValue={form.note} onBlur={v => onChange({ note: v })}/>}

            {(selected[1] > 0 || !readOnly) && <div className="form-datatable mt-4">
                <div className="_header no-boxmargin">
                    <span className="_title">รายการ</span>

                    {!readOnly && <>
                        <Checkbox className="ml-4" text={'เฉพาะที่ถึงดิวชำระ'} checked={searchOnlyDue} onChange={v => setSearchOnlyDue(v)}/>

                        <InputSearch value={search} onChange={setSearch} className="ml-4 mb-0"/>
                        <UploadButton className="ml-2 mr-4" info url={'app/upload_temp'} onUploaded={onMatch}>นำเข้าข้อมูล</UploadButton>
                    </>}

                    <span className="ml-auto">เลือกแล้ว</span>
                    <strong className="mx-2 red">{num(selected[1])}</strong>
                    <span>รายการ , รวมยอดเงินนำส่ง</span>
                    <strong className="mx-2 red">{num(selected[0], 2)}</strong>
                    <span>บาท</span>

                    {!!company && num(selected[1]) > 0 && !!selectedDocs &&
                        <Button success className="ml-2" href={'/d/account_company_export.xlsx?company=' + company.id + '&ids=' + selectedDocs.join(',')}
                                targetBlank>ส่งออก</Button>}
                </div>
                {!wait && <Datatable name={'ac_' + company?.id} className="report-table height-300" resizeColumn columns={tableColumns} data={tableDatas} settings={tableSetting}/>}
            </div>}
            {(selectedRevoke[1] > 0 || !readOnly) && <div className="form-datatable mt-4">
                <div className="_header">
                    <span className="_title">รายการยกเลิก</span>
                    <span className="ml-auto">เลือกแล้ว</span>
                    <strong className="mx-2 red">{num(selectedRevoke[1])}</strong>
                    <span>รายการ , รวมยอดเงินนำส่ง</span>
                    <strong className="mx-2 red">{num(selectedRevoke[0], 2)}</strong>
                    <span>บาท</span>
                </div>
                <Datatable name={'account_company_revoke'} className="report-table height-300" resizeColumn columns={revokeColumns} data={revokeDatas} settings={revokeSetting}/>
            </div>}

            <div className="flex mt-4">
                <div className="sumbox ml-auto">
                    <label className="_label">ยอดเงินนำส่ง</label>
                    <span className="_value">{num(selected[0], 2)}</span>
                </div>
                <div className="sumbox-fm ml-4"><Icon name="minus grey"/></div>
                <div className="sumbox ml-4">
                    <label className="_label">ยอดเงินคืน</label>
                    <span className="_value">{num(selectedRevoke[0], 2)}</span>
                </div>
                <div className="sumbox-fm ml-4"><Icon name="equals grey"/></div>
                <div className="sumbox ml-4">
                    <label className="_label">ยอดเงินนำส่งสุทธิ</label>
                    <span className="_value">{num(selected[0] - selectedRevoke[0], 2)}</span>
                </div>
            </div>
        </Modal>

        <FormUpload data={formUpload} onRefresh={onUploadRefresh} onSave={onUploadSave} onClose={() => setFormUpload(null)}/>
    </>
}

function CompanyNewForm (props: any) {

    const readOnly = !!props.readOnly

    const meta = useMeta()

    const [wait, setWait] = useState(false)

    const [form, setForm] = useState<any>({})
    const [company, setCompany] = useState<any>({})

    const [documents, setDocuments] = useState<any>([])

    const [search, setSearch] = useState('')
    const [searchOnlyDue, setSearchOnlyDue] = useState(false)

    const [formUpload, setFormUpload] = useState<any>(null)

    // ----- ACTION

    const loadDataCompany = (id?: any, ob?: any) => {
        setWait(true)
        get('setting/company/get/' + id).then((d: any) => {
            if (d.ok) {
                const { data } = d
                const workcodes = data.agentBrokers?.map((b: any) => ({ id: b.code, name: b.code + ' : ' + b.name })) || []
                const banks = data.banks?.map((b: any) => ({ id: b.number, name: b.number + ' : ' + b.account })) || []
                setCompany({ ...data, workcodes, banks })

                if (ob) {
                    const update: any = {}
                    if (!(ob?.bank?.id) || !banks.find((o: any) => o.id === ob?.bank?.id)) update.bank = (banks && banks[0].id) || ''
                    if (!(ob?.workcode) || !workcodes.find((o: any) => o.id === ob?.workcode)) update.workcode = (workcodes && workcodes[0].id) || ''

                    if (Object.keys(update).length > 0)
                        onChange(update)
                }
                loadDoc(data.id)
            }
        }).finally(() => setWait(false))
    }

    const loadData = () => {
        if (props.id > 0) {
            setWait(true)
            setDocuments([])
            get('account/company/get/' + props.id).then(({ ok, data }) => {
                if (ok) {
                    const { documents, ...o } = data
                    setForm(o)
                    loadDataCompany(o.company?.id, o)
                    hhashUpdate('company,' + data.id)
                }
                else props.onClose()
            }).finally(() => setWait(false))
        }
        else {
            setForm({ ...DEFAULT_ACCOUNT_COMPANY })
            setDocuments([])
        }
    }

    const loadDoc = debounce((company: any) => {
        setWait(true)
        get('account/company/documents', { id: props.id, date: date(form.date, 'DB'), onlyDue: searchOnlyDue, company }).then((d: any) => {
            if (d.ok) {
                const ndocs = d.documents
                    .sort((a: any, b: any) => {
                        if (a.checked !== b.checked) return a.checked ? -1 : 1
                        return a.dateStart > b.dateStart ? 1 : -1
                    })
                    .map((o: any) => {
                        const companyYields = o.companyYields ? o.companyYields.reduce((s: any, y: any) => {
                            s['yield_' + y.id] = y.money
                            if (y.taxHold) s['yield_' + y.id + '_taxHold'] = y.taxHold
                            if (y.taxVat) s['yield_' + y.id + '_taxVat'] = y.taxVat
                            return s
                        }, {}) : {}

                        return {
                            ...o,
                            customer: o.customer?.name || '',
                            metaInsuretype: o.metaInsuretype?.name || '',
                            companyDue: date(o.companyDue),
                            dateStart: date(o.dateStart),
                            revokeDate: date(o.revokeDate),
                            ...companyYields,
                            checked: !!o.checked
                        }
                    })
                setDocuments(ndocs)
            }
        }).finally(() => setWait(false))
    }, 250)

    const saveData = (c: any) => {
        const saveData = {
            id: form.id,
            date: dbdate(form.date),
            company: form.company?.id,
            workcode: form.workcode,
            bank: form.bank?.id || form.bank || '',
            type: form.type,
            note: form.note,
            documents: documents.filter((d: any) => d.checked)
        }

        post('account/company/save/' + props.id, saveData).then(d => {
            if (d.ok) {
                tSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
                props.onSave(d.id)
                props.onClose()
            }
            else dError(d)
        }).finally(c)
    }

    const deleteData = () => {
        dConfirm('ยืนยันการลบข้อมูล').then(() => {
            get('account/company/delete/' + props.id).then(d => {
                if (d.ok) {
                    tSuccess('ลบข้อมูลเรียบร้อยแล้ว')
                    props.onSave()
                    props.onClose()
                }
                else dError(d)
            })
        })
    }

    // ----- EVENT

    const onChange = (update: any) => {
        if (update.hasOwnProperty('company')) {
            update.bank = ''
            loadDataCompany(update.company.id)
        }
        setForm((o: any) => ({ ...o, ...update }))
    }

    const _doMatch = (file: any, company: any) => {
        setWait(true)
        post('account/company/match', { file, company }).then(d => {
            if (d.ok) {
                setFormUpload({ ...d, file })
            }
            else dError(d)
        }).finally(() => setWait(false))
    }

    const onMatch = (d: any) => {
        if (d.ok && d.files && d.files.length > 0) {
            _doMatch(d.files[0].file, form.company?.id || 0)
        }
        else dError(d)
    }

    const onUploadRefresh = () => {
        if (formUpload && formUpload.file && formUpload.company && formUpload.company.id)
            _doMatch(formUpload.file, formUpload.company.id)
    }

    const onCheck = (id: any, v: any) => readOnly ? {} : setDocuments((o: any) => o.map((d: any) => id === d.id ? { ...d, checked: v } : d))

    const onUploadSave = (datas: any) => {
        setFormUpload(null)
        if (datas && datas.length > 0) {
            setDocuments((prevs: any) => prevs.map((p: any) => {
                const up = datas.find((d: any) => p.id === d.id)
                if (up) {
                    p.checked = { id: up.id, check: true }
                    const objs = Object.keys(up)
                    for (const k in objs) {
                        const key = objs[k]
                        if (key === 'moneyInsure' || key === 'moneyTotal' || key.startsWith('yield_')) {
                            if (up[key] !== p[key]) {
                                p[key] = up[key]
                                addDocumentChange(p, key)
                            }
                        }
                    }
                }
                return p
            }).sort((a: any, b: any) => (a.checked === b.checked) ? 0 : a.checked ? -1 : 1))
        }
    }

    const addDocumentChange = (doc: any, key: any) => {
        if (!doc.hasEdits) doc.hasEdits = []
        if (doc.hasEdits.indexOf(key) < 0) doc.hasEdits.push(key)
        return doc
    }

    const ononDocumentChangeNew = (rowIndex: number, colName: string, row: any, rows: any, isRevoke = false) => {
        const docId = num(rows[rowIndex].id)

        const valueOld = documents.find((d: any) => d.id === docId)[colName]
        let valueNew = rows[rowIndex][colName]

        const index = documents.findIndex((x: any) => x.id === docId)

        if (index >= 0 && documents[index]) { //  && documents[index].canEdit
            if (valueOld !== valueNew) {
                if (!(isRevoke && (colName === 'moneyInsure' || colName === 'moneyTotal')))
                    addDocumentChange(documents[index], colName)

                documents[index][colName] = valueNew || ''

                if (isRevoke) {
                    if (colName === 'moneyInsure') {
                        const moneyStamptax = num(documents[index].moneyStamptax)
                        const revokeMoneyNet = !isEmpty(valueNew) ? num(valueNew) : 0
                        const revokeMoneyTotal = revokeMoneyNet + moneyStamptax + (revokeMoneyNet + moneyStamptax) * 0.07

                        documents[index].revokeMoneyNet = num(revokeMoneyNet)
                        documents[index].revokeMoneyTotal = num(revokeMoneyTotal)

                        addDocumentChange(documents[index], 'revokeMoneyNet')
                        addDocumentChange(documents[index], 'revokeMoneyTotal')
                    }
                    else if (colName === 'moneyTotal') {
                        documents[index].revokeMoneyTotal = !isEmpty(valueNew) ? num(valueNew) : 0
                        addDocumentChange(documents[index], 'revokeMoneyTotal')
                    }
                }

                else {
                    if (colName === 'moneyInsure') {
                        documents[index].moneyStamptax = Math.ceil(valueNew * 0.004)
                        documents[index].moneyTax = (num(valueNew) + documents[index].moneyStamptax) * 0.07
                        documents[index].moneyTotal = num(valueNew) + documents[index].moneyStamptax + documents[index].moneyTax
                        addDocumentChange(documents[index], 'moneyTotal')
                    }
                    else if (colName.startsWith('yield_')) {

                        if (typeof valueNew === 'string' && valueNew.trim().endsWith('%')) {
                            valueNew = (documents[index].moneyInsure * num(valueNew)).toFixed(2)
                            documents[index][colName] = valueNew || ''
                        }

                        const ys = colName.split('_')
                        const edited: any = {}
                        if (documents[index].hasEdits) documents[index].hasEdits.forEach((e: any) => edited[e] = documents[index][e])

                        post('book/cal_yield/' + num(ys[1]), { extra: ys.length > 2 ? ys[2] : '', doc: docId, index, value: num(valueNew), edited }).then(g => {
                            if (g.ok) {
                                if (g.updates && g.updates.length) {
                                    setDocuments(documents.map((o: any, oi: any) => {
                                        if (oi === g.index) {
                                            g.updates.forEach((u: any) => {
                                                if (o[u.field] !== u.value) {
                                                    o[u.field] = u.value
                                                    addDocumentChange(o, u.field)
                                                }
                                            })
                                        }
                                        return o
                                    }))
                                }
                            }
                        })
                    }
                }
            }

            setDocuments([...documents])
        }
    }

    const onClose = () => {
        hhashRemove()
        setForm({})
    }

    // -----

    const _yieldColumns = function (company: any) {
        const yieldColumns: (IDatagridColumns | IDatagridNestedColumns)[] = []

        if (company && !!company.yieldDatas) {
            const ys: any = []
            Object.keys(company.yieldDatas).forEach(c1 => {
                Object.keys(company.yieldDatas[c1]).forEach(c2 => {
                    const c2n = num(c2)
                    if (ys.indexOf(c2n) < 0 && c2n > 0) ys.push(c2n)
                })
            })

            meta?.YIELDs.sort((a: any, b: any) => a.id - b.id).forEach((yg: any) => yg.items.forEach((y: any) => {
                if (ys.indexOf(y.id) >= 0) {
                    yieldColumns.push({
                        width: 100,
                        format: 'number.2',
                        name: 'yield_' + y.id,
                        text: y.name,
                        style: 'r ' + (yg.id ? '-red' : '-green'),
                        //taxHold: y.taxHold,
                        //taxVat: y.taxVat,
                        editable: yg.id === 11 && !readOnly
                    })

                    if (y.taxHold > 0) {
                        yieldColumns.push({
                            width: 100,
                            format: 'number.2',
                            name: 'yield_' + y.id + '_taxHold',
                            text: 'หักภาษี ' + y.taxHold + '%',
                            style: 'r -down ' + (yg.id ? '-red' : '-green'),
                            editable: yg.id === 11 && !readOnly
                        })
                    }
                    if (y.taxVat > 0) {
                        yieldColumns.push({
                            width: 100,
                            format: 'number.2',
                            name: 'yield_' + y.id + '_taxVat',
                            text: 'Vat ' + y.taxVat + '%',
                            style: 'r -up ' + (yg.id ? '-red' : '-green'),
                            editable: yg.id === 11 && !readOnly
                        })
                    }
                }
            }))
        }

        return yieldColumns
    }

    // ----- table docs

    const { tableColumns, tableDatas, selected, selectedDocs } = useMemo<any>(() => {

        const yieldColumns = _yieldColumns(company)

        const tableColumns: (IDatagridColumns | IDatagridNestedColumns)[] = [
            { name: 'rownum', text: '#', width: 40, style: 'c' },
            { name: 'checked', text: '', style: 'c', width: 30, renderer: (_, d) => <CheckboxCellNew data={d} hot-renderer onClick={onCheck}/> },
            { name: 'companyDue', text: 'ดิวชำระ', width: 110, style: 'c' },
            { name: 'dateStart', text: 'เริ่มคุ้มครอง', width: 110, style: 'c' },
            { name: 'insuranceNumber', text: 'เลขที่กรมธรรม์', width: 200, editable: !readOnly },
            { name: 'vehicleLicense', text: 'ทะเบียน', width: 110, style: 'c' },
            { name: 'customer', text: 'ชื่อลูกค้า', width: 200 },
            { name: 'metaInsuretype', text: 'ประเภทประกัน', width: 100, style: 'c' },
            { name: 'moneyInsure', text: 'เบี้ยประกันภัยสุทธิ', width: 120, style: 'r -blue', format: 'number.2', editable: !readOnly },
            { name: 'moneyTotal', text: 'เบี้ยประกันภัยรวม', width: 120, style: 'r', format: 'number.2' },
            { label: 'ค่าคอมฯ', children: yieldColumns },
            { name: 'companyMoneySend', text: 'ยอดชำระ', width: 100, style: 'r -blue', format: 'number.2' },
            { name: 'note', text: 'หมายเหตุ', width: 200 }
        ]

        const __docs: any = documents.filter((d: any) => !d.isRevoke).map((d: any, i: any) => ({ rownum: i + 1, ...d })).filter((d: any) => d.checked.check || !readOnly)

        const tableDatas = search && search.length > 0
            ? __docs.filter((d: any) => (d.insuranceNumber?.includes(search) || d.customer.includes(search)))
            : __docs

        const selected = tableDatas && tableDatas.reduce((sum: any, d: any) => d.checked ? [sum[0] + num(d.companyMoneySend), sum[1] + 1] : sum, [0, 0])
        const selectedDocs = tableDatas && tableDatas.filter((d: any) => d.checked).map((d: any) => d.id)

        return { tableColumns, tableDatas, selected, selectedDocs }

    }, [props.meta, readOnly, company, search, documents])

    // ----- table docs_revoke

    const { revokeColumns, revokeDatas, selectedRevoke } = useMemo(() => {
        const yieldColumns = _yieldColumns(company)
        const revokeColumns: (IDatagridColumns | IDatagridNestedColumns)[] = [
            { name: 'rownum', text: '#', width: 40, style: 'c' },
            { name: 'checked', text: '', style: 'c', width: 30, renderer: (_, d) => <CheckboxCellNew data={d} hot-renderer onClick={onCheck}/> },
            { name: 'revokeDate', text: 'วันที่ยกเลิก', width: 110, style: 'c' },
            { name: 'dateStart', text: 'เริ่มคุ้มครอง', width: 110, style: 'c' },
            { name: 'insuranceNumber', text: 'เลขที่กรมธรรม์', width: 200 },
            { name: 'vehicleLicense', text: 'ทะเบียน', width: 110, style: 'c' },
            { name: 'customer', text: 'ชื่อลูกค้า', width: 200 },
            { name: 'metaInsuretype', text: 'ประเภทประกัน', width: 100, style: 'c', format: 'number.2' },
            { name: 'moneyInsure', text: 'เบี้ยประกันภัยสุทธิ', width: 120, style: 'r -blue', format: 'number.2', editable: !readOnly },
            { name: 'moneyTotal', text: 'เบี้ยประกันภัยรวม', width: 120, style: 'r -blue', format: 'number.2', editable: !readOnly },
            ...yieldColumns,
            { name: 'revokeMoneyCal', text: 'เงินคืน (คำนวน)', width: 100, style: 'r', format: 'number.2' },
            { name: 'revokeMoney', text: 'ยอดเงินคืน', width: 100, style: 'r -blue', format: 'number.2', editable: !readOnly },
            { name: 'companyMoneyRevoke', text: 'ค่ายกเลิก', width: 100, style: 'r -blue', format: 'number.2', editable: !readOnly },
            { name: 'revokeNote', text: 'หมายเหตุ', width: 200 }
        ]

        const revokeDatas: any = documents && documents.filter((d: any) => d.isRevoke).map((d: any, i: any) => {
            return ({
                rownum: i + 1,
                ...d,
                moneyInsure: !isEmpty(d.revokeMoneyNet) ? d.revokeMoneyNet : d.moneyInsure,
                moneyTotal: !isEmpty(d.revokeMoneyTotal) ? d.revokeMoneyTotal : d.moneyTotal,
            })
        }).filter((d: any) => d.checked || !readOnly)

        const selectedRevoke = revokeDatas && revokeDatas.reduce((sum: any, d: any) => d.checked ? [sum[0] + num(d.revokeMoney), sum[1] + 1] : sum, [0, 0])

        return { revokeColumns, revokeDatas, selectedRevoke }
    }, [documents, readOnly, company])

    const propMeodal = readOnly ? {} : { footerSave: saveData, footerDrop: props.id > 0 && deleteData }

    return <>
        <Modal title="ตัดจ่ายค่าเบี้ยบริษัทประกันภัย" full noCloseBackdrop open={props.id !== null} onClose={props.onClose} onOpenEnd={loadData}
               onCloseEnd={onClose} {...propMeodal}>

            {wait && <Wait/>}
            {readOnly
                ? <div className="flex">
                    <Input readOnly icon="calendar-alt" label="วันที่ตัดจ่าย" className="w-40" center value={date(form.date)}/>
                    <Input readOnly label="บริษัท" className="ml-4 w-fill" value={form.company?.name}/>
                    <Input readOnly label="รหัสแจ้งงาน" className="ml-4 w-fill" value={textValue(company.workcodes, form.workcode)}/>
                    <Input readOnly label="วิธีชำระ" className="ml-4 w-fill" value={textValue(PAYWAY_TYPEs, form.type)}/>
                    <Input readOnly label="รหัสแจ้งงาน" className="ml-4 w-fill" value={textValue(company.banks, form.bank)}/>
                </div>
                : <div className="flex">
                    <InputDate icon label="วันที่ตัดจ่าย" fixWidth noClear value={form.date} onChange={v => onChange({ date: v })}/>
                    <Select label="บริษัท" className="ml-4 w-fill" noClear options={meta.COMPANYs} value={form.company} onChange={(_, v) => onChange({ company: v })}/>
                    <Select label="รหัสแจ้งงาน" className="width-300 ml-4" noClear options={company.workcodes || []} value={form.workcode}
                            onChange={v => onChange({ workcode: v })}/>
                    <Select label="วิธีชำระ" className="w-40 ml-4" noClear options={PAYWAY_TYPEs} value={form.type} onChange={v => onChange({ type: v })}/>
                    <Select label="เลขที่บัญชี" classBox="width-300 ml-4" noClear options={company.banks || []} value={form.bank} onChange={(_, v) => onChange({ bank: v })}/>
                </div>}
            {readOnly
                ? <Input readOnly label="หมายเหตุ" value={form.note}/>
                : <Input label="หมายเหตุ" multiline={3} defValue={form.note} onBlur={v => onChange({ note: v })}/>}


            {(selected[1] > 0 || !readOnly) && <div className="form-datatable mt-4">
                <div className="_header no-boxmargin">
                    <span className="_title">รายการ</span>

                    {!readOnly && <>
                        <Checkbox className="ml-4" text={'เฉพาะที่ถึงดิวชำระ'} checked={searchOnlyDue} onChange={v => setSearchOnlyDue(v)}/>

                        <InputSearch value={search} onChange={setSearch} className="ml-4 mb-0"/>
                        <UploadButton className="ml-2 mr-4" info url={'app/upload_temp'} onUploaded={onMatch}>นำเข้าข้อมูล</UploadButton>
                    </>}

                    <span className="ml-auto">เลือกแล้ว</span>
                    <strong className="mx-2 red">{num(selected[1])}</strong>
                    <span>รายการ , รวมยอดเงินนำส่ง</span>
                    <strong className="mx-2 red">{num(selected[0], 2)}</strong>
                    <span>บาท</span>

                    {!!company && num(selected[1]) > 0 && !!selectedDocs &&
                        <Button success className="ml-2" href={'/d/account_company_export.xlsx?company=' + company.id + '&ids=' + selectedDocs.join(',')}
                                targetBlank>ส่งออก</Button>}
                </div>

                {!wait && <Datagrid className={'height-300'} name={'account_company_' + company?.id}
                                    columns={tableColumns} datas={tableDatas}
                                    onEdited={(rowIndex, colName, row, rows) => ononDocumentChangeNew(rowIndex, colName, row, rows)}/>}

            </div>}

            {(selectedRevoke[1] > 0 || !readOnly) && <div className="form-datatable mt-4">
                <div className="_header">
                    <span className="_title">รายการยกเลิก</span>
                    <span className="ml-auto">เลือกแล้ว</span>
                    <strong className="mx-2 red">{num(selectedRevoke[1])}</strong>
                    <span>รายการ , รวมยอดเงินนำส่ง</span>
                    <strong className="mx-2 red">{num(selectedRevoke[0], 2)}</strong>
                    <span>บาท</span>
                </div>

                <Datagrid className={'height-300'} name={'account_company_revoke_' + company?.id} columns={revokeColumns} datas={revokeDatas}/>

            </div>}

            <div className="flex mt-4">
                <div className="sumbox ml-auto">
                    <label className="_label">ยอดเงินนำส่ง</label>
                    <span className="_value">{num(selected[0], 2)}</span>
                </div>
                <div className="sumbox-fm ml-4"><Icon name="minus grey"/></div>
                <div className="sumbox ml-4">
                    <label className="_label">ยอดเงินคืน</label>
                    <span className="_value">{num(selectedRevoke[0], 2)}</span>
                </div>
                <div className="sumbox-fm ml-4"><Icon name="equals grey"/></div>
                <div className="sumbox ml-4">
                    <label className="_label">ยอดเงินนำส่งสุทธิ</label>
                    <span className="_value">{num(selected[0] - selectedRevoke[0], 2)}</span>
                </div>
            </div>

        </Modal>

        <FormUpload data={formUpload} onRefresh={onUploadRefresh} onSave={onUploadSave} onClose={() => setFormUpload(null)}/>
    </>

}

function FormUpload (props: any) {
    const { data, onRefresh, onSave, onClose } = props

    const onOk = () => onSave(data.datas && data.datas.length > 0 ? data.datas.filter((d: any) => d.id > 0) : [])

    if (data === null) return null
    return <Modal className="form-upload" title={'ข้อมูล'} lg noCloseBackdrop open onClose={onClose}
                  footer={<>
                      <Icon button name={'sync green'} onClick={onRefresh}/>
                      <Icon button name={'amp-guitar grey-blue'} href={'/tools/paycomp_recheck?code=' + data.code} targetBlank/>
                  </>} footerSave={onOk}>
        <div className={'row'}>
            <div className={'col flex-1'}>
                <div className="info-detail -blue">
                    <Icon name={'database'}/>
                    <span className="_label">ข้อมูลในไฟล์</span>
                    <span className="_value">{data.count}</span>
                    <span className="_unit">รายการ</span>
                </div>
            </div>

            <div className={'col flex-1'}>
                <div className="info-detail -green">
                    <Icon name={'clone'}/>
                    <span className="_label">ข้อมูลที่แมชได้</span>
                    <span className="_value">{data.found}</span>
                    <span className="_unit">รายการ</span>
                </div>
            </div>
        </div>

        <div className="height-500 flex">
            <ListContainer className={'page-content mt-4'}>
                <ListHead>
                    <div className="w-16 c">#</div>
                    <div className="w-date r">เริ่มคุ้มครอง</div>
                    <div className="w-32 c">ทะเบียน</div>
                    <div className="w-fill">ลูกค้า</div>
                    <div className="w-56">เลขที่กรมธรรม์</div>
                </ListHead>
                <ListBody scroll>
                    {data.datas && data.datas.length > 0 && data.datas.map((d: any, x: any) => <List key={'item_' + x} className={d.id > 0 ? '-green' : ''}>
                        <div className="w-16 c orange">{d.id > 0 ? d.id : ''}</div>
                        <div className="w-date r blue">{date(d.dateStart)}</div>
                        <div className="w-32 c grey-blue">{d.vehicleLicense}</div>
                        <div className="w-fill">{d.customer}</div>
                        <div className="w-56 text-overflow">{d.insuranceNumber}</div>
                    </List>)}
                </ListBody>
            </ListContainer>
        </div>
    </Modal>
}