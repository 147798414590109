import React, { useMemo } from 'react'

import { FormContainer, UploadButton, UploadZone } from 'unno-comutils/form'
import { DOCUMENT_PHOTO_TAGs, DOCUMENT_PHOTOs } from '../../../service/var'
import { clsNames, dConfirm } from 'unno-comutils'
import { Icon, IconDelete } from 'unno-comutils/ui'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { visBlock } from '../wait'

export const fileIconExt = (ext: any) => {
    if (ext === 'pdf') return 'file-pdf red'
    return 'question-square grey-blue'
}

export function FormAttach (props: any) {
    const { document, onChange } = props

    const onDragEnd = (item: any, tag: any) => {
        if (item.file) {
            console.log('onDragEnd', item, tag)
            const file = files.find((f: any) => f.number === item.file.number)
            if (file) {
                file.tag = tag
                onChange({ photos: files.map(({ number, ...f }: any) => ({ ...f })) })
            }
        }
    }

    const onUpload = (d: any, tag?: string) => {
        if (d.ok) {
            const files = d.files.map((f: any) => ({ ...f, tag: tag || 'other' }))
            onChange({ photos: document.photos ? [...document.photos, ...files] : files })
        }
    }

    const onDelete = (number?: number) => {
        dConfirm('ลบรายการนี้ ?').then(() => {
            onChange({ photos: files.filter((f: any) => f.number !== number) })
        })
    }

    // ----- MEMO

    const files = useMemo(() => {
        if (!document || !document.photos) return []
        const ks = DOCUMENT_PHOTOs.map((k: any) => k.key)
        return document.photos?.map((f: any, number: number) => ({ ...f, key: !f.key || ks.indexOf(f.key) < 0 ? 'other' : f.key, number })) || []
    }, [document?.photos])

    const visible_DOCUMENT_PHOTOs = useMemo(() => {
        if (props.wait && !visBlock(document))
            return DOCUMENT_PHOTOs.filter((o: any) => o.key.startsWith('vehicle_') || o.key === 'quotation' || o.key === 'other')
        return DOCUMENT_PHOTOs
    }, [props.wait, document])

    // ----- RENDER

    if (!document) return <></>

    return <FormContainer collapse={'book_attach_vehicle'} label={'ไฟล์แนบและภาพถ่าย'} className={'docform-attach'}>
        <div className={'document-attach'}>
            <DndProvider backend={HTML5Backend}>
                {visible_DOCUMENT_PHOTOs.map((box: any, boxIndex: any) => <React.Fragment key={'l' + boxIndex}>
                    {(box.key === 'vehicle_license' || box.key === 'other') && <div className="_line"/>}
                    <AttachBox box={box} items={files.filter((file: any) => file.tag === box.key || (box.key === 'other' && DOCUMENT_PHOTO_TAGs.indexOf(file.tag) < 0))}
                               onUpload={onUpload} onDrop={onDragEnd} onDelete={onDelete}/>
                </React.Fragment>)}
            </DndProvider>
        </div>
    </FormContainer>
}

export function AttachBox (props: { box: any, items: any[], onUpload: any, onDrop: any, onDelete: (number: any) => void }) {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: 'all',
        drop: (item: any, e: any) => props.onDrop(item, props.box.key, e),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })

    return <UploadZone onUploaded={(file: any) => props.onUpload(file, props.box.key)} className={clsNames('_box -' + props.box.key, isOver && '-over', canDrop && '-drop_can')}>
        <div ref={drop}>
            <div className="_label no-boxmargin">
                <div className="_text">{props.box.label}</div>
                <UploadButton success xs onUploaded={(file: any) => props.onUpload(file, props.box.key)}><Icon name={'plus'}/></UploadButton>
            </div>
            <div className={clsNames('_items')}>
                {props.items && props.items.map((o: any, x: number) => <AttachItem key={'f_' + x} file={o} onDelete={props.onDelete}/>)}
            </div>
        </div>
    </UploadZone>
}

function AttachItem (props: { file: any, onDelete: (number: any) => void }) {
    const { file, onDelete } = props
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: 'all',
            item: { file },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [file],
    )

    const fileIcon = (file: any) => {
        const ext = file.url.split('.').reverse()[0]
        if (['png', 'jpg', 'jpeg', 'git'].indexOf(ext) >= 0)
            return <div className={'_icon'} style={{ backgroundImage: 'url("' + file.url + '")' }}/>
        return <Icon name={fileIconExt(ext)} className={'_icon'}/>
    }

    const fileName = (file: any) => {
        if (file.name) return file.name
        return file.url.split('/').reverse()[0]
    }

    return <div ref={drag} className={clsNames('_item', isDragging && '-drag')}>
        {fileIcon(file)}
        <div className="_name">{fileName(file)}</div>
        <Icon button name="search orange" href={file.url} targetBlank download/>
        <IconDelete onClick={() => onDelete(file.number)}/>
    </div>
}
