import { get, num, todate } from 'unno-comutils'
import { PROVINCEs } from './service/var'
import { useEffect, useState } from 'react'

const h = window.location.hostname
export const IsDEMO = h.indexOf('demo') >= 0
export const IsBETA = h.indexOf('beta') >= 0 || h.indexOf('localhost') >= 0

export function reduceCol (m: any, o: any) {
    m[o.data] = o.text
    return m
}

export function textValue (options: any, value: any) {
    if (!options || !Array.isArray(options)) return ''
    return options.find(d => d.id === value)?.name || ''
}

export function sinum (n: any) {
    n = num(n)
    if (n > 1000000) return num(n / 1000000, 1) + 'M'
    if (n > 1000) return num(n / 1000, 1) + 'K'
    return num(n, 0)
}

export const timeDuration = (time: any) => {
    if (!time) return '-'
    const h = Math.floor(time / 3600)
    const m = Math.floor((time - (h * 3600)) / 60)
    return (h + ' ชั่วโมง ' + (m > 0 ? m + ' นาที' : '')).trim()
}
export const timeHour = (time: any) => {
    if (!time) return '-'
    const h = Math.floor(time / 3600)
    const m = Math.floor((time - (h * 3600)) / 60)
    const s = Math.floor((time - (h * 3600)) % 60)
    return (h >= 24 ? '(+' + Math.floor(h / 24) + ') ' : '') + `${h >= 24 ? h % 24 : h}`.padStart(2, '0') + ':' + `${m}`.padStart(2, '0') + ':' + `${s}`.padStart(2, '0')
}

export const addEvent = (name: string, callback: (e: Event) => void) => {
    document.removeEventListener(name, callback)
    document.addEventListener(name, callback)
}

export const sendEvent = (name: string, data?: any) => {
    const event = new CustomEvent(name, data ? { detail: data } : undefined)
    document.dispatchEvent(event)
}

export const addressText = (address: any) => {
    if (!address) return ''

    const texts = []
    if (!emptyText(address.name)) texts.push(address.name)
    if (!emptyText(address.room)) texts.push('ห้อง ' + address.room)
    if (!emptyText(address.floor)) texts.push('ชั้น ' + address.floor)
    if (!emptyText(address.number)) texts.push(address.number)
    if (!emptyText(address.moo)) texts.push('หมู่ ' + address.moo)
    if (!emptyText(address.soi)) texts.push('ซอย ' + address.soi)
    if (!emptyText(address.road)) texts.push('ถนน ' + address.road)

    if (!emptyText(address.subdistrict)) texts.push(address.subdistrict)
    if (!emptyText(address.district)) texts.push(address.district)
    if (!emptyText(address.province)) texts.push(PROVINCEs.find(p => address.province === p.id)?.name)
    if (!emptyText(address.zipcode)) texts.push(address.zipcode)

    return texts.filter(Boolean).join(' ')
}

export const emptyText = (t: any) => !t || t === '-'

export const qsList = (q: any) => {
    if (typeof q === 'string') return q
    if (Array.isArray(q)) return q.map(p => typeof p === 'object' ? (p.value || p.id) : p).join(',')
    return ''
}

export const tagMap = (tags?: any, maps?: any) =>
    tags.map(({ name, text, icon, color }: any) => ({ text, icon, color, onClear: maps && maps.find((m: any) => m.name === name)?.onClear }))

export const pc = (v: any, b: any, subfix = '%') => {
    if (b === 0) return '-'
    const n = v / b * 100
    if (n === 0) return '-'
    return num(n, 2) + subfix
}

export const loadMeta = (options: any, setData: any) => {
    get('app/meta', options).then(({ ok, ...d }) => ok && setData(d))
}

export const hhash = () => {
    const h = document.location.hash
    if (h && h.length > 1) {
        const ex = h.substr(1).split(',')
        if (ex.length > 1)
            return { type: ex[0], id: num(ex[1]), raw: ex }
    }
    return null
}

export const hhashUpdate = (hash: string) => window.location.hash = hash

export const hhashRemove = () => window.location.hash = ''

export function useMeta (need: string[], force?: boolean) {
    const [meta, setMeta] = useState<any>({})
    const [used, setUsed] = useState<string[]>([])

    useEffect(() => {
        const newUse = need.filter(x => !used.includes(x) || force)
        if (newUse.length > 0) {
            setUsed([...used, ...newUse].filter((v, i, a) => a.indexOf(v) === i))
            get('app/meta', { _need: newUse.join(',') }).then((d) => {
                if (d.ok) {
                    const output: any = {}
                    for (const k in d) {
                        if (k !== 'ok' && !k.startsWith('_') && !k.startsWith('$') && d.hasOwnProperty(k)) {
                            output[k] = d[k]
                        }
                    }
                    if (Object.keys(output).length > 0)
                        setMeta((prev: any) => ({ ...prev, ...output }))
                }
            })
        }
    }, [need])
    return meta
}

export function age (dateString: any) {
    var today = new Date()
    var birthDate = todate(dateString)
    var age = today.getFullYear() - birthDate.getFullYear()
    var m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
        age--
    return age
}

export function ageCar (yCar: number) {
    const yCurrent = todate().getFullYear()
    if (yCar)
        return (yCurrent - yCar) + 1
    return 0
}

export function dateRemain (dateString: any) {

    const sDate = todate().getTime()
    const eDate = todate(dateString).getTime()

    const output = Math.round((eDate - sDate) / (24 * 60 * 60 * 1000)) + 1

    return output ? output : 0
}

export const idCardMask = (n: string) => {
    // X-XXXX-XXXXX-XX-X
    const output: any = []
    const number = n?.replace(/\D/g, '') || ''

    if (number.length > 0) output.push(number.substring(0, 1))
    if (number.length > 1) output.push(number.substring(1, 5))
    if (number.length > 5) output.push(number.substring(5, 10))
    if (number.length > 10) output.push(number.substring(10, 12))
    if (number.length > 12) output.push(number.substring(12, 13))

    return output.join('-')
}
