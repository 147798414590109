import React, { useMemo } from 'react'

import { changeDate, date } from 'unno-comutils'
import { Checkbox, FormContainer } from 'unno-comutils/form'
import { List, ListBody, ListHead, ListContainer } from 'unno-comutils/ui'

export function FormEtc (props: any) {
    const { document, onChange } = props

    const onFlag = (set?: boolean) => {
        onChange({ flag: set ? 1 : 0 })
    }

    const { sendDocConfirm, sendDocInsurance, sendDocRevoke } = useMemo(() => {
        if (!document?.sendDoc) return { sendDocConfirm: null, sendDocInsurance: null, sendDocRevoke: null }

        const sendDocConfirm = document.sendDoc.find((d: any) => d.name === 'confirm')
        const sendDocInsurance = document.sendDoc.find((d: any) => d.name === 'insurance')
        const sendDocRevoke = document.sendDoc.find((d: any) => d.name === 'revoke')
        return { sendDocConfirm, sendDocInsurance, sendDocRevoke }
    }, [document?.sendDoc])

    if (!document) return <></>
    return <FormContainer collapse={'book_etc'} label={'อื่นๆ'} className={'docform-etc'}>
        <div className={'flex'}>
            <Checkbox m0 text={'รีวิวให้บริษัท'} checked={document.flag & 1} onChange={onFlag}/>
            <Checkbox m0 text={'บริษัทประกันจัดส่งกรมธรรม์'} className={'ml-3'} checked={document.isInsureSend} onChange={v => onChange({ isInsureSend: v })}/>
        </div>
        <ListContainer className="mt-2">
            <ListHead>
                <div className="w-32">ประเภท</div>
                <div className="w-24 c">วันที่ต้องส่ง</div>
                <div className="w-24 c">วันที่ส่ง</div>
                <div className="w-fill">เลขพัสดุ</div>
            </ListHead>
            <ListBody>
                <List>
                    <div className="w-32 grey-blue">หนังสือยืนยัน</div>
                    <div className="w-24 c blue">{date(changeDate(document.date, 1, 'd'))}</div>
                    <div className="w-24 c green">{sendDocConfirm?.date ? date(sendDocConfirm.date) : ''}</div>
                    <div className="w-fill">{sendDocConfirm?.number || ''}</div>
                </List>
                <List>
                    <div className="w-32 grey-blue">กรมธรรม์</div>
                    <div className="w-24 c blue">{document.dateDone ? date(document.dateDone) : ''}</div>
                    <div className="w-24 c green">{sendDocInsurance?.date ? date(sendDocInsurance.date) : ''}</div>
                    <div className="w-fill">{sendDocInsurance?.number || ''}</div>
                </List>
                <List>
                    <div className="w-32 grey-blue">หนังสือยกเลิก</div>
                    <div className="w-24 c blue">{document.revokeDate ? date(changeDate(document.revokeDate, 3, 'd')) : ''}</div>
                    <div className="w-24 c green">{sendDocRevoke?.date ? date(sendDocRevoke.date) : ''}</div>
                    <div className="w-fill">{sendDocRevoke?.number || ''}</div>
                </List>
            </ListBody>
        </ListContainer>
    </FormContainer>
}
