import React from 'react'

import { num } from 'unno-comutils'
import { Button, PageScroll, Wait } from 'unno-comutils/ui'

export function RevenueList (props: {
    datas: any[] | null;
    loading?: boolean;
    onView: (p: any) => void;
    onUnCheck?: (p: any[]) => void;
}) {
    const { loading, datas, onUnCheck, onView } = props

    return <div className="account-revenue-container">
        {loading && <Wait icon={'asterisk'} color="orange"/>}
        <PageScroll>
            {datas?.map((r: any, x: any) => <div key={'_' + x} className="account-employee-revenue">
                <div className="_header">
                    <div className="_title">{r.name}</div>
                    <div className="_stats">{num(r.stats.money, 2)} ({num(r.stats.count, 0)})</div>
                    <ol className="_processs">
                        {r.process.map((p: any, x2: any) => <li key={'_' + x2}>
                            <div className="_name">{p.name}</div>
                            <div className="_cond-name">{p.condName}</div>
                            <div className="_result" onClick={() => onView(p)}>
                                <div className="_stats">{num(p.money, 2)} ({num(p.count, 0)})</div>
                                <div className="_money">{'~>'} {num(p.moneyProcess, 2)}</div>
                            </div>
                        </li>)}
                    </ol>
                    {!!r.noGroups && r.noGroups.length > 0 &&
                        (onUnCheck
                            ? <Button m0 sm error fullWidth className="mt-4" onClick={() => onUnCheck(r.noGroups)}>ไม่เข้าเงื่อนไข {r.noGroups.length} รายการ</Button>
                            : <div className="red c">ไม่เข้าเงื่อนไข {r.noGroups.length} รายการ</div>)}
                </div>
            </div>)}
        </PageScroll>
    </div>
}