import React from 'react'
import { clsNames, isEmpty } from 'unno-comutils'

export default function DetailItem (props: { children?: any, label?: string, col?: number, offset?: number, className?: string, classValue?: string }) {
    return <div className={clsNames('detail-item', !!props.col && `col w-${props.col}/12`, !!props.offset && `offset-${props.offset}`, props.className)}>
        <div className={'_label'}>{props.label}</div>
        <div className={clsNames('_value', props.classValue)}>{props.children || '-'}</div>
    </div>
}

export function DetailItemPhoto (props: { photos: any, label?: string, col?: number, offset?: number, className?: string, classValue?: string }) {
    const { photos, ...nextProps } = props
    if (isEmpty(photos)) {
        nextProps.classValue = clsNames(nextProps.classValue, 'red')
        return <DetailItem {...nextProps}>ไม่มีภาพ</DetailItem>
    }

    nextProps.classValue = clsNames(nextProps.classValue, 'chat-order-item-photos')
    return <DetailItem {...nextProps}>
        {photos.map((p: any, x: number) => {
            if (p.url.match(/\.(jpeg|jpg|gif|png)$/) != null)
                return <a key={'p_' + x} className="_item" href={p.url} target="_blank" style={{ backgroundImage: `url(${p.url})` }}/>
            return <a key={'p_' + x} className="_item-file tclick" href={p.url} target="_blank">{p.url.split('/').pop()}</a>
        })}
    </DetailItem>
}

export function DetailItemDiv () {
    return <div className="detail-item-line"/>
}