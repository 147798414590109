import React from 'react'
import { clsNames } from 'unno-comutils'
import { columnIconClick, IDatagridColumns } from 'unno-comutils/Datagrid'
import { Icon } from 'unno-comutils/ui'

export function ButtonCell (props: any) {
    const { value, mode, text, state, onClick } = props
    if (!value) return <></>
    return <button className={`btn-cell un-button un-button-${value.off ? 'outline-' : ''}${state || 'dark'}`} onClick={() => onClick(value.id, mode)}>{text}</button>
}

export function ButtonFollow (props: any) {
    const { value } = props
    return <button className="btn-cell un-button un-button-yellow" onClick={() => props.onClick(value.id)}>นัดหมาย</button>
}

export function ButtonRevoke (props: any) {
    const { value } = props
    const day = 7

    if (!value) return <></>

    if (!value.isRevoke)
        return <button className="btn-cell un-button un-button-red" onClick={() => props.onClick(value.id)}>ยกเลิก</button>

    if (value.sendDoc) {
        const err = day && !value.revoke.off && value.revoke.day > day
        return <div className="orange c">ส่งเอกสารแล้ว {err && `(ส่งช้า ${value.revoke.day - day} วัน)`}</div>
    }
    return <div className="red c">ยกเลิกแล้ว</div>
}

//IDatagridColumns TODO ยังทำ icon cog ไม่ได้ติดเรื่อง width
export function ButtonEditDataGrid (props: any) {
    const { value, mode } = props
    if (!value.isRevoke) {
        if (value.canEdit)
            return <button className="btn-cell un-button un-button-dark" onClick={() => props.edit(value.id)}>แก้ไข</button>
        return <button className="btn-cell un-button un-button-dark-outline" onClick={() => props.editMini(value.id, mode)}>แก้ไข</button>
    }
    return <div className="red c cell-small">ยกเลิกแล้ว</div>
}

export function ButtonEdit (props: any) {
    const { value, mode } = props
    if (!value)
        return <></>

    if (!value.isRevoke) {
        if (value.canEdit)
            return <button className="btn-cell un-button un-button-dark" onClick={() => props.edit(value.id)}>แก้ไข</button>
        return <button className="btn-cell un-button un-button-dark-outline" onClick={() => props.editMini(value.id, mode)}>แก้ไข</button>
    }
    return <div className="red c cell-small">ยกเลิกแล้ว</div>
}

export function ButtonPay (props: any) {
    const { value } = props

    if (!value) {
        if (props.cellProperties.row < 1)
            props.TD.className = '-header'
        return <></>
    }

    if (props.cellProperties)
        props.TD.className = props.cellProperties.className

    if (!!value.isPay) return <button className="btn-cell un-button un-button-green-outline" onClick={() => props.onRePrint(value.id)}>จ่ายแล้ว</button>
    return <button className={`btn-cell un-button un-button-blue`} onClick={() => props.onClick(value.id)}>บันทึกจ่าย</button>
}

export function CheckboxCell (props: any) {
    const { value, checkAll, headerCount } = props

    if (!value) {
        if (props.cellProperties.row < (headerCount || 1)) {
            props.TD.className = '-header'
            if (props.onAll)
                return <input type="checkbox" className="custom-control-input input" checked={checkAll} onChange={e => props.onAll(e.target.checked)}/>
        }
        return <></>
    }
    props.TD.className = props.cellProperties.className
    return <input type="checkbox" className="custom-control-input input" checked={!!value.check} onChange={() => props.onClick(value.id, { ...value, check: !value.check })}/>
}


export function CheckboxCellNew (props: any) {
    const { data } = props
    return <input type="checkbox" className="custom-control-input input" checked={data.checked} onChange={() => props.onClick(data.id, !data.checked )}/>
}

export const rowHeaders = (dt: any, i: any) => (i + 1) + (dt[i].hasEdits && dt[i].hasEdits.length > 0 ? '*' : '')

export const cellClsRender = (dt: any, dc: any, row: any, col: any) => {
    if (dc[col]) {
        const checked = dt[row] && dt[row].checked && dt[row].checked.check

        if (dt[row] && dt[row].hasEdits && dt[row].hasEdits.length > 0 && dt[row].hasEdits.indexOf(dc[col].data) >= 0) {
            return { className: clsNames(dc[col].className, '-edited', checked && '-selected') }
        }
        else if (checked) {
            return { className: clsNames(dc[col].className, checked && '-selected') }
        }
        const option = dt[row] && dt[row].__option
        if (option) return { className: clsNames(dc[col].className, option.indexOf('b') >= 0 && 'b') }
    }
}