export const MainMenus = [
    { page: 'book_new', url: '/document', name: 'สมุดรายวัน (ใหม่)', icon: 'book', role: '__admin' },
    { page: 'book_wait', url: '/book_order', name: 'งานขายใหม่', icon: 'feather', role: 'book.wait' },
    { page: 'book', url: '/book', name: 'สมุดรายวัน', icon: 'book-open', role: '_book' },
    { page: 'book_revoke', url: '/revoke', name: 'เกินดิว/ยกเลิก', icon: 'file-exclamation', role: 'book.revoke' },
    { page: 'account_company', url: '/account/company', name: 'รายจ่าย', icon: 'money-check-alt', role: '_paycomp' },
    { page: 'account_customer', url: '/account/customer', name: 'รายรับ', icon: 'sack-dollar', role: '_income' },
    { page: 'account_employee', url: '/account/employee', name: 'ตัดจ่ายพนักงาน', icon: 'briefcase', role: '_payemp' },
    { page: 'account_agent', url: '/account/agent', name: 'ตัดจ่ายตัวแทน', icon: 'person-sign', role: '_payagent' },
    { page: 'finance', url: '/finance/office_cost', matchUrl: '/finance/', name: 'บันทึกการเงิน', icon: 'shopping-basket', role: '_finance' },
    { page: 'customer', url: '/customer', name: 'ลูกค้า', icon: 'users-crown', role: '_customer' },
    { page: 'landing', url: '/landing', name: 'นำเข้าเบี้ยประกัน', icon: 'hand-holding-magic', role: 'vehicle' },
    { page: 'insurance', url: '/insurance', name: 'ประกัน', icon: 'telescope', role: 'insurance.search' },
    { page: 'report', url: '/report', name: 'รายงาน', icon: 'book', role: '_report' },
    { page: 'chat', url: '/chat', name: 'พูดคุย', icon: 'headset', role: '_chat' },
    { page: 'chat2', url: '/chat2', name: 'พูดคุยใหม่', icon: 'user-headset', role: '__admin' },
    { page: 'web', url: '/web', name: 'Web', icon: 'globe', role: '_web' },
    { page: 'call', url: '/call', name: 'การโทร', icon: 'phone', role: '__admin' },
]

export const SettingMenus = [
    { page: 'setting_etc', name: 'ตั้งค่าทั่วไป', icon: 'cog', url: '/setting/basic', role: 'config.basic' },
    { page: 'setting_broker', name: 'โบรคเกอร์', icon: 'user-circle', url: '/setting/broker', role: 'config.broker' },
    { page: 'setting_company', name: 'บริษัทประกันภัย', icon: 'car-building', url: '/setting/company', role: 'config.company', main: true },
    { page: 'setting_agent', name: 'ตัวแทน', icon: 'user-ninja', url: '/setting/agent', role: 'config.agent' },
    { page: 'setting_employee', name: 'พนักงาน', icon: 'user-tie', url: '/setting/employee', role: 'config.employee' },
    { page: 'setting_revenue', name: 'ตั้งค่าผลตอบแทน', icon: 'coins', url: '/setting/revenue', role: 'config.revenue' },
    { page: 'setting_meta', name: 'ประเภท', icon: 'layer-group', url: '/setting/meta', role: 'config.meta' },
    { page: 'setting_field', name: 'ฟิลด์', icon: 'tasks-alt', url: '/setting/field', role: 'config.field' },
    { page: 'setting_chat', name: 'ห้องแชท', icon: 'comments', url: '/setting/chat', role: 'config.chat' },
    { page: 'setting_notice', name: 'ข้อความแจ้งเตือน', icon: 'bell', url: '/setting/notice', role: 'config.notice' },
    { page: 'setting_address', name: 'ที่อยู่', icon: 'at', url: '/setting/address', role: 'config.address' },
    { page: 'setting_docprint', name: 'แบบพิมพ์', icon: 'print', url: '/setting/docprint', role: '__admin' },
]

export const AdminMenus = [
    { page: 'admin_home', name: 'โฮม', icon: 'igloo', url: '/admin/home', role: '__admin' },
    { page: 'admin_user', name: 'ผู้ใช้งาน', icon: 'user', url: '/admin/user', role: '__admin' },
    { page: 'admin_user-group', name: 'กลุ่มผู้ใช้งาน', icon: 'users', url: '/admin/user_group', role: '__admin' },
    { page: 'admin_log', name: 'บันทึกการทำงาน', icon: 'clipboard-list', url: '/admin/log', role: '__admin' },
    { page: 'admin_chat', name: 'ห้องแชท', icon: 'comments', url: '/admin/chat', role: '__admin' },
    { page: 'admin_lab', name: 'แล็ป', icon: 'flask-poison', url: '/admin/lab', role: '__admin' },
]

const BookMenus = [
    { page: 'book_express', name: 'ใบเสร็จเร่งด่วน', icon: 'rabbit-fast', url: '/book/express', role: 'book.express' },
    { page: 'book_day', name: 'สมุดรายวัน', icon: 'file-signature', url: '/book/data', role: 'book.day', main: true },
    //{ page: 'book_revoke', name: 'เกินดิว/ยกเลิก', icon: 'file-exclamation', url: '/book/revoke', role: 'book.revoke' },
    { page: 'book_send-doc', name: 'ส่งเอกสาร', icon: 'paper-plane', url: '/book/send_doc', role: 'book.send-doc' },
    { page: 'book_follow', name: 'ตามกรมธรรม์', icon: 'radar', url: '/book/follow', role: 'book.follow' },
    { page: 'book_notify', name: 'แจ้งเตือน', icon: 'bell', url: '/book/notify', role: 'book.day' },
]

const AccountCompanyMenus = [
    { page: 'company_data', name: 'ตัดจ่ายค่าเบี้ยบริษัทประกันภัย', icon: 'envelope-open-dollar', url: '/account/company/data', role: 'account.company', main: true },
    { page: 'company_wait', name: 'รายการสรุปเจ้าหนี้', icon: 'file-invoice-dollar', url: '/account/company/wait', role: 'account.company' },
]

export const AccountCustomerMenus = [
    { page: 'customer_data', name: 'รายรับ', icon: 'sack-dollar', url: '/account/customer/data', role: 'account.customer.data', main: true },
    { page: 'customer_other', name: 'รายรับอื่นๆ', icon: 'lightbulb-dollar', url: '/account/customer/other', role: 'account.customer.other' },
    { page: 'customer_summary', name: 'สรุปลูกหนี้', icon: 'box-usd', url: '/account/customer/summary', role: 'account.customer.summary' },
    { page: 'customer_slip', name: 'สลิป', icon: 'receipt', url: '/account/customer/slip', role: 'account.customer.data' },
    { page: 'customer_notify', name: 'แจ้งเตือน', icon: 'bell', url: '/account/customer/notify', role: '__admin' },
]

export const FinanceMenus = [
    { page: 'finance_office_cost', name: 'รายจ่ายสำนักงาน', icon: 'car-building', url: '/finance/office_cost', role: 'finance.office_cost' },
]

export function getSectionMenu (cPath: any) {
    if (cPath.startsWith('/book/')) return BookMenus
    if (cPath.startsWith('/account/company/')) return AccountCompanyMenus
    if (cPath.startsWith('/account/customer/')) return AccountCustomerMenus
    if (cPath.startsWith('/finance/')) return FinanceMenus
    return null
}

export const REPORTs = [
    {
        name: 'รายงานผู้บริหาร', children: [
            { key: 'sale_summary', name: 'สรุปยอดขายแยกตามประเภทกรมธรรม์', role: 'report.sale_summary' },
            { key: 'sale_company', name: 'สรุปยอดขายแยกตามบริษัทประกันภัย', role: 'report.sale_company' },
            { key: 'vehicle_top', name: 'รุ่นรถที่ขายดี', role: 'report.vehicle_top' },
            { key: 'document_revoke3', name: 'กรมธรรมยกเลิกแยกตามบริษัทประกันภัย', role: 'report.document_revoke3' },
            { key: 'document_revoke2', name: 'กรมธรรมยกเลิกแยกตามชื่อลูกค้า', role: 'report.document_revoke2' },
            { key: 'chat', name: 'จำนวนลูกค้าที่ติดต่อเข้ามา', role: 'report.chat' },
        ]
    },
    {
        name: 'รายงาน คปภ', children: [
            { key: 'insurance_book', name: 'สมุดทะเบียนเกี่ยวกับธุรกิจของนายหน้าประกันวินาศภัย', role: 'report.insurance_book' },
            { key: 'insurance', name: 'การจัดให้มีการประกันภัยโดยตรง', role: 'report.insurance' },
            { key: 'insurance_list', name: 'การจัดให้มีการประกันภัยโดยตรงแจกแจง', role: 'report.insurance_list' },
        ]
    },
    {
        name: 'รายงานใหม่', children: [
            { key: 'search', name: 'แจกแจงกรมธรรม์', role: 'report.search' },
            { key: 'income_credit', name: 'รายรับ ( ส่วนสินเชื่อ )', role: 'report.income_credit' },
            { key: 'insurance_summary', name: 'สรุปยอดขายประกันภัย', role: 'report.insurance_summary' },
            { key: 'insurance_search', name: 'แจกแจงยอดขายประกันภัย', role: 'report.insurance_search' },
            { key: 'income_performance', name: 'PERFORMANCE เบี้ยสุทธิ/เดือน', role: 'report.income_performance' },
            { key: 'document_revoke4', name: 'ยกเลิกกรมธรรม์', role: 'report.document_revoke4' },
            { key: 'outcome', name: 'รายจ่าย', role: 'report.outcome' },
            { key: 'io_summary', name: 'สรุปรายรับ-รายจ่าย จากการขายประกัน', role: '__admin' },
            { key: 'income_daily', name: 'เงินสดรับประจำวัน', role: '__admin' },
            { key: 'customer_behavior', name: 'พฤติกรรมลูกค้า', role: '__admin' },
            { key: 'company_summary', name: 'สรุปบริษัทประกัน', role: '__admin' },

        ]
    },
    {
        name: 'รายงานวิเคราะห์พฤติกรรม', children: [
            { key: 'customer_insurance', name: 'โอกาสที่ลูกค้าจะตัดสินใจซื้อประกันภัยแบ่งวันหมดอายุประกันเดิม', role: '__admin' },
            { key: 'customer_insurance2', name: 'โอกาสที่ลูกค้าจะเลือกซื้อประกันแต่ละประเภทแยกตามอายุรถ', role: '__admin' },
            { key: 'customer_insurance3', name: 'กลุ่มลูกค้าที่มักซื้อ ป.1 ซ่อมศูนย์ แยกตามอายุรถ 2-7 ปี', role: '__admin' },
            { key: 'customer_insurance4', name: 'ทุนรถ ป.2+/3+ ที่ลูกค้ามักซื้อ', role: '__admin' },
            { key: 'sale_insurance', name: 'วิเคาะห์พฤติกรรมการขายของพนักงาน', role: '__admin' },
        ]
    }

]
