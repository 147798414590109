import { PAY_STATUS_OPTIONs } from './var'
import { textRange } from 'unno-comutils/form/InputDateRange'

export function createTags (options: any) {
    const {
        searchDate, setSearchDate,
        searchCompany, setSearchCompany,
        searchEmployee, setSearchEmployee,
        searchAgent, setSearchAgent,
        searchIsPay, setSearchIsPay
    } = options

    const tags = []
    if (searchDate) tags.push({ icon: 'calendar-alt', color: 'blue', text: textRange(searchDate), onClear: () => setSearchDate('') })
    if (searchCompany) tags.push({ icon: 'building', text: searchCompany.name, onClear: () => setSearchCompany('') })
    if (searchEmployee) tags.push({ icon: 'user-tie', text: searchEmployee.name, onClear: () => setSearchEmployee('') })
    if (searchAgent) tags.push({ icon: 'user-secret', text: searchAgent.name, onClear: () => setSearchAgent('') })

    if (searchIsPay) tags.push({ color: 'green', text: PAY_STATUS_OPTIONs.find(d => d.id === searchIsPay)?.name, onClear: () => setSearchIsPay(0) })

    return tags
}