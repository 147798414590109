export const ADDRESS_PROVINCEs = [
    { id: 0, name: '-', short: '', part: '-'}, 
    { id: 10, name: 'กรุงเทพมหานคร', short: 'กท', part: 'กลาง'}, 
    { id: 81, name: 'กระบี่', short: 'กบ', part: 'ใต้'}, 
    { id: 71, name: 'กาญจนบุรี', short: 'กจ', part: 'ตะวันตก'}, 
    { id: 46, name: 'กาฬสินธุ์', short: 'กส', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 62, name: 'กำแพงเพชร', short: 'กพ', part: 'กลาง'}, 
    { id: 40, name: 'ขอนแก่น', short: 'ขก', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 22, name: 'จันทบุรี', short: 'จบ', part: 'ตะวันออก'}, 
    { id: 24, name: 'ฉะเชิงเทรา', short: 'ฉช', part: 'ตะวันออก'}, 
    { id: 20, name: 'ชลบุรี', short: 'ชบ', part: 'ตะวันออก'}, 
    { id: 18, name: 'ชัยนาท', short: 'ชน', part: 'กลาง'}, 
    { id: 36, name: 'ชัยภูมิ', short: 'ชย', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 86, name: 'ชุมพร', short: 'ชพ', part: 'ใต้'}, 
    { id: 50, name: 'เชียงใหม่', short: 'ชม', part: 'เหนือ'}, 
    { id: 57, name: 'เชียงราย', short: 'ชร', part: 'เหนือ'}, 
    { id: 92, name: 'ตรัง', short: 'ตง', part: 'ใต้'}, 
    { id: 23, name: 'ตราด', short: 'ตร', part: 'ตะวันออก'}, 
    { id: 63, name: 'ตาก', short: 'ตก', part: 'ตะวันตก'}, 
    { id: 26, name: 'นครนายก', short: 'นย', part: 'กลาง'}, 
    { id: 73, name: 'นครปฐม', short: 'นฐ', part: 'กลาง'}, 
    { id: 48, name: 'นครพนม', short: 'นพ', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 30, name: 'นครราชสีมา', short: 'นม', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 80, name: 'นครศรีธรรมราช', short: 'นศ', part: 'ใต้'}, 
    { id: 60, name: 'นครสวรรค์', short: 'นว', part: 'กลาง'}, 
    { id: 12, name: 'นนทบุรี', short: 'นบ', part: 'กลาง'}, 
    { id: 96, name: 'นราธิวาส', short: 'นธ', part: 'ใต้'}, 
    { id: 55, name: 'น่าน', short: 'นน', part: 'เหนือ'}, 
    { id: 31, name: 'บุรีรัมย์', short: 'บร', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 97, name: 'บึงกาฬ', short: 'บก', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 13, name: 'ปทุมธานี', short: 'ปท', part: 'กลาง'}, 
    { id: 77, name: 'ประจวบคีรีขันธ์', short: 'ปข', part: 'ตะวันตก'}, 
    { id: 25, name: 'ปราจีนบุรี', short: 'ปจ', part: 'ตะวันออก'}, 
    { id: 94, name: 'ปัตตานี', short: 'ปน', part: 'ใต้'}, 
    { id: 14, name: 'พระนครศรีอยุธยา', short: 'อย', part: 'กลาง'}, 
    { id: 56, name: 'พะเยา', short: 'พย', part: 'เหนือ'}, 
    { id: 82, name: 'พังงา', short: 'พง', part: 'ใต้'}, 
    { id: 93, name: 'พัทลุง', short: 'พท', part: 'ใต้'}, 
    { id: 66, name: 'พิจิตร', short: 'พจ', part: 'กลาง'}, 
    { id: 65, name: 'พิษณุโลก', short: 'พล', part: 'กลาง'}, 
    { id: 76, name: 'เพชรบุรี', short: 'พบ', part: 'ตะวันตก'}, 
    { id: 67, name: 'เพชรบูรณ์', short: 'พช', part: 'กลาง'}, 
    { id: 54, name: 'แพร่', short: 'พร', part: 'เหนือ'}, 
    { id: 83, name: 'ภูเก็ต', short: 'ภก', part: 'ใต้'}, 
    { id: 44, name: 'มหาสารคาม', short: 'มค', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 49, name: 'มุกดาหาร', short: 'มห', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 58, name: 'แม่ฮ่องสอน', short: 'มส', part: 'เหนือ'}, 
    { id: 35, name: 'ยโสธร', short: 'ยส', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 95, name: 'ยะลา', short: 'ยล', part: 'ใต้'}, 
    { id: 45, name: 'ร้อยเอ็ด', short: 'รอ', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 85, name: 'ระนอง', short: 'รน', part: 'ใต้'}, 
    { id: 21, name: 'ระยอง', short: 'รย', part: 'ตะวันออก'}, 
    { id: 70, name: 'ราชบุรี', short: 'รบ', part: 'ตะวันตก'}, 
    { id: 16, name: 'ลพบุรี', short: 'ลบ', part: 'กลาง'}, 
    { id: 52, name: 'ลำปาง', short: 'ลป', part: 'เหนือ'}, 
    { id: 51, name: 'ลำพูน', short: 'ลพ', part: 'เหนือ'}, 
    { id: 42, name: 'เลย', short: 'ลย', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 33, name: 'ศรีสะเกษ', short: 'ศก', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 47, name: 'สกลนคร', short: 'สน', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 90, name: 'สงขลา', short: 'สข', part: 'ใต้'}, 
    { id: 91, name: 'สตูล', short: 'สต', part: 'ใต้'}, 
    { id: 11, name: 'สมุทรปราการ', short: 'สป', part: 'กลาง'}, 
    { id: 75, name: 'สมุทรสงคราม', short: 'สส', part: 'กลาง'}, 
    { id: 74, name: 'สมุทรสาคร', short: 'สค', part: 'กลาง'}, 
    { id: 27, name: 'สระแก้ว', short: 'สก', part: 'ตะวันออก'}, 
    { id: 19, name: 'สระบุรี', short: 'สบ', part: 'กลาง'}, 
    { id: 17, name: 'สิงห์บุรี', short: 'สห', part: 'กลาง'}, 
    { id: 64, name: 'สุโขทัย', short: 'สท', part: 'กลาง'}, 
    { id: 72, name: 'สุพรรณบุรี', short: 'สพ', part: 'กลาง'}, 
    { id: 84, name: 'สุราษฎร์ธานี', short: 'สฎ', part: 'ใต้'}, 
    { id: 32, name: 'สุรินทร์', short: 'สร', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 43, name: 'หนองคาย', short: 'นค', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 39, name: 'หนองบัวลำภู', short: 'นภ', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 15, name: 'อ่างทอง', short: 'อท', part: 'กลาง'}, 
    { id: 37, name: 'อำนาจเจริญ', short: 'อจ', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 41, name: 'อุดรธานี', short: 'อด', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 53, name: 'อุตรดิตถ์', short: 'อต', part: 'เหนือ'}, 
    { id: 61, name: 'อุทัยธานี', short: 'อน', part: 'กลาง'}, 
    { id: 34, name: 'อุบลราชธานี', short: 'อบ', part: 'ตะวันออกเฉียงเหนือ'}, 
    { id: 101, name: 'เบตง', short: 'บต', part: 'ใต้'},
];

export const BANKs = [
    { id: 2, name: 'ธนาคารกรุงเทพ', name_s: 'BBL', color: '#1E4598', icon: 'https://files.un-no.net/static/bank/bbl.svg'}, 
    { id: 4, name: 'ธนาคารกสิกรไทย', name_s: 'KBANK', color: '#128F2D', icon: 'https://files.un-no.net/static/bank/kbank.svg'}, 
    { id: 6, name: 'ธนาคารกรุงไทย', name_s: 'KTB', color: '#1AA5E1', icon: 'https://files.un-no.net/static/bank/ktb.svg'}, 
    { id: 11, name: 'ธนาคารทหารไทย', name_s: 'TMB', color: '#1179BE', icon: 'https://files.un-no.net/static/bank/tmb.svg'}, 
    { id: 14, name: 'ธนาคารไทยพาณิชย์', name_s: 'SCB', color: '#128F2D', icon: 'https://files.un-no.net/static/bank/scb.svg'}, 
    { id: 25, name: 'ธนาคารกรุงศรีอยุธยา', name_s: 'BAY', color: '#FEC43B', icon: 'https://files.un-no.net/static/bank/bay.svg'}, 
    { id: 30, name: 'ธนาคารออมสิน', name_s: 'GSB', color: '#EB198D', icon: 'https://files.un-no.net/static/bank/gsb.svg'}, 
    { id: 33, name: 'ธนาคารอาคารสงเคราะห์', name_s: 'GHB'}, 
    { id: 66, name: 'ธนาคารอิสลามแห่งประเทศไทย', name_s: 'ISALAM'}, 
    { id: 69, name: 'ธนาคารเกียรตินาคิน', name_s: 'KKP'}, 
    { id: 22, name: 'ธนาคารซีไอเอ็มบีไทย', name_s: 'CIMB'}, 
    { id: 67, name: 'ธนาคารทิสโก้', name_s: 'TISCO'}, 
    { id: 65, name: 'ธนาคารธนชาต', name_s: 'TBANK'}, 
    { id: 24, name: 'ธนาคารยูโอบี', name_s: 'UOB'}, 
    { id: 20, name: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด', name_s: 'SCBT'}, 
    { id: 71, name: 'ธนาคารไทยเครดิตเพื่อรายย่อย', name_s: 'TCD'}, 
    { id: 73, name: 'ธนาคารแลนด์ แอนด์ เฮาส์', name_s: 'LHBANK'}, 
    { id: 70, name: 'ธนาคารไอซีบีซี', name_s: 'ICBC'}, 
    { id: 98, name: 'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย', name_s: 'SME'}, 
    { id: 34, name: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร', name_s: 'BAAC'}, 
    { id: 35, name: 'ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย', name_s: 'EXIM'},
];

export const BOOK_VERIFYs = [
    { group: 'book', name: 'agent', text: 'ตัวแทน', option: 'id'}, 
    { group: 'book', name: 'employee', text: 'พนักงาน', option: 'id'}, 
    { group: 'book', name: 'express', text: 'ใบเสร็จเร่งด่วน'}, 
    { group: 'customer', name: 'customer', text: 'ลูกค้า'}, 
    { group: 'customer', name: 'dateLicenseExpire', text: 'วันหมดอายุใบขับขี่ลูกค้า'}, 
    { group: 'customer', name: 'customerPay', text: 'ผู้ผ่อน'}, 
    { group: 'vehicle', name: 'vehicleDate', text: 'วันจดทะเบียน'}, 
    { group: 'vehicle', name: 'vehicleLicense', text: 'ทะเบียนรถ'}, 
    { group: 'vehicle', name: 'vehicleProvice', text: 'จังหวัด'}, 
    { group: 'vehicle', name: 'metaVehicle', text: 'ยี่ห้อ/รุ่น/ปี/รุ่นย่อย'}, 
    { group: 'vehicle', name: 'vehicleNumber', text: 'เลขตัวถัง'}, 
    { group: 'vehicle', name: 'vehicleNumberEngine', text: 'เลขเครื่องยนต์'}, 
    { group: 'insurance', name: 'company', text: 'บริษัท'}, 
    { group: 'insurance', name: 'workcode', text: 'รหัสแจ้งงาน'}, 
    { group: 'insurance', name: 'metaInsuretype', text: 'ประเภทประกัน'}, 
    { group: 'insurance', name: 'moneyFund', text: 'ทุนประกันภัย'}, 
    { group: 'insurance', name: 'vehicleUsage', text: 'ประเภทการใช้รถ'}, 
    { group: 'insurance', name: 'detail', text: 'รายการตกแต่งเพิ่มเติม'}, 
    { group: 'insurance', name: 'money', text: 'ราคารายการตกแต่ง'}, 
    { group: 'insurance', name: 'options', text: 'อุปกรณ์เพิ่มเติมพิเศษอื่น ๆ'}, 
    { group: 'insurance', name: 'insuranceNumber', text: 'เลขที่กรมธรรม์'}, 
    { group: 'insurance', name: 'moneyDeduct', text: 'ดีดัก'}, 
    { group: 'insurance', name: 'insuranceIssue', text: 'การออกกรมธรรม์'}, 
    { group: 'insurance', name: 'coverage', text: 'ความคุ้มครอง'}, 
    { group: 'pay', name: '__payMeta', text: 'ประเภทการชำระ'}, 
    { group: 'pay', name: 'moneyInsure', text: 'เบี้ยประกันภัยสุทธิ'}, 
    { group: 'pay', name: 'moneyDiscount', text: 'ส่วนลดลูกค้า'}, 
    { group: 'pay', name: 'moneyFee', text: 'ค่าธรรมเนียม'}, 
    { group: 'pay', name: 'moneyInterest', text: 'ดอกเบี้ย'}, 
    { group: 'pay', name: 'note', text: 'หมายเหตุ'}, 
    { group: 'pay', name: 'moneyFine', text: 'ค่าปรับ'}, 
    { group: 'files', name: 'vehicle_f', text: 'หน้า'}, 
    { group: 'files', name: 'vehicle_b', text: 'หลัง'}, 
    { group: 'files', name: 'vehicle_r', text: 'ข้างขวา'}, 
    { group: 'files', name: 'vehicle_l', text: 'ข้างซ้าย'}, 
    { group: 'files', name: 'vehicle_fr', text: 'มุมหน้าขวา'}, 
    { group: 'files', name: 'vehicle_fl', text: 'มุมหน้าซ้าย'}, 
    { group: 'files', name: 'vehicle_br', text: 'มุมหลังขวา'}, 
    { group: 'files', name: 'vehicle_bl', text: 'มุมหลังซ้าย'}, 
    { group: 'files', name: 'vehicle_license', text: 'ทะเบียนรถ'}, 
    { group: 'files', name: 'tax', text: 'ภาษี'}, 
    { group: 'files', name: 'quotation', text: 'ใบเสนอราคา'}, 
    { group: 'files', name: 'installment', text: 'สัญญา'}, 
    { group: 'files', name: 'poa', text: 'ใบมอบอำนาจ'}, 
    { group: 'files', name: 'cancel', text: 'หนังสือยกเลิก'}, 
    { group: 'files', name: 'deduct', text: 'หนังสือดีดักท์'}, 
    { group: 'files', name: 'checkup_note', text: 'บันทึกตรวจสภาพรถ'}, 
    { group: 'files', name: 'request', text: 'คำขอทำประกัน'}, 
    { group: 'files', name: 'checkup', text: 'ตรวจสภาพรถ'}, 
    { group: 'files', name: 'insurance', text: 'กรมธรรม์'}, 
    { group: 'files', name: 'endorse', text: 'สลักหลัง'}, 
    { group: 'files', name: 'addon', text: 'อุปกรณ์ต่อเติม'}, 
    { group: 'files', name: 'other', text: 'ไฟล์และภาพอื่น ๆ'},
];

export const CALL_STATUSs = [
    { id: 'NO ANSWER', name: 'ไม่รับสาย', color: 'grey'}, 
    { id: 'ANSWERED', name: 'รับสาย', color: 'green'}, 
    { id: 'BUSY', name: 'สายไม่ว่าง', color: 'orange'},
];

export const CALL_TYPEs = [
    { id: 'Outbound', name: 'โทรออก'}, 
    { id: 'Inbound', name: 'โทรเข้า'},
];

export const CHAT_PROFILE_WAYs = [
    { id: '_main', name: 'ข้อมูลหลัก'}, 
    { id: '_way', name: 'ข้อมูลทางเลือก'}, 
    { id: 'greet', name: 'ทักทาย แนะนำตัว'}, 
    { id: 'introduce', name: 'แนะนำประกัน'}, 
    { id: 'decide', name: 'ตัดสินใจเลือกประกัน'}, 
    { id: 'promotion', name: 'แนะนำโปรโมชั่น'}, 
    { id: 'provide', name: 'ให้ข้อมูลการทำประกัน'}, 
    { id: 'payment', name: 'สอบถามการชำระเงิน'}, 
    { id: 'other', name: 'คุยเรื่องอื่นๆ'},
];

export const COMPANY_CAR_CHECKUPs = [
    { id: 0, name: 'บริษัทประกันเป็นผู้ตรวจสภาพรถ'}, 
    { id: 1, name: 'ลูกค้าเป็นคนตรวจ'},
];

export const CONTACT_WAYs = [
    { id: '', name: 'ไม่ระบุ'}, 
    { id: 'tel', name: 'เบอร์โทร'}, 
    { id: 'mobile', name: 'เบอร์มือถือ'}, 
    { id: 'email', name: 'อีเมล'}, 
    { id: 'line', name: 'LINE'},
];

export const CUSTOMER_JOBs = [
    { id: 0, name: 'ไม่ระบุ'}, 
    { id: 1, name: 'พนักงานออฟฟิศ'}, 
    { id: 2, name: 'พนักงานราชการ'}, 
    { id: 3, name: 'พนักงานโรงงาน'}, 
    { id: 4, name: 'ค้าขาย'}, 
    { id: 5, name: 'รับส่งสินค้า'}, 
    { id: 6, name: 'อาชีพอิสระ'},
];

export const CUSTOMER_SALARY_OPTIONs = [
    { id: 1, name: 'ไม่เกิน 9000'}, 
    { id: 9000, name: '9000 - 15000 บาท'}, 
    { id: 15000, name: '15000 - 25000 บาท'}, 
    { id: 25000, name: '25000 - 35000 บาท'}, 
    { id: 35000, name: 'มากกว่า 35000 บาท'},
];

export const CUSTOMER_STATEs = [
    { id: 0, name: 'ลูกค้าติดต่อเข้ามา', icon: 'phone-volume', color: 'grey-blue', fromDoc: 0}, 
    { id: 10, name: 'คนให้ข้อมูล', icon: 'file-invoice-dollar', color: 'sky', fromDoc: 9}, 
    { id: 11, name: 'คนไม่ให้ข้อมูล', icon: 'feather', color: 'pink', fromDoc: 1, fromDocState: 0}, 
    { id: 20, name: 'ผู้สนใจ', icon: 'file-signature', color: 'red', fromDoc: 10}, 
    { id: 21, name: 'ว่าที่ลูกค้า', icon: 'cauldron', color: 'purple', fromDoc: 40}, 
    { id: 30, name: 'ไม่ซื้อสินค้า', icon: 'cash-register', color: 'orange', fromDoc: 1, fromDocState: 40}, 
    { id: 100, name: 'ลูกค้า', icon: 'clipboard-check', color: 'green', fromDoc: 100},
];

export const DOCUMENT_FOLLOW_RESONs = [
    'เงินไม่พอ', 
    'รอเงินออก', 
    'หาเงินไม่ทัน', 
    'ป่วย', 
    'ธนาคารมีปัญหา', 
    'ขอเลื่อน', 
    'อยู่ต่างประเทศ', 
    'อยู่ต่างจังหวัด', 
    'อื่นๆ',
];

export const DOCUMENT_FOLLOW_STATEs = [
    { id: 0, name: 'ไม่มี'}, 
    { id: 2, name: 'ติดต่อไม่ได้'}, 
    { id: 4, name: 'นัดโอน'}, 
    { id: 5, name: 'นัดที่เหลือ'},
];

export const DOCUMENT_ORDER_APPROVEs = [
    { id: -1, name: 'รออนุมัติ', color: 'grey'}, 
    { id: 0, name: 'อนุมัติแล้ว', color: 'green'}, 
    { id: 1, name: 'ไม่อนุมัติ', color: 'red'},
];

export const DOCUMENT_ORDER_CONTRACTs = [
    { id: 300, name: 'ตรวจสัญญา', color: 'red'}, 
    { id: 310, name: 'แก้ไขตรวจสัญญา', color: 'green'},
];

export const DOCUMENT_ORDER_FOLLOW_STATEs = [
    { id: 1, name: 'นัดหมาย/ติดตาม'}, 
    { id: 2, name: 'เลื่อนนัด'}, 
    { id: 3, name: 'เสร็จสิ้น'},
];

export const DOCUMENT_ORDER_OFFER_BROKERs = [
    { id: 1, name: 'เงินติดล้อ'}, 
    { id: 2, name: 'รู้ใจ'}, 
    { id: 3, name: 'ศูนย์รถ'}, 
    { id: 4, name: 'ราคาต่ออายุบริษัทเดิม'}, 
    { id: 5, name: 'อื่นๆ'},
];

export const DOCUMENT_ORDER_OLD_COMPANY_EXPIRE_STATEs = [
    { id: 0, name: 'ระบุวันหมดอายุ'}, 
    { id: 1, name: 'ยังไม่หมด จำวันที่ไม่ได้'}, 
    { id: 2, name: 'ขาดต่อแล้ว จำวันที่ไม่ได้'},
];

export const DOCUMENT_ORDER_OLD_COMPANY_STATEs = [
    { id: 1, name: 'ไม่ทราบ'}, 
    { id: 2, name: 'จำไม่ได้'},
];

export const DOCUMENT_ORDER_PAYMENT_METHODs = [
    { id: 1, name: 'เต็มจำนวน'}, 
    { id: 2, name: 'ผ่อนชำระ'},
];

export const DOCUMENT_ORDER_STATEs = [
    { id: 0, name: 'เสนอราคา', color: 'orange'}, 
    { id: 10, name: 'ยื่นข้อเสนอ', color: 'brown'}, 
    { id: 100, name: 'คำสั่งซื้อ', color: 'blue'}, 
    { id: 200, name: 'อนุมัติ', color: 'blue'}, 
    { id: 290, name: 'รอชำระเงิน', color: 'blue'}, 
    { id: 300, name: 'จ่ายแล้ว', color: 'blue'}, 
    { id: 400, name: 'รอบันทึกรับแจ้ง', color: 'blue'}, 
    { id: 500, name: 'แจ้งความคุ้มครอง', color: 'purple'}, 
    { id: 900, name: 'จบ', color: 'purple'},
];

export const DOCUMENT_ORDER_VEHICLE_USAGEs = [
    { id: 1, name: 'ส่วนบุคคล'}, 
    { id: 2, name: 'ใช้เพื่อการพาณิชย์'}, 
    { id: 3, name: 'ใช้เพื่อการพาณิชย์พิเศษ'}, 
    { id: 4, name: 'รับจ้างสาธารณะ'}, 
    { id: 5, name: 'อื่นๆ'},
];

export const DOCUMENT_STATEs = [
    { id: 0, name: 'ไม่มี', icon: 'ellipsis-h', color: 'grey'}, 
    { id: 1, name: 'ไม่มีการพูดคุย/ทักแล้วเงียบ', icon: 'comment-times', color: 'grey-blue'}, 
    { id: 9, name: 'รอข้อมูล/เอกสารเช็คเบี้ย', icon: 'watch', color: 'sky'}, 
    { id: 10, name: 'เสนอราคาแล้ว', icon: 'file-invoice-dollar', color: 'sky'}, 
    { id: 20, name: 'รอเอกสารทำประกัน', icon: 'feather', color: 'pink'}, 
    { id: 40, name: 'รอชำระเงิน', icon: 'history', color: 'purple'}, 
    { id: 45, name: 'อยู่ระหว่างทำสัญญาและแจ้งความคุ้มครอง', icon: 'cauldron', color: 'purple'}, 
    { id: 100, name: 'ซื้อแล้ว', icon: 'clipboard-check', color: 'green'}, 
    { id: 90, name: 'ลูกค้าเปลี่ยนใจไม่ซื้อ', icon: 'times', color: 'red'},
];

export const FIELD_TYPEs = [
    { id: 1, name: 'text'}, 
    { id: 2, name: 'select'}, 
    { id: 3, name: 'checkbox'}, 
    { id: 4, name: 'radio'}, 
    { id: 5, name: 'date'}, 
    { id: 6, name: 'photo'}, 
    { id: 7, name: 'file'}, 
    { id: 8, name: 'vehicle'},
];

export const INSURETYPEs = [
    { id: 11, name: 'ภาคสมัครใจ', group: 'MOTOR'}, 
    { id: 12, name: 'ภาคบังคับ', group: 'MOTOR'}, 
    { id: 21, name: 'อัคคีภัย', group: 'NON-MOTOR'}, 
    { id: 22, name: 'เบ็ดเตล็ด', group: 'NON-MOTOR'}, 
    { id: 23, name: 'ทางทะเลและขนส่ง', group: 'NON-MOTOR'},
];

export const ORDER_ATTACH_TAGs = [
    { id: 'checkup_note', name: 'บันทึกตรวจสภาพ'}, 
    { id: 'vehicle_f', name: 'ด้านหน้า'}, 
    { id: 'vehicle_b', name: 'ด้านหลัง'}, 
    { id: 'vehicle_l', name: 'ด้านซ้ายเต็มคัน'}, 
    { id: 'vehicle_r', name: 'ด้านขวาต็มคัน'}, 
    { id: 'vehicle_fr', name: 'มุมหน้าขวา'}, 
    { id: 'vehicle_fl', name: 'มุมหน้าซ้าย'}, 
    { id: 'vehicle_br', name: 'มุมหลังขวา'}, 
    { id: 'vehicle_bl', name: 'มุมหลังซ้าย'}, 
    { id: 'vehicle_bt', name: 'หลังคารถ'}, 
    { id: 'vehicle_mileage', name: 'เลขไมล์รถ'}, 
    { id: 'other', name: 'อื่นๆ'},
];

export const PAYCOND_TYPEs = [
    { id: 10, name: 'หักจากยอดชำระลูกค้า'}, 
    { id: 20, name: 'จ่ายแยกจากยอดชำระของลูกค้า'},
];

export const PAYLOT_COUNTs = [
    { id: 0, name: 'เงินสด', name_s: 'เงินสด', icon: 'wallet', color: 'red'}, 
    { id: 2, name: 'ผ่อน 2 เดือน', name_s: '2ด.', icon: 'money-bill-wave-alt', color: 'pink'}, 
    { id: 3, name: 'ผ่อน 3 เดือน', name_s: '3ด.', icon: 'money-bill-wave', color: 'purple'}, 
    { id: 4, name: 'ผ่อน 4 เดือน', name_s: '4ด.', icon: 'money-bill-alt', color: 'indigo'}, 
    { id: 5, name: 'ผ่อน 5 เดือน', name_s: '5ด.', icon: 'money-bill', color: 'blue'}, 
    { id: 6, name: 'ผ่อน 6 เดือน', name_s: '6ด.', icon: 'sack-dollar', color: 'green'}, 
    { id: 8, name: 'ผ่อน 8 เดือน', name_s: '8ด.', icon: 'box-usd', color: 'yellow'}, 
    { id: 10, name: 'ผ่อน 10 เดือน', name_s: '10ด.', icon: 'envelope-open-dollar', color: 'orange'}, 
    { id: 11, name: 'ผ่อน 11 เดือน', name_s: '11ด.', icon: 'envelope-open-dollar', color: 'orange'}, 
    { id: 12, name: 'ผ่อน 12 เดือน', name_s: '12ด.', icon: 'file-invoice-dollar', color: 'brown'},
];

export const PAYWAY_TYPEs = [
    { id: 10, name: 'เงินสด', payagent: true, payemp: true, payservice: true}, 
    { id: 20, name: 'เช็ค'}, 
    { id: 30, name: 'โอนเงิน', bank: true, payemp: true, payservice: true}, 
    { id: 35, name: 'โอนเงินสดย่อย', payagent: true}, 
    { id: 40, name: 'บัตรเครดิต'}, 
    { id: 50, name: 'หักจากยอดชำระของลูกค้า', payagent: true},
];

export const REVENUE_COND_OPTIONs = [
    { id: 'agent_must', name: 'ต้องมีตัวแทน'}, 
    { id: 'agent_no', name: 'ต้องไม่มีตัวแทน'}, 
    { id: 'interest_must', name: 'ต้องมีดอกเบี้ย'}, 
    { id: 'interest_no', name: 'ต้องไม่มีดอกเบี้ย'}, 
    { id: 'discount_no', name: 'ต้องไม่มีส่วนลด'}, 
    { id: 'pay_gt', name: 'ยอดชำระ >'},
];

export const REVENUE_MONEY_OPTIONs = [
    { id: 'money_insure', name: 'เบี้ยสุทธิ'}, 
    { id: 'money_total', name: 'เบี้ยรวม'}, 
    { id: 'money_net', name: 'ยอดเงินรวม'},
];

export const SHOP_TYPEs = [
    { id: 10, name: 'ห้างหุ้นส่วนจำกัด', short: 'หจก.', prefix: 'ห้างหุ้นส่วน', suffix: 'จำกัด'}, 
    { id: 20, name: 'บริษัทจำกัด', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด'}, 
    { id: 30, name: 'บริษัทมหาชนจำกัด', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด'}, 
    { id: 40, name: 'ห้างหุ้นส่วนสามัญ', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด'}, 
    { id: 50, name: 'ร้านค้า', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด'}, 
    { id: 60, name: 'บุคคลธรรมดา', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด'}, 
    { id: 70, name: 'คณะบุคคล', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด'}, 
    { id: 80, name: 'มูลนิธิ', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด'}, 
    { id: 90, name: 'สมาคม', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด'}, 
    { id: 100, name: 'กิจการร่วมค้า', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด'}, 
    { id: 900, name: 'อื่นๆ', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด'},
];

export const VEHICLE_ADDITION_OPTIONs = [
    'อุปกรณ์ดัมพ์', 
    'เครื่องไฮโดรลิก', 
    'เครื่องทำความเย็น',
];

export const VEHICLE_PLACEFIXs = [
    { id: 0, name: 'ซ่อมอู่'}, 
    { id: 1, name: 'ซ่อมศูนย์'},
];

export const VEHICLE_USAGEs = [
    'ส่วนบุคคล', 
    'ใช้เพื่อการพาณิชย์', 
    'ใช้เพื่อการพาณิชย์พิเศษ', 
    'รับจ้างสาธารณะ', 
    'อื่นๆ',
];

export const WEB_CONTENT_TYPEs = [
    { id: 0, name: 'ข่าวสาร'}, 
    { id: 1, name: 'รีวิว'}, 
    { id: 2, name: 'คำถามที่พบบ่อย'}, 
    { id: 3, name: 'บทความ'}, 
    { id: 4, name: 'privacy'}, 
    { id: 5, name: 'cookie'},
];

export const YIELDTYPEs = [
    { id: 11, name: 'ผลตอบแทน'}, 
    { id: 12, name: 'รายการหัก'},
];
