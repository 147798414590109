import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import Input from 'unno-comutils/form/Input'
import Icon, { IconType, Tag } from 'unno-comutils/Icon'
import Button from 'unno-comutils/Button'
import { clsNames, debounce, isEmpty } from 'unno-comutils'
import { isFunction } from 'unno-comutils/utils'
import { ColorName, IconName, LagacyState } from 'unno-comutils/var'
import Dropdown, { DropdownDivider, DropdownItem } from 'unno-comutils/Dropdown'
import Popup from 'unno-comutils/Popup'

// ----- INTERFACE

interface InputSearchTags {
    text: string;
    color?: ColorName | string;
    icon?: IconName | string;
    onClear?: () => void;
}

interface InputSearchFilters {
    id: number | string
    name: string
    icon?: IconType | string
    className?: string
}

interface InputSearchProps {
    children?: ReactNode;

    tags?: InputSearchTags[];  // ป้าย
    value?: any;
    onChange?: (v: any) => void;
    onPressEnter?: (v: any) => void;

    option?: any,
    options?: any[],
    onOptionChange?: (v: any) => void,

    filter?: any;
    filterOptions?: (InputSearchFilters | boolean)[],
    onFilterChange?: (v: any, o: any) => void;

    icon?: boolean | IconType

    defaultOpen?: boolean; // เซ็ต true แล้ว เข้ามาจะเปิด popup ไว้ให้ก่อน

    onOk?: () => void; // ถ้ามีอันนี้ จะมีปุ่ม OK ข้างล่าง สามารถกดแล้วปิด popup ได้
    onRefresh?: () => void; // ถ้ามีอันนี้ จะมีปุ่ม OK ข้างล่าง สามารถกดแล้วปิด popup ได้
    okLabel?: string; // ชื่อปุ่ม OK

    refreshButton?: boolean | string | { label?: string, state?: LagacyState };

    zTop?: boolean;

    lg?: boolean;
    sm?: boolean;
    m0?: boolean;

    className?: string;
    classBox?: string;
    classPopup?: string;
}

interface InputSearchRowProps {
    children?: ReactNode;
    label?: string;
    className?: string;
}

// ----- COMPONENT

export function InputSearchRow (props: InputSearchRowProps) {
    return <div className={clsNames('un-input-search-row', props.className)}>
        <div className="_label">{props.label}</div>
        {props.children}
    </div>
}

export default function InputSearch (props: InputSearchProps) {
    const { defaultOpen, onChange, onOk, onRefresh } = props

    const [focus, setFocus] = useState(false)
    const [popup, setPopup] = useState(false)

    const [value, setValue] = useState('')

    const onTextChange = (v: any) => {
        if (focus) setFocus(false)
        if (popup) setPopup(false)
        setValue(v)
        dOnTextChange(v)
    }

    const dOnTextChange = debounce((v: any) => {
        if (props.onChange) props.onChange(v)
    }, 500)

    // {text,color,icon,onClear}

    const onFocus = () => {
        setFocus(true)
        setTimeout(() => setPopup(true), 200)
    }

    const closePopup = () => {
        setTimeout(() => {
            setFocus(false)
            setPopup(false)
        }, 200)
    }

    // -----

    useEffect(() => {
        if (defaultOpen) setPopup(true)
    }, [defaultOpen])

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    const inputPrepend = useMemo(() => {
        const items: any[] = []

        if (props.filter && props.filterOptions && props.onFilterChange) {
            const filterSelected = props.filter
                ? (typeof props.filter === 'object' && props.filter.id ? props.filter.id : props.filter)
                : null
            const btn = _filterButton(props.filter, props.filterOptions)
            items.push(<Dropdown key={'opt_filter'} className="un-input-search-filter"
                                 button={<Button className={clsNames('_filter-btn', btn.className)} icon={btn.icon}>{btn.name}</Button>}>
                {props.filterOptions.map((o: any, x: number) => {
                    if (typeof o === 'boolean') return <DropdownDivider key={'optd_' + x}/>
                    return <DropdownItem key={'opt_' + x} className={o.className} active={filterSelected === o.id} icon={o.icon} label={o.name}
                                         onClick={() => props.onFilterChange?.(o.id, o)}/>
                })}
            </Dropdown>)
        }

        if (props.tags && !isEmpty(props.tags)) {
            props.tags.forEach((t: any, x: number) => {
                items.push(<Tag key={'tag_' + x} label={t.text} icon={t.icon} color={t.color} onClear={t.onClear}/>)
            })
        }
        if (items.length > 0) return items
        return null
    }, [props.tags, props.filter, props.filterOptions])

    const inputProps: any = {
        icon: props.icon === undefined ? 'search' : (props.icon === false ? undefined : props.icon),
        className: clsNames('un-input-search', props.className),
        prepend: inputPrepend,

        sm: props.sm,
        lg: props.lg,
        m0: props.m0,
    }

    if (isFunction(onRefresh)) {
        if (props.refreshButton) {
            let btnState: LagacyState = 'secondary'
            let btnLabel = 'OK'
            if (typeof props.refreshButton === 'object') {
                btnState = props.refreshButton.state || 'secondary'
                btnLabel = props.refreshButton.label || 'OK'
            }
            else if (typeof props.refreshButton === 'string') {
                btnLabel = props.refreshButton
            }
            inputProps.apppend = <Button sm state={btnState} onClick={onRefresh}>{btnLabel}</Button>
        }
        else {
            inputProps.apppend = <Icon button name={'sync green'} onClick={onRefresh}/>
        }
    }

    if (!!onChange) {
        inputProps.value = value
        inputProps.onChange = (v: any) => onTextChange(v)
        inputProps.onFocus = onFocus
        inputProps.onBlur = () => setFocus(false)
        inputProps.onPressEnter = props.onPressEnter
    }
    else {
        inputProps.readOnly = true
        inputProps.onClick = () => setFocus(true)
    }

    return <Input {...inputProps}>
        {props.children && (focus || popup) && <Popup className={props.classPopup} onClose={closePopup}>
            {props.children}
            {isFunction(onOk) && <Button primary className="btn-ok" onClick={() => {
                setPopup(false)
                if (onOk) onOk()
            }}>{props.okLabel || 'OK'}</Button>}
        </Popup>}
    </Input>
}

function _filterButton (filter: any, options: any) {
    if (filter) {
        if (typeof filter === 'object')
            return filter

        const opt = options.find((o: any) => o.id == filter)
        if (opt) return opt
    }
    return { name: '...' }
}
