import React, { useEffect, useMemo, useRef, useState } from 'react'

import { clsNames, date, dbdate, dConfirm, debounce, dError, get, isEmpty, num, post, qsget, qsset, round, tSuccess, useUser } from 'unno-comutils'
import { fromRangeParam, Input, InputDate, InputDateRange, InputSearch, InputSearchRow, Select } from 'unno-comutils/form'
import { Button, createPaging, Icon, List, ListBody, ListContainer, ListFoot, ListHead, Modal, PageTitle, Paging, Wait } from 'unno-comutils/ui'
import { cellValue, EWidth } from 'unno-comutils/Datagrid'

import Datagrid, { columnIconClick, IDatagridColumns } from '../../components/test/Datagrid'
import { hhash, hhashRemove, hhashUpdate } from '../../utils'
import { createTags } from '../../service/search'
import { RevenueList } from '../../components/account'
import { DEFAULT_ACCOUNT_EMPLOYEE } from '../../service/var.default'

export default function Employee (props: any) {
    const [wait, setWait] = useState(false)

    const [datas, setDatas] = useState<any>([])
    const [form, setForm] = useState<any>(null)

    const [tags, setTags] = useState<any>([])
    const [searchDate, setSearchDate] = useState<any>('')
    const [searchEmployee, setSearchEmployee] = useState<any>('')

    const [paging, setPaging] = useState(createPaging(1))

    // ----- ACTION

    const loadList = debounce((page?: any) => {
        if (!page) page = paging
        const qs = { page: page?.page || 1, date: dbdate(searchDate), employee: searchEmployee?.id }
        setWait(true)
        get('account/employee/list', qs).then(({ ok, datas, paging }) => {
            if (ok) {

                setTags(createTags({ searchDate, setSearchDate, searchEmployee, setSearchEmployee }))

                setDatas(datas)
                setPaging(paging)

                const h = hhash()
                if (!h) qsset(qs)
            }
            //is(isDev) setForm(100850)
        }).finally(() => setWait(false))
    }, 500)

    useEffect(() => {

        const qs = qsget()
        const { employee } = qs
        if (qs.date) setSearchDate(fromRangeParam(qs.date))
        if (qs.page) setPaging(createPaging(num(qs.page)))
        if (employee)
            get('app/qs', { employee }).then(d => {
                if (d.ok && d.employee) setSearchEmployee(d.employee)
            })

        const h = hhash()
        if (h && h.type === 'employee') setForm(h.id)

        //if (isDev) setForm(101174)
        //if (isDev) setForm(0)
    }, [])

    // ----- MEMO

    useEffect(() => {
        loadList()
    }, [searchDate, searchEmployee])

    const sum = useMemo(() => datas?.reduce((s: any, d: any) => {
        s.count += d.count
        s.moneySalary += d.moneySalary
        s.employeeMoney += d.employeeMoney
        s.employeeMoneyInsentive += d.employeeMoneyInsentive
        s.moneyBonus += d.moneyBonus

        s.employeeMoneyAgent += d.employeeMoneyAgent

        s.money += d.money
        return s
    }, {
        count: 0,
        moneySalary: 0,
        employeeMoney: 0,
        employeeMoneyInsentive: 0,
        moneyBonus: 0,
        employeeMoneyAgent: 0,
        money: 0
    }) || {}, [datas])

    const tableDatas = useMemo(() => {
        return datas.map((d: any) => ({
            ...d,
            date: date(d.date, 'S'),
            employee: d.employee?.name || (d.employee?.id ? '?[' + d.employee?.id + ']' : '?')
        }))
    }, [datas])

    const totalDatas = useMemo(() => {
        if (paging && paging.pageCount === 1) {
            const dataTotal = { _text: 'รวม', ...sum }
            return [dataTotal]
        }
        return undefined
    }, [datas])

    const tableColumns = useMemo<IDatagridColumns[]>(() => [
        { name: '_rownum', label: '', width: EWidth.ROWNUM, style: 'c' },
        { name: 'date', label: 'วันที่', width: EWidth.DATE, style: 'r -blue-text' },
        { name: 'employee', label: 'พนักงาน', width: 150, },
        { name: 'count', label: 'รายการ', width: 60, style: 'c' },
        { name: 'moneySalary', label: 'เงินเดือน', width: 90, style: 'r -orange-text', format: 'number.2' },
        { name: 'employeeMoney', label: 'ค่าคอม', width: 90, style: 'r -grey-blue-text', format: 'number.2' },
        { name: 'employeeMoneyInsentive', label: 'Insentive', width: 90, style: 'r -grey-blue-text', format: 'number.2' },
        { name: 'employeeMoneyAgent', label: 'ค่าดูแล', width: 80, style: 'r -grey-blue-text', format: 'number.2' },
        { name: 'moneyBonus', label: 'โบนัส', width: 90, style: 'r -blue-text', format: 'number.2' },
        { name: 'money', label: 'ยอดรวม', width: 90, style: 'r -green-text', format: 'number.2' },
        { name: 'note', label: 'หมายเหตุ', width: 200, fill: true },
        columnIconClick({ name: 'btn_print', icon: 'print', pinned: 'right', href: (_, d) => '/d/account_employee.pdf?id=' + d.id, targetBlank: true }),
        columnIconClick({ name: 'btn_edit', icon: 'cog', pinned: 'right', onClick: (_, p: any) => setForm(p.id), }),
    ], [])

    // ----- RENDER

    return <>
        <PageTitle icon="briefcase" title="ตัดจ่ายพนักงาน">
            <InputSearch tags={tags} onRefresh={() => loadList()}>
                <InputSearchRow label="วันที่" children={<InputDateRange icon fixWidth value={searchDate} onChange={v => setSearchDate(v)}/>}/>
                <InputSearchRow label="พนักงาน" children={<Select value={searchEmployee} url="/ac/employee" onChange={(_, v) => setSearchEmployee(v)}/>}/>
            </InputSearch>
            <Button success className="ml-2" onClick={() => setForm(0)}>เพิ่มรายการใหม่</Button>
        </PageTitle>

        <Datagrid wait={wait} wrapText name={'account_employee'} datas={tableDatas} datasTotal={totalDatas} columns={tableColumns}/>

        <Paging paging={paging} onChange={loadList}/>
        <EmployeeForm id={form} onSave={() => loadList()} onClose={() => setForm(null)}/>
    </>
}

const DEFAULT_EMPLOYEE_SUM = () => ({ nodeSelecteds: 0, employeeMoney: 0, employeeMoneyAgent: 0, employeeMoneyInsentive: 0 })

export function EmployeeForm (props: any) {
    const user = useUser()
    const [wait, setWait] = useState(false)

    const [form, setForm] = useState<any>({})
    const [employee, setEmployee] = useState<any>({})

    const [formEditSalary, setFormEditSalary] = useState(false)

    const [checked, setChecked] = useState<any>([])

    const [sum, setSum] = useState(DEFAULT_EMPLOYEE_SUM())

    const [documents, setDocuments] = useState<any>([])
    const [searchDate, setSearchDate] = useState<any>('')

    const [dateMoneySalaryPay, setDateMoneySalaryPay] = useState('')

    const [revenueLoading, setRevenueLoading] = useState(false)
    const [revenueDatas, setRevenueDatas] = useState(null)
    const [revenueProcess, setRevenueProcess] = useState(null)

    const gridRef = useRef<any>()

    // ----- ACTION

    const loadData = () => {
        if (props.id > 0) {
            setWait(true)
            get('account/employee/get/' + props.id).then(({ ok, data, ...etc }) => {
                if (ok) {
                    const { documents, ...o } = data
                    setForm(o)
                    loadEmployee(o.employee?.id)
                    hhashUpdate('employee,' + data.id)
                }
                else {
                    dError(etc)
                    props.onClose()
                }
            }).finally(() => setWait(false))
        }
        else {
            /*
            if (isDev) {
                setForm({ ...DEFAULT_ACCOUNT_EMPLOYEE, employee: { id: 1002, name: 'รี' } })
                loadEmployee(1002)
                setTimeout(() => {
                    //console.log('onCheckAll')
                    onCheckAll(true)
                }, 2000)
            }
            else {
            }*/
            setRevenueDatas(null)
            setForm({ ...DEFAULT_ACCOUNT_EMPLOYEE })
            setDocuments([])
        }
    }

    const loadEmployee = (id: any) => {
        setWait(true)
        get('setting/employee/get/' + id).then(({ ok, data }) => {
            if (ok) {
                setEmployee({ ...data, banks: data.banks?.map((b: any) => ({ id: b.number, name: b.number + ' : ' + b.account })) || [] })
            }
        }).finally(() => setWait(false))
    }

    const loadDoc = () => {
        setDocuments([])
        setWait(true)
        if (employee?.id) {
            get('account/employee/documents', { id: props.id, date: dbdate(searchDate), employee: employee?.id }).then(({ ok, documents }) => {
                if (ok) {
                    const __documents = documents.map((d: any) => ({
                        ...d,
                        date: date(d.date),
                        dateStart: date(d.dateStart),
                        customer: d.customer?.name || '',
                        agent: d.agent?.name || '',
                        metaInsuretype: d.metaInsuretype?.name || '',
                        isNew: d.isNew ? 'ใหม่' : 'ต่ออายุ',
                        btnEdit: { id: d.id }
                    })).filter((d: any) => !d.isRevoke)

                    const ccs = __documents.filter((d: any) => d.checked).map((d: any) => d.id)
                    setChecked(ccs)
                    setDocuments(__documents)
                }
            }).finally(() => setWait(false))
        }
    }

    const saveData = (c: any) => {
        const saveData = {
            employee: form.employee?.id,
            employeeMoneySalary: form.moneySalary,
            moneyBonus: form.moneyBonus,
            date: date(form.date, 'DB'),
            type: form.type,
            bank: form.bank?.id,
            note: form.note,
            documents: gridRef.current.api.getSelectedNodes().map((d: any) => ({
                id: d.data.id,
                employeeMoney: cellValue(d.data.employeeMoney),
                employeeMoneyAgent: cellValue(d.data.employeeMoneyAgent),
                employeeMoneyInsentive: cellValue(d.data.employeeMoneyInsentive),
            }))
        }

        //console.log('saveData = ', saveData.documents)
        //return c()

        post('account/employee/save/' + props.id, saveData).then(d => {
            if (d.ok) {
                tSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
                props.onSave(d.id)
                props.onClose()
            }
            else dError(d)
        }).finally(c)
    }

    const deleteData = () => {
        dConfirm('ยืนยันการลบข้อมูล').then(() => {
            get('account/employee/delete/' + props.id).then(d => {
                if (d.ok) {
                    tSuccess('ลบข้อมูลเรียบร้อยแล้ว')
                    props.onSave()
                    props.onClose()
                }
                else dError(d)
            })
        })
    }

    const calSum = debounce(() => {
        const nodes = gridRef.current && gridRef.current.api.getSelectedNodes() || []
        const nodeSelecteds = nodes.map((d: any) => ({
            id: d.data.id,
            employeeMoney: cellValue(d.data.employeeMoney),
            employeeMoneyAgent: cellValue(d.data.employeeMoneyAgent),
            employeeMoneyInsentive: cellValue(d.data.employeeMoneyInsentive),
        })) || []

        const employeeMoney = nodeSelecteds.reduce((sum: any, d: any) => sum + num(d.employeeMoney), 0)
        const employeeMoneyAgent = nodeSelecteds.reduce((sum: any, d: any) => sum + num(d.employeeMoneyAgent), 0)
        const employeeMoneyInsentive = nodeSelecteds.reduce((sum: any, d: any) => sum + num(d.employeeMoneyInsentive), 0)

        setSum({
            nodeSelecteds: nodeSelecteds.length,
            employeeMoney,
            employeeMoneyAgent,
            employeeMoneyInsentive,
        })
    }, 300)

    const calRevenue = debounce(() => {
        const docs = gridRef.current.api.getSelectedNodes().map((d: any) => d.data.id)
        //console.log('docs select = ', docs)
        if (docs && docs.length > 0) {
            setRevenueLoading(true)
            post('account/employee/cal_revenue/' + employee.id, { docs }).then(d => {
                setRevenueDatas(d.datas || [])
                if (d.datas && d.datas.length > 0) {
                    for (const g of d.datas) {
                        if (g.docs && g.docs.length > 0) {
                            for (const h of g.docs) {
                                if (h.moneyProcess >= 0) {
                                    gridRef.current.api.forEachNode((rowNode: any) => {
                                        if (rowNode.data.id === h.id) {
                                            rowNode.setDataValue('employeeMoney', round(h.moneyProcess, 2))
                                            calSum()
                                        }
                                    })
                                }
                            }
                        }
                    }
                }
            }).finally(() => setRevenueLoading(false))
        }
        else {
            setRevenueDatas(null)
            calSum()
        }
    }, 300)

    // ----- EVENT

    const onChange = (update: any) => {
        if (update.hasOwnProperty('employee')) {

            setSum(DEFAULT_EMPLOYEE_SUM())

            update.bank = ''
            loadEmployee(update.employee.id)
        }
        setForm((prev: any) => ({ ...prev, ...update }))
    }

    const onUnCheck = (ids: any) => {
        if (!readOnly) {
            const nodes: any = []
            gridRef.current.api.forEachNode((node: any) => {
                if (node.data && ids.indexOf(node.data.id) >= 0) nodes.push(node)
            })
            gridRef.current.api.setNodesSelected({ nodes })
        }
    }

    function onCheckNew (chk: any) {
        setChecked(chk)
        calRevenue()
    }

    const onClose = () => {
        hhashRemove()

        // ----- clear

        setDocuments([])
        setChecked([])
        setRevenueDatas(null)
        setSum(DEFAULT_EMPLOYEE_SUM())
    }

    function onEditedDocument (rowIndex: number, colName: string, row: any, rows: any) {
        const value = row[colName]?.value || row[colName]
        if (gridRef.current.api) {
            gridRef.current.api.forEachNode((rowNode: any, index: number) => {
                if (rowIndex === index) {
                    if (typeof value === 'string' && value.trim().endsWith('%')) {
                        const moneyInsure = cellValue(rowNode.data.moneyInsure)
                        rowNode.setDataValue(colName, num((moneyInsure * num(value)), 2))
                    }
                    else {
                        rowNode.setDataValue(colName, num(value, 2))
                    }
                    calSum()
                }
            })
        }
    }

    // ----- DATATABLE

    function onGridReady (el: any) {
        gridRef.current = el
    }

    const readOnly = !!props.readOnly && user.allow('account.employee.edit_comm')

    const tableColumns = useMemo<IDatagridColumns[]>(() => {
        return [
            { name: 'rownum', label: '#', width: 40, style: 'c' },
            { name: 'date', label: 'วันแจ้งงาน', width: 110, style: 'c' },
            { name: 'dateStart', label: 'เริ่มคุ้มครอง', width: 110, style: 'c' },
            { name: 'insuranceNumber', label: 'กรมธรรม์เลขที่', width: 200, style: 'c' },
            { name: 'vehicleLicense', label: 'ทะเบียน', width: 110, style: 'c' },
            { name: 'customer', label: 'ชื่อลูกค้า', width: 200 },
            { name: 'metaInsuretype', label: 'ประเภทประกัน', width: 110, style: 'c' },
            { name: 'moneyInsure', label: 'เบี้ยประกันภัยสุทธิ', width: 120, style: 'r', format: 'number.2' },
            { name: 'moneyTotal', label: 'เบี้ยประกันภัยรวม', width: 120, style: 'r', format: 'number.2' },
            { name: 'moneyNet', label: 'ยอดเงินรวม', width: 120, style: 'r', format: 'number.2' },
            { name: 'paylotMoneyPay', label: 'ยอดลูกค้าชำระ', width: 120, style: 'r', format: 'number.2' },
            { name: 'agent', label: 'ตัวแทน', width: 110, style: 'c' },
            { name: 'isNew', label: 'ประเภทงาน', width: 110, style: 'c' },
            { name: 'paytype', label: 'ประเภทการ ผ่อนชำระ', width: 130, style: 'c' },
            { name: 'employeeMoney', label: 'ค่าคอม', width: 120, style: 'r -blue', editable: !readOnly },
            { name: 'employeeMoneyInsentive', label: 'Insentive', width: 120, style: 'r -blue', editable: !readOnly },
            { name: 'employeeMoneyAgent', label: 'ค่าดูแล', width: 120, style: 'r -blue', editable: !readOnly },
            { name: 'note', label: 'หมายเหตุ', width: 250 },
        ]
    }, [readOnly])

    const tableDatas = useMemo(() => {
        return documents.map((d: any, i: number) => ({ ...d, rownum: i + 1 }))
    }, [documents, readOnly])

    const propMeodal = readOnly ? {} : { footerSave: saveData, footerDrop: props.id > 0 && deleteData }

    // ----- EFFECT

    useEffect(() => {
        loadDoc()
    }, [searchDate, employee])

    useEffect(() => {
        if (employee.id > 0) {
            get('account/employee/check_salary_pay', { employee: employee.id, date: form.date }).then(d => {
                onChange({ moneySalary: d.salary })
                setDateMoneySalaryPay(d.salaryDate)
            })
        }
    }, [form.date, employee])

    // ----- RENDER

    return <>
        <Modal title="ตัดจ่ายพนักงาน" full noCloseBackdrop open={props.id !== null} {...propMeodal}
               onClose={props.onClose} onCloseEnd={onClose} onOpenEnd={loadData}
               footer={<Icon button name={'sync green'} onClick={loadDoc}/>}>
            {wait && employee?.id > 0 && <Wait/>}
            {readOnly
                ? <div className="flex it-start">
                    <Input readOnly icon="calendar-alt" label="วันที่ตัดจ่าย" className="w-40" center value={date(form.date)}/>
                    <Input readOnly label="พนักงาน" className="w-52 ml-4" value={form.employee?.name}/>
                    <Input readOnly label="หมายเหตุ" className="ml-4 w-fill" value={form.note}/>
                </div>
                : <div className="flex it-start">
                    <InputDate icon fixWidth noClear label="วันที่ตัดจ่าย" value={form.date} onChange={date => onChange({ date })}/>
                    <Select label="พนักงาน" className="w-52 ml-4" noClear url={'ac/employee'} value={form.employee} onChange={(_, v) => onChange({ employee: v })}/>
                    <Input label="หมายเหตุ" multiline className="ml-4 w-fill" value={form.note} onChange={note => onChange({ note })}/>
                </div>}

            <div className="flex -mt-2">
                <div className="form-datatable w-fill">
                    <div className="_header">
                        <span className="_title">รายการ</span>
                        {!readOnly && <InputDateRange icon fixWidth className="ml-4 mb-0" value={searchDate} onChange={v => setSearchDate(v)}/>}

                        <span className="ml-auto">เลือกแล้ว</span>
                        <strong className="mx-2 red">{num(sum.nodeSelecteds)}</strong>
                        <span>รายการ , รวมยอดค่าคอม</span>
                        <strong className="mx-2 red">{num(sum.employeeMoney, 2)}</strong>
                        <span>บาท, รวมยอด Insentive</span>
                        <strong className="mx-2 red">{num(sum.employeeMoneyInsentive, 2)}</strong>
                        <span>บาท</span>
                        <span>บาท, รวมยอดค่าดูแล</span>
                        <strong className="mx-2 red">{num(sum.employeeMoneyAgent, 2)}</strong>
                        <span>บาท</span>
                    </div>

                    <Datagrid wait={wait} wrapText
                              className={'height-300'}
                              name={'account_employee_form'}
                              datas={tableDatas}
                              columns={tableColumns}
                              onReady={onGridReady}
                              onEdited={onEditedDocument}
                              checkbox={['id', checked, onCheckNew]}
                    />

                </div>
                <RevenueList loading={revenueLoading} datas={revenueDatas} onView={setRevenueProcess} onUnCheck={onUnCheck}/>
            </div>

            <div className="flex mt-4">
                <div className="sumbox ml-auto">
                    {isEmpty(dateMoneySalaryPay) && user.allow('account.employee.edit_salary') &&
                        <Icon button name={'pencil'} className="_icon-edit" onClick={() => setFormEditSalary(p => !p)}/>}
                    <div className="_label">เงินเดือน</div>
                    {isEmpty(dateMoneySalaryPay) && formEditSalary && user.allow('account.employee.edit_salary')
                        ? <Input m0 className="_input" center value={form.moneySalary} onChange={v => onChange({ moneySalary: num(v) })}/>
                        : <>
                            <div className={clsNames('_value', dateMoneySalaryPay !== '' && 'red')}>{num(form.moneySalary, 2)}</div>
                            <div className="small red">{dateMoneySalaryPay !== '' && 'จ่ายแล้ว ' + date(dateMoneySalaryPay, 'S')}</div>
                        </>}
                </div>
                <div className="sumbox-fm ml-4"><Icon name="plus grey"/></div>
                <div className="sumbox ml-4">
                    <div className="_label">ยอดค่าคอม</div>
                    <div className="_value">{num(sum.employeeMoney, 2)}</div>
                </div>
                <div className="sumbox-fm ml-4"><Icon name="plus grey"/></div>
                <div className="sumbox ml-4">
                    <div className="_label">Insentive</div>
                    <div className="_value">{num(sum.employeeMoneyInsentive, 2)}</div>
                </div>
                <div className="sumbox-fm ml-4"><Icon name="plus grey"/></div>
                <div className="sumbox ml-4">
                    <div className="_label">ยอดค่าดูแล</div>
                    <div className="_value">{num(sum.employeeMoneyAgent, 2)}</div>
                </div>
                <div className="sumbox-fm ml-4"><Icon name="plus grey"/></div>
                <div className="sumbox ml-4">
                    <div className="_label">โบนัส</div>
                    <div className="_value"><Input m0 type={'number'} value={form.moneyBonus} onChange={v => onChange({ moneyBonus: v })}/></div>
                </div>
                <div className="sumbox-fm ml-4"><Icon name="equals grey"/></div>
                <div className="sumbox ml-4">
                    <div className="_label">รวมรายจ่าย</div>
                    <div
                        className="_value">{num(((dateMoneySalaryPay !== '' ? 0 : form.moneySalary) + sum.employeeMoney + sum.employeeMoneyInsentive + sum.employeeMoneyAgent + form.moneyBonus), 2)}</div>
                </div>
            </div>
        </Modal>
        <RevenueProcess data={revenueProcess} onClose={() => setRevenueProcess(null)}/>
    </>
}

export function RevenueProcess (props: any) {
    const { data, tax } = props
    const total = useMemo(() => data?.docs?.reduce((s: any, d: any) => ({
        moneyInsure: s.moneyInsure + d.moneyInsure,
        moneyTotal: s.moneyTotal + d.moneyTotal,
        moneyDiscount: s.moneyDiscount + d.moneyDiscount,
        moneyNet: s.moneyNet + d.moneyNet,
        moneyProcess: s.moneyProcess + d.moneyProcess,
        moneyProcessTax: s.moneyProcessTax + d.moneyProcessTax
    }), { moneyInsure: 0, moneyTotal: 0, moneyDiscount: 0, moneyNet: 0, moneyProcess: 0, moneyProcessTax: 0 }), [data])

    if (!data) return <></>

    return <Modal title={data.name} lg noCloseBackdrop open onClose={props.onClose}>
        <h6 className="grey-blue mb-2">{data.condName}</h6>
        <ListContainer className="height-500">
            <ListHead>
                <div className="w-10 c">#</div>
                <div className="w-date c">วันที่แจ้ง</div>
                <div className="w-fill">ลูกค้า</div>
                <div className="w-24 r">เบี้ยสุทธิ</div>
                <div className="w-24 r">เบี้ยรวม</div>
                <div className="w-24 r">ส่วนลด</div>
                <div className="w-24 r">ยอดเงินรวม</div>
                <div className="w-24 r">ผลตอบแทน</div>
                {tax && <div className="w-16 r">ภาษี</div>}
                <div className="w-10 r"/>
            </ListHead>
            <ListBody scroll>
                {data.docs.map((d: any, x: any) => <List key={'doc_' + d.id}>
                    <div className="w-10 c">{x + 1}</div>
                    <div className="w-date c">{date(d.date)}</div>
                    <div className="w-fill">{d.customer}{d.isRevoke && <span className={'red'}>ยกเลิก</span>}</div>
                    <div className="w-24 r grey-blue">{num(d.moneyInsure, 2)}</div>
                    <div className="w-24 r grey-blue">{num(d.moneyTotal, 2)}</div>
                    <div className="w-24 r red">{!!d.moneyDiscount && num(d.moneyDiscount, 2)}</div>
                    <div className="w-24 r blue">{num(d.moneyNet, 2)}</div>
                    <div className="w-24 r green">{num(d.moneyProcess, 2)}</div>
                    {tax && <div className="w-16 r green">{num(d.moneyProcessTax, 2)}</div>}
                    <div className="w-10 c green-light small pt-1">{d.moneyProcess_p}</div>
                </List>)}
            </ListBody>
            <ListFoot>
                <div className="w-fill">รวม</div>
                <div className="w-24 r grey-blue">{num(total.moneyInsure, 2)}</div>
                <div className="w-24 r grey-blue">{num(total.moneyTotal, 2)}</div>
                <div className="w-24 r red">{!!total.moneyDiscount && num(total.moneyDiscount, 2)}</div>
                <div className="w-24 r blue">{num(total.moneyNet, 2)}</div>
                <div className="w-24 r green">{num(total.moneyProcess, 2)}</div>
                {tax && <div className="w-16 r green">{num(total.moneyProcessTax, 2)}</div>}
                <div className="w-10 r"/>
            </ListFoot>
        </ListContainer>

        <div className="flex my-4">
            <div className="sumbox ml-auto -sm">
                <label className="_label">จำนวน</label>
                <span className="_value c">{num(data.count)}</span>
            </div>
            <div className="sumbox-fm ml-4"><Icon name="plus grey"/></div>
            <div className="sumbox ml-4">
                <label className="_label">ยอดเงินที่เอามาคำนวน</label>
                <span className="_value">{num(data.money, 2)}</span>
            </div>
            <div className="sumbox-fm ml-4"><Icon name="equals grey"/></div>
            <div className="sumbox ml-4">
                <label className="_label">ยอดที่ได้</label>
                <span className="_value">{num(data.moneyProcess, 2)}</span>
            </div>
            {tax && <div className="sumbox ml-4">
                <label className="_label">ภาษี หัก ณที่จ่าย</label>
                <span className="_value">{num(data.moneyProcessTax, 2)}</span>
            </div>}
        </div>
    </Modal>
}


