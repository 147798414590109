import React, { useMemo } from 'react'
import { Checkbox, FormContainer } from 'unno-comutils/form'
import { useMeta } from '../../../app'

export function FormLookup (props: any) {
    const meta = useMeta()
    const { document, onChange } = props

    const insureTypes = useMemo(() => meta.INSURE_TYPEs.filter((v: any) => v.name.startsWith('ป.')), [])

    if (!document) return <></>

    return <FormContainer collapse={'book_lookup'} label={'ความสนใจ'} className={'docform-lookup'}>
        <Checkbox label={'ประเภทประกัน'} items={insureTypes} checked={document.lookup} onChange={lookup => onChange({ lookup })}/>
    </FormContainer>
}