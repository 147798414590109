import React, { useEffect, useMemo, useState } from 'react'

import { date, dError, get, num, post, todate, tSuccess, useUser } from 'unno-comutils'
import { Button, Icon, List, ListContainer, ListStack, Modal, Wait } from 'unno-comutils/ui'
import { ListButton } from 'unno-comutils/List'
import { Checkbox } from 'unno-comutils/form'
import { diffDate } from 'unno-comutils/utils'

import { DOCUMENT_PHOTO_TAGs, DOCUMENT_PHOTOs, VEHICLE_FIXPLACEs } from '../../service/var'
import DetailItem from '../../components/test/DetailItem'
import { CoverageView } from '../../components/view'
import { BookDocumentPaylot } from '../../components/book'
import { CustomerForm } from '../customer'
import { AgentForm } from '../account/agent'
import { EmployeeForm } from '../account/employee'
import { CompanyForm } from '../account/company'
import { addressText, hhashRemove, hhashUpdate } from '../../utils'
import { fileIconExt } from './components/attach'
import { MockEmpty } from '../../components/common'
import { useMeta } from '../../app'

const CustomerView = (props: any) => {
    const { customer } = props
    if (!customer) return <DetailItem label="ลูกค้า">?</DetailItem>

    const address = customer?.contactData?.addressSendmail === 'address' ? customer?.contactData?.address : customer?.contactData?.address

    return <>
        <DetailItem label={props.label}>{customer.name}
            <Button className="ml-2" sm info onClick={() => props.onEdit(customer.id)}>แก้ไข</Button>
        </DetailItem>
        <DetailItem label="ที่อยู่">{addressText(address)}</DetailItem>
        <DetailItem label="เบอร์โทร">{customer?.contactData?.tel || ''}</DetailItem>
    </>
}

export const HistoryModal = (props: any) => {
    const [logs, setLogs] = useState<any>(null)

    // ----- ACTION

    const loadData = () => {
        get('app/action_log', { search: 'name:' + props.name }).then(({ ok, logs }) => {
            if (ok) setLogs(logs)
            else setLogs(null)
        })
    }

    // ----- EVENT

    const onToggle = (index: any) => {
        if (logs && logs.length > index) {
            logs[index].show = !logs[index].show
            setLogs([...logs])
        }
    }

    // ----- RENDER

    const typeColor = (name: any) => {
        if (name === 'send_doc') return 'blue-bg'
        if (name === 'send_doc.remove') return 'red-bg'
        return ''
    }

    return <Modal className="history-modal" title={'ข้อมูลประวัติการแก้ไข'} lg open={props.name !== null}
                  onClose={props.onClose} onOpenEnd={loadData} onCloseEnd={() => setLogs(null)}>
        {props.children}
        <ListContainer>
            {logs && logs.length > 0 ? logs.map((d: any, x: any) => <ListStack key={'item_' + d.time + '_' + x}>
                <List>
                    <ListButton fill onClick={() => onToggle(x)}>
                        <div className="w-52 green">{d.action.join(' , ')}</div>
                        <div className="w-fill">{d.url}</div>
                        <div className="w-40 r blue">{d.user && d.user.split(' ').join(' , ')}</div>
                        <div className="w-44 grey-blue r">{date(d.time, 'ST')}</div>
                    </ListButton>
                </List>
                {d.show && <List>
                    <div className="w-fill">
                        <pre className="text-code">{typeof d.detail === 'object' ? JSON.stringify(d.detail, null, 4) : d.detail}</pre>
                        {d.data && <pre className="text-code">{typeof d.data === 'object' ? JSON.stringify(d.data, null, 4) : d.data}</pre>}
                    </div>
                </List>}
            </ListStack>) : <MockEmpty/>}
        </ListContainer>
    </Modal>
}

export function PrintView (props: { id: number, isPrint?: boolean, onClose: () => void }) {
    const { isPrint } = props

    const [items, setItems] = useState([])
    const [prints, setPrints] = useState<any>([])

    function loadData (id: number) {
        if (id > 0) {
            get('book/prints/' + id).then((d: any) => {
                if (d.ok) {
                    setItems(d.items)
                }
            })
        }
        else {
            setItems([])
        }
    }

    const sendDocLine = (c: any) => {
        post('book/send_doc_line/' + props.id, { prints: prints.join(',') }).then((d: any) => {
            if (d.ok) {
                tSuccess('ส่งข้อมูลแล้ว')
                props.onClose()
            }
            else {
                dError(d)
            }
        }).finally(c)
    }

    const onChanagePrint = (key: any) => setPrints((prev: any) => prints.indexOf(key) >= 0 ? prev.filter((d: any) => d !== key) : [...prev, key])

    useEffect(() => {
        loadData(props.id)
    }, [props.id])

    return <Modal title={'พิมพ์'} open={props.id > 0} onClose={props.onClose} onCloseEnd={() => setPrints([])}
                  footerSave={prints.length > 0 ? [sendDocLine, { labelButton: 'ส่งไฟล์' }] : undefined}>
        {items.map((d: any, index: number) => <div key={'i_' + index} className="document-print-item">
            {isPrint && (d.reprint ? <Checkbox m0 checked={prints.indexOf(d.key) >= 0} onChange={() => onChanagePrint(d.key)}/> : <div className="_check-none"/>)}
            <Icon name={'print'} className="_icon"/>
            <div className="_name">{d.name}</div>
            {d.urlPdf && <Button sm href={d.urlPdf} targetBlank className="_btn">PDF</Button>}
            {d.urlImg ? <Button sm href={d.urlImg} targetBlank className="_btn">รูป</Button> : <div className="_btn-empty"/>}
        </div>)}
    </Modal>
}

export const BookView = (props: any) => {
    const meta = useMeta()
    const user = useUser()

    const [wait, setWait] = useState(false)

    const [history, setHistory] = useState(null)
    const [customer, setCustomer] = useState(null)
    const [viewData, setViewData] = useState<any>(null)
    const [viewPhotos, setViewPhotos] = useState([])

    const [viewPaycomp, setViewPaycomp] = useState(null)
    const [viewPayagent, setViewPayagent] = useState(null)
    const [viewPayemp, setViewPayemp] = useState(null)

    // ----- ACTION

    const loadData = () => {
        if (props.id > 0) {
            setWait(true)
            get(props.get ? 'book_wait/get/' + props.id : 'book/get/' + props.id).then(({ ok, data }) => {
                if (ok) {
                    const dphotos = (!!data.photos && data.photos
                        .map((p: any, index: any) => ({
                            index,
                            tag: p.tag,
                            url: p.url,
                            title: DOCUMENT_PHOTOs.find(dp => dp.key === p.tag)?.label || '',
                            detail: p.name
                        }))) || []

                    setViewData(data)
                    setViewPhotos(dphotos)
                    hhashUpdate('view,' + data.id)
                }
                else props.onClose()
            }).finally(() => setWait(false))
        }
        else setViewData(null)
    }

    const customerSave = (d: any) => {
        setCustomer(null)
        if (props.onReview)
            props.onReview(d.id)
    }

    // ----- EVENT

    const onClose = () => {
        hhashRemove()
        setViewData(null)
    }

    // ----- MEMO

    const isAdmin = useMemo(() => user.roles.indexOf('__admin') >= 0, [user])

    // ----- RENDER

    const renderPhotoItem = (p: any) => {
        const ext = p.url.split('.').reverse()[0]
        if (['png', 'jpg', 'jpeg', 'git'].indexOf(ext) >= 0)
            return <a href={p.url} className="book-view-attach-item" key={`p${p.index}`} target={'_blank'}>
                <div className="_icon" style={{ backgroundImage: `url("${encodeURI(p.url)}")` }}/>
                <div className="_name">{p.detail}</div>
            </a>
        return <a href={p.url} className="book-view-attach-item" key={`p${p.index}`} target={'_blank'}>
            <Icon name={fileIconExt(ext)} className={'_icon'}/>
            <div className="_name">{p.detail}</div>
        </a>
    }

    const renderPhoto = () => {
        return <div className="book-view-attachs">{DOCUMENT_PHOTOs.map(dp => {
            const photos = !!viewPhotos && viewPhotos.filter((p: any) => dp.key === p.tag || (dp.key === 'other' && DOCUMENT_PHOTO_TAGs.indexOf(p.tag) < 0))
            return <div key={`p${dp.key}`} className="book-view-attach">
                <div className="_label">{dp.label}</div>
                <div className="_items">
                    {photos && photos.length > 0 ? photos.map(renderPhotoItem) : <div className="_none">ไม่มี</div>}
                </div>
            </div>
        })}</div>
    }

    const textVehicleAddtion = () => {
        let vehicleAddtion = ''
        if (!!viewData.vehicleAddtion) {
            vehicleAddtion += viewData.vehicleAddtion.detail
            if (viewData.vehicleAddtion.options && viewData.vehicleAddtion.options.length > 0) {
                if (vehicleAddtion !== '') vehicleAddtion += ' , '
                vehicleAddtion += viewData.vehicleAddtion.options.join(' , ')
            }
        }
        return vehicleAddtion
    }

    let title = '?'
    if (viewData) {
        if (!!viewData.customer) title = viewData.customer.name
        if (!!viewData.vehicleLicense) title += ` (${viewData.vehicleLicense})`
    }

    const footer = viewData && <>
        {isAdmin && <Button dark className="mr-4" onClick={() => setHistory(viewData.id)}>ดูประวัติ</Button>}
        {viewData.customer && <>
            <Button icon={'file'} info className="mr-4" href={'/d/document_confirm.pdf?id=' + viewData.id} targetBlank>พิมพ์หนังสือยืนยัน</Button>
            <Button icon={'paper-plane'} success className="mr-4" href={'/d/letter.pdf?customer_id=' + viewData.customer.id} targetBlank>พิมพ์ซองลูกค้า</Button>
        </>}
        {viewData.customerPay &&
            <Button icon={'paper-plane'} success className="mr-4" href={'/d/letter.pdf?customer_id=' + viewData.customerPay.id} targetBlank>พิมพ์ซองผู้ผ่อน</Button>}
        <div className="w-fill"/>
        {viewData.isRevoke && <Button icon={'file'} error className="ml-4" href={'/d/document_confirm_revoke.pdf?id=' + viewData.id} targetBlank>พิมพ์หนังสือยกเลิก</Button>}
        {props.onRestore && viewData.isRevoke && viewData.canRevokeBack && <Button warning className="ml-4" onClick={() => props.onRestore(viewData.id)}>เรียกคืนการยกเลิก</Button>}
        {props.onCopy && <Button secondary onClick={() => props.onCopy(viewData.id)} className="ml-4">คัดลอกเอกสาร</Button>}
    </>

    const modalProps: any = {
        open: props.id !== null,
        title,
        lg: true,
        onOpenEnd: loadData,
        onClose: props.onClose,
        onCloseEnd: onClose,
        footer
    }

    if (props.sideBar) {
        modalProps.noBackdrop = true
        modalProps.openEffect = 'right'
        modalProps.startPosition = { right: 0, top: 0 }
        modalProps.className = 'chat-sidebar'
    }

    let oldData = viewData?.oldData

    return <>
        <Modal icon={'file-alt'} {...modalProps}>
            {wait && <Wait block/>}
            {viewData && <>
                <div className="row">
                    <DetailItem label="วันที่" col={8}>{date(viewData.date, 'L')}</DetailItem>
                    <div className="col w-4/12 detail-item justify-end">{viewData.isNew
                        ? <span className={'green'}>(งานใหม่)</span>
                        : <span className={'blue'}>(ต่ออายุ)</span>}</div>
                </div>

                <div className="row">
                    <DetailItem label="ตัวแทน" col={4}>{viewData.agent?.name}</DetailItem>
                    <DetailItem label="พนักงาน" col={4}>{viewData.employee?.name}</DetailItem>
                </div>

                <div className="row">
                    <div className="col w-6/12"><CustomerView label="ลูกค้า" customer={viewData.customer} onEdit={() => setCustomer(viewData.customer?.id)}/></div>
                    <div className="col flex-1">
                        {viewData.customer?.id === viewData.customerPay?.id ? <DetailItem label="ผู้ผ่อน"><span className="red">เหมือนลูกค้า</span></DetailItem>
                            : <CustomerView label="ผู้ผ่อน" customer={viewData.customerPay} onEdit={() => setCustomer(viewData.customerPay?.id)}/>}
                    </div>
                </div>

                <hr/>

                <div className="row">
                    <DetailItem label="วันจดทะเบียน" col={4}>{date(viewData.vehicleDate, 'L')}</DetailItem>
                    <DetailItem label="ทะเบียนรถ" col={4}>{viewData.vehicleLicense} ({viewData.vehicleProvice?.name || '-'})</DetailItem>
                    <DetailItem label="ข้อมูลรถ" col={4}>{viewData.carBrand} / {viewData.carGeneration} / {viewData.carSubGeneration} / {viewData.carYear}</DetailItem>
                </div>
                <div className="row">
                    <DetailItem label="เลขตัวถัง" col={4}>{viewData.vehicleNumber}</DetailItem>
                    <DetailItem label="เลขเครื่องยนต์" col={4}>{viewData.vehicleNumberEngine}</DetailItem>
                    {viewData.vehicleTax > 0 && <DetailItem label="ภาษี" col={4}>{num(viewData.vehicleTax, 2)} บาท</DetailItem>}
                </div>

                <hr/>

                <div className="row">
                    <DetailItem label="บริษัท" col={4}>{viewData.company?.name || '?'}</DetailItem>
                    <DetailItem label="รหัสแจ้งงาน" col={4}>{viewData.workcode}</DetailItem>
                    <DetailItem label="ประเภทประกัน" col={4}>{viewData.metaInsuretype?.name}</DetailItem>
                </div>

                <div className="row">
                    <DetailItem label="ประเภทการใช้รถ" col={4}>{viewData.vehicleUsage}</DetailItem>
                    <DetailItem label="สถานที่ซ่อม" col={4}>{VEHICLE_FIXPLACEs.find(e => e.id == viewData.vehicleFixplace)?.text || ''}</DetailItem>
                    <DetailItem label="ทุนประกันภัย" col={4}>{num(viewData.moneyFund, 2)} บาท</DetailItem>
                </div>

                <div className="row">
                    <DetailItem label="ตกแต่งเพิ่มเติม" col={8}>{textVehicleAddtion()}</DetailItem>
                    {!!viewData.vehicleAddtion && <DetailItem label="ราคาตกแต่ง" col={4}>{num(viewData.vehicleAddtion.money, 2)} บาท</DetailItem>}
                </div>

                <hr/>

                <div className="row">
                    <DetailItem label="วันเริ่มคุ้มครอง" col={4}>{date(viewData.dateStart, 'L')}</DetailItem>
                    <DetailItem label="วันสิ้นสุดคุ้มครอง" col={4}>{date(viewData.dateEnd, 'L')}</DetailItem>
                    <DetailItem label="วันที่รับเอกสาร" col={4}>{date(viewData.dateDoc, 'L')}</DetailItem>
                </div>

                <div className="row">
                    <DetailItem label="ดีดัก" col={4}>{num(viewData.moneyDeduct, 2)} บาท <span
                        className="green">({!!viewData.drivers && viewData.drivers.length > 0 ? 'ระบุผู้ขับขี่' : 'ไม่ระบุผู้ขับขี่'})</span></DetailItem>
                    <DetailItem label="เลขที่กรมธรรม์" col={8}>{viewData.insuranceNumber}</DetailItem>
                </div>

                {!!viewData.drivers && viewData.drivers.length > 0 && <DetailItem label="ระบุผู้ขับขี่">
                    <ol>
                        {viewData.drivers.map((x: any, i: any) => {
                            return <li key={`driver_${i}`}>
                                {x.name} ,
                                <span className="grey-blue ml-1">{date(x.birth, 'L')}</span>
                                <span className="grey ml-1">({diffDate(todate(x.birth), todate(), 'y')} ปี)</span>
                            </li>
                        })}
                    </ol>
                </DetailItem>}

                <div className="row">
                    <DetailItem label="ความคุ้มครอง" col={12}>{viewData.coverage?.name || ''}</DetailItem>
                </div>

                {!!viewData.coverage && !!viewData.coverage.data &&
                    <CoverageView className="mt-4" money={viewData.moneyFund} moneyDeduct={viewData.moneyDeduct} data={viewData.coverage.data}/>}
                <hr/>

                {oldData && <>
                    <div className="row">
                        <DetailItem label="บริษัทประกันเดิม" col={8}>{meta.COMPANYs.find((e: any) => e.id == oldData.company)?.name || ''}</DetailItem>
                        <DetailItem label="ประเภทประกันเดิม" col={4}>{meta.INSURE_TYPEs.find((e: any) => e.id == oldData.metaInsuretype)?.name || ''}</DetailItem>
                    </div>

                    <div className="row">
                        <DetailItem label="วันเริ่มคุ้มครองเดิม" col={4}>{date(oldData.dateStart, 'L')}</DetailItem>
                        <DetailItem label="วันสิ้นสุดคุ้มครองเดิม" col={4}>{date(oldData.dateEnd, 'L')}</DetailItem>
                        <DetailItem label="สถานที่ซ่อมเดิม" col={4}>{VEHICLE_FIXPLACEs.find(e => e.id == oldData.vehicleFixplace)?.text || ''}</DetailItem>
                    </div>

                    <div className="row">
                        <DetailItem label="ทุนประกันภัยเดิม" col={4}>{num(oldData.moneyFund, 2)} บาท</DetailItem>
                        <DetailItem label="เบี้ยประกันภัยรวมเดิม" col={4}>{num(oldData.moneyTotalFund, 2)} บาท</DetailItem>
                        <DetailItem label="ดีดักเดิม" col={4}>{num(oldData.moneyDeduct, 2)} บาท</DetailItem>
                    </div>

                    <hr/>
                </>}

                <div className="row">
                    <DetailItem label="เบี้ยประกันภัยสุทธิ" col={3}>{num(viewData.moneyInsure, 2)} บาท</DetailItem>
                    <DetailItem label="อากร" col={3}>{num(viewData.moneyStamptax, 2)} บาท</DetailItem>
                    <DetailItem label="ภาษี" col={3}>{num(viewData.moneyTax, 2)} บาท</DetailItem>
                    <DetailItem label="เบี้ยประกันภัยรวม" col={3}>{num(viewData.moneyTotal, 2)} บาท</DetailItem>
                </div>

                <div className="row">
                    <DetailItem label="ส่วนลดลูกค้า" col={3}>{num(viewData.moneyDiscount, 2)} บาท</DetailItem>
                    <DetailItem label="ค่าธรรมเนียม" col={3}>{num(viewData.moneyFee, 2)} บาท</DetailItem>
                    <DetailItem label="ดอกเบี้ย" col={3}>{num(viewData.moneyInterest, 2)} บาท</DetailItem>
                    <DetailItem label="ยอดเงินรวม" col={3}>{num(viewData.moneyNet, 2)} บาท</DetailItem>
                </div>

                <div className="row">
                    <DetailItem label="ค่าปรับ" col={3}>{num(viewData.moneyFine, 2)} บาท</DetailItem>
                    <DetailItem label="หมายเหตุ" col={9}>{viewData.note}</DetailItem>
                </div>

                {!!viewData.systemNote && <DetailItem label="system note">{viewData.systemNote}</DetailItem>}

                <hr/>

                <BookDocumentPaylot document={viewData} datas={viewData.paylots}/>

                {renderPhoto()}

                <div className={'border bg-light mt-3 mb-2 p-2'}>
                    <div className="row">
                        <DetailItem label="เลขที่" col={4}>{viewData.id}</DetailItem>
                    </div>
                    <div className="row">
                        <DetailItem label="ดิวชำระบริษัท" col={4}>{date(viewData.companyDue)}</DetailItem>
                    </div>
                    <div className="row">
                        <DetailItem label="ยอดชำระบริษัท" col={4}>
                            {num(viewData.companyMoneySend, 2)}
                            {viewData.paycomp?.id ? <Button xs m0 secondary className="ml-2 px-0" onClick={() => setViewPaycomp(viewData.paycomp.id)}>ตัดจ่ายแล้ว</Button> : ''}
                        </DetailItem>
                        <DetailItem label="ค่าคอมตัวแทน" col={4}>
                            {num(viewData.agentMoney, 2) + (viewData.agentMoney > 0 ? ` ภาษี ${num(viewData.agentMoneyTax, 2)}` : '')}
                            {viewData.payagent?.id ? <Button xs m0 secondary className="ml-2 px-0" onClick={() => setViewPayagent(viewData.payagent.id)}>ตัดจ่ายแล้ว</Button> : ''}
                        </DetailItem>
                        <DetailItem label="ค่าคอมพนักงาน" col={4}>
                            {num(viewData.employeeMoney, 2) + (viewData.employeeMoney > 0 ? ` ค่าดูแล ${num(viewData.employeeMoneyAgent, 2)}` : '')}
                            {viewData.payemp?.id ? <Button xs m0 secondary className="ml-2 px-0" onClick={() => setViewPayemp(viewData.payemp.id)}>ตัดจ่ายแล้ว</Button> : ''}
                        </DetailItem>
                    </div>
                    <DetailItem label="ค่าคอมบริษัท">
                        {num(viewData.companyMoney, 2)}
                        <small> , แยกเป็น {viewData.companyYields && viewData.companyYields.map((y: any, i: any) => <span key={`y_${i}`} className="ml-4">
                            <span className="grey-blue">{y.name} </span>
                            <span className={'red'}>{num(y.money, 2)}</span>
                            {(y.taxHold > 0 || y.money > 0) && <span className="ml-2 grey">(
                                {y.taxHold > 0 && <span className="mx-1">หักภาษี {num(y.taxHold, 2)}</span>}
                                {y.taxVat > 0 && <span className="mx-1">แวท {num(y.taxVat, 2)}</span>}
                                )</span>}
                        </span>)}</small>
                    </DetailItem>
                    {viewData.isRevoke && <DetailItem label="">
                        <span className="red">ยกเลิกแล้ว</span>
                        {viewData.paycompRevoke?.id ? <Button xs m0 className="ml-2 px-0" secondary
                                                              onClick={() => setViewPaycomp(viewData.paycompRevoke.id)}>ตัดจ่ายแล้ว</Button> : ''}
                    </DetailItem>}
                </div>
            </>}
        </Modal>

        <HistoryModal name={history ? 'doc_' + history : null} onClose={() => setHistory(null)}/>

        <CustomerForm sideBar={props.sideBar} id={customer} onSave={customerSave} onClose={() => setCustomer(null)}/>
        <CompanyForm readOnly id={viewPaycomp} onClose={() => setViewPaycomp(null)}/>
        <AgentForm readOnly id={viewPayagent} onClose={() => setViewPayagent(null)}/>
        <EmployeeForm readOnly id={viewPayemp} onClose={() => setViewPayemp(null)}/>
    </>
}

export default BookView
