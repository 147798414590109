import { dConfirm, dError, get, tError, tSuccess } from 'unno-comutils'

import { calMoneyDFI, calPaylot, calPaylotDate } from './calculator'
import { DEFAULT_DOCUMENT_PAYLOT } from './var.default'

export const documentFormat = (document: any) => {
    document.sameCustomer = document.customer && document.customerPay && document.customer.id === document.customerPay.id
    document.__paylotCount = document.metaPaylot?.count || 0
    document.__payMeta = !!document.metaPaylot?.rate ? {
        id: document.metaPaylot.id,
        name: document.metaPaylot.name,
        discount: document.metaPaylot.rate.discount,
        fee: document.metaPaylot.rate.fee,
        interest: document.metaPaylot.rate.interest,
    } : { id: 0, name: 'ไม่ได้เลือก', discount: 0, fee: 0, interest: 0 }
}

export const documentChange = (document: any, update: any, method: any, options: any) => {
    const { formDate } = options

    if (method === 'paylot') {
        const c = document.paylots.length
        if (document.__paylotCount > c) {
            const add = document.__paylotCount - c
            for (let i = 0; i < add; i++)
                document.paylots.push(DEFAULT_DOCUMENT_PAYLOT())
        }

        else if (document.__paylotCount < c) {
            document.paylots = document.paylots.slice(0, update.__paylotCount)
        }

        calPaylotDate(document, formDate)
        calMoneyDFI(document)
        // calPaylotCountCond(document, this.customerPayCond)
    }

    else if (method === 'paylotMoney') {
        calPaylot(document)
    }
    return document
}

export const documentLicenseOld = ({ license, index, form, setForm, setLoading }: any) => {
    if (license !== '') {
        setLoading(true)
        get('book/get', { byLicense: license }).then(async d => {
            if (d.ok) {
                const { data } = d

                const update: any = {}
                if (!form.customer && data.customer) {
                    // @ts-ignore
                    update.customer = await customerChange({ ...data.customer, text: data.customer.name }, 'customer')
                    if (!form.customerPay && data.customerPay) {
                        // @ts-ignore
                        update.customerPay = await customerChange({ ...data.customerPay, text: data.customerPay.name }, 'customerPay')
                    }
                }

                if (!form.employee && data.employee) update.employee = { id: data.employee.id, text: data.employee.name }
                if (!form.agent && data.agent) update.agent = { id: data.agent.id, text: data.agent.name }

                const docUpdate = {
                    vehicleType: data.metaVehicletype?.id || 0,
                    vehicleLicense: data.vehicleLicense,
                    vehicleProvice: data.vehicleProvice,
                    vehicleNumber: data.vehicleNumber
                }
                if (index !== undefined) {
                    const documents = form.documents
                    documents[index] = { ...documents[index], ...docUpdate }
                    setForm((prev: any) => ({ ...prev, ...update, documents }))
                }
                else {
                    setForm((prev: any) => ({ ...prev, ...update, ...docUpdate }))
                }
            }
            else tError('ไม่พบข้อมูลเดิม')
        }).finally(() => setLoading(false))
    }
}

// @ts-ignore
export const customerChange = async (data: any) => data && { id: data.id, text: data.name, ...await customerLoad(data.id) }

export const customerLoad = (id: any) => new Promise((resolve, reject) => get('customer/get/' + id).then(d => {
    if (d.ok) { resolve(d.data) }
    else { reject() }
}))

export const customerSave = ({ customer, onChange, customerData, setCustomerData }: any) => {
    if (customer) {
        if (customerData?.type === 1) {
            onChange({ customer: customer }, 'customer')
        }
        else if (customerData?.type === 2) {
            onChange({ customerPay: customer }, 'customerPay')
        }
    }
    setCustomerData({ type: false, data: null })
}

export const customerDrop = ({ id, form, setForm, customerData, setCustomerData }: any) => {
    dConfirm('ยืนยันการลบข้อมูล').then(() => {
        get('customer/drop', { id: id }).then(d => {
            if (d.ok) {
                tSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')

                if (id > 0) {
                    if (customerData?.type === 1) {
                        // @ts-ignore
                        customerChange(null, 'customer', form, setForm)
                    }
                    else if (customerData?.type === 2) {
                        // @ts-ignore
                        customerChange(null, 'customerPay', form, setForm)
                    }
                }

                setCustomerData({ type: false, data: null })
            }
            else dError(d)
        })
    })
}
