import React from 'react'

import { FormContainer, Input, InputDate } from 'unno-comutils/form'
import { List, ListBody, ListContainer, ListFoot, ListHead } from 'unno-comutils/ui'
import { clsNames, date, num, useUser } from 'unno-comutils'

export function FormPaylot (props: any) {
    const { document, onChange } = props
    const user = useUser()

    // ----- EVENT

    const paylotChange = (update: any, index: any) => {
        let paylots = document.paylots
        paylots[index] = { ...paylots[index], ...update }
        onChange({ paylots })
    }

    // ----- RENDER

    if (!document) return <></>

    //
    return <FormContainer collapse={'book_paylot'} label={'งวดการชำระเงิน'} pb className={'docform-paylot'}>
        <ListContainer className="document-paylots">
            <div className="_header">
                <span>ชำระรูปแบบ</span>
                <strong>{document.__paylotCount > 1 ? `ผ่อน ${document.__paylotCount} งวด` : 'เงินสด'}</strong>
                <span>โดย</span>
                <strong>{!!document.__paylotFirst
                    ? Object.keys(document.__paylotFirstRate).map(fx => `งวด${fx === '1' ? 'แรก' : fx} ${document.__paylotFirstRate[fx]}%`).join(' , ')
                    : 'เท่ากันทุกงวด'}</strong>
            </div>
            <ListHead>
                <div className="w-rownum c">งวดที่</div>
                <div className="w-40 c">วันที่นัดจ่าย</div>
                <div className="w-40 c">ยอดเงิน</div>
                <div className="w-fill">หมายเหตุ</div>
            </ListHead>
            <ListBody>
                {document.paylots?.map((p: any, index: any) => <List key={'paylot_' + index}>
                        <div className="w-rownum c">{index + 1}</div>
                        {user.allow('book.create.paylot')
                            ? <>
                                <InputDate className="w-40" noClear icon value={p.date} onChange={v => paylotChange({ date: v }, index)}/>
                                <Input unit="บาท" type="number" right className="w-40" key={'money_' + p.money}
                                       defValue={p.money} onBlur={v => paylotChange({ money: v }, index)}/>
                                <Input className="w-fill" key={'note_' + p.note} defValue={p.note} onBlur={v => paylotChange({ note: v }, index)}/>
                            </>
                            : <>
                                <div className="w-40 c">{date(p.date)}</div>
                                <div className="w-40 r">
                                    {num(p.money, 0)}
                                    <span className="ml-2">บาท</span>
                                </div>
                                <Input className="w-fill" key={'note_' + p.note} defValue={p.note} onBlur={v => paylotChange({ note: v }, index)}/>
                            </>}
                    </List>
                )}
            </ListBody>
            <ListFoot>
                <div className="w-rownum c"/>
                <div className="w-40 r">รวม</div>
                <div className="w-40 r">
                    {document.paylots ? num(document.paylots.reduce((s: number, o: any) => s + o.money, 0), 0) : 'n/a'}
                    <span className="ml-2">บาท</span>
                </div>
            </ListFoot>
        </ListContainer>
    </FormContainer>
}

export function ViewPaylot (props: any) {
    const { document, datas } = props

    const renderPaymentStatus = (p: any) => {
        if (p.isPay)
            return <div className={'_status green'}>จ่ายแล้ว</div>
        return <div className={'_status red'}>ยังไม่จ่าย</div>
    }

    if (!document) return <></>

    return <FormContainer collapse={'book_paylot'} pb label={'งวดการชำระเงิน'}>
        <div className={clsNames('document-paylot', props.className)}>
            <div className={'_header'}>
                <div className={'_count -lot_' + document.paylotCount}>{document.paylotCount > 1 ? `ผ่อน ${document.paylotCount} งวด` : 'เงินสด'}</div>
                <div className="_summary">
                    จ่ายแล้ว <span className={'green'}>{num(document.paylotMoneyPay, 2)}</span> บาท ,
                    จาก <span className={'blue'}>{num(document.paylotMoney, 2)}</span> บาท ,
                    ค้างจ่าย <span className={'red'}>{num(document.paylotMoney - document.paylotMoneyPay, 2)}</span> บาท
                </div>
            </div>

            <div className="_lots">
                <div className={'_head'}>
                    <div className={'_lot'}>งวดที่</div>
                    <div className={'_due'}>นัดชำระ</div>
                    <div className={'_pay'}>จ่าย</div>
                    <div className={'_note'}>หมายเหตุ</div>
                </div>
                {datas && datas.map((p: any) => {
                    const payDetails = []

                    let moneyNet = p.moneyNet
                    if (p.moneyPay) payDetails.push(`ค่าเบี้ยประกัน ${num(p.moneyPay, 2)} บาท`)
                    if (p.moneyService) payDetails.push(`ค่าบริการ ${num(p.moneyService, 2)} บาท`)
                    if (p.moneyFine) payDetails.push(`ค่าปรับ ${num(p.moneyFine, 2)} บาท` + (p.fineNote ? ` (งวด ${p.fineNote})` : ''))
                    if (p.moneyOver) payDetails.push(`เงินเกิน ${num(p.moneyOver, 2)} บาท`)
                    if (p.moneyDiscount) payDetails.push(`ส่วนลดดอกเบี้ย -${num(p.moneyDiscount, 2)} บาท`)

                    if (p.isPayangent && document.payagent && document.agentMoney > 0) {
                        payDetails.push(`หักค่าคอม ${num(p.agentMoney, 2)} บาท`
                            + (p.agentMoneyTax ? ` (ภาษี ณ ที่จ่าย ${num(p.agentMoneyTax, 2)} บาท)` : ''))
                    }

                    const canEdit = !!props.onEdit

                    return <div key={`lot_${p.id}`} className={'_item' + (canEdit ? ' -can-edit' : '')}
                                onClick={() => canEdit && props.onEdit(p.id)}>
                        <div className={'_lot'}>
                            <div
                                className={'_number'}>{p.lot > 0 ? `${p.lot}${p.lotSplit > 0 ? `/${p.lotSplit}` : ''}` : '-'}</div>
                            {renderPaymentStatus(p)}
                        </div>
                        <div className={'_due'}>
                            {p.lot > 0 && <div className={'_box'}>
                                <div className="_date">{date(p.dateDue, 'S')}</div>
                                <div className="_money">
                                    <div className={'_label'}>ยอดเงิน</div>
                                    <div className={'_value'}>{num(p.money - p.splitMoney, 2)}</div>
                                </div>
                            </div>}
                            {p.splitMoney > 0 && <div className="_value-split">จาก {num(p.money, 2)} ยกไปงวดถัดไป {num(p.splitMoney, 2)} บาท</div>}
                        </div>
                        <div className={'_pay'}>
                            {p.isPay && <div className={'_box'}>
                                <div className="_date">{date(p.datePay, 'S')}</div>
                                <div className="_money">
                                    <div className={'_label'}>ยอดเงิน</div>
                                    <div className={'_value'}>{num(moneyNet, 2)}</div>
                                </div>
                                <div className={'_detail'}>
                                    <ol>{payDetails.map((t, i) => <li key={`paydetail_${i}`}>{t}</li>)}</ol>
                                </div>
                            </div>}
                        </div>
                        <div className={'_note'}>{p.note}</div>
                    </div>

                })}
            </div>
        </div>
    </FormContainer>
}
