import React, { useEffect, useMemo } from 'react'

import { changeDate, date, get } from 'unno-comutils'
import { Checkbox, FormContainer, Input, InputDate, Radio, Select } from 'unno-comutils/form'

import { DOCUMENT_INSURANCE_ISSUE, VEHICLE_FIXPLACEs } from '../../../service/var'
import { VEHICLE_ADDITION_OPTIONs } from '../../../service/var.meta'
import { CoverageView } from '../../../components/view'
import { Icon } from 'unno-comutils/ui'
import { useMeta } from '../../../app'

export function FormInsurance (props: any) {
    const meta = useMeta()

    const { document, onChange } = props

    // ----- EVENT

    const onCompanyChange = (update: any) => {
        if (update.company?.workcodes && Array.isArray(update.company.workcodes))
            update.workcode = update.company.workcodes[0].code
        onChange(update)
    }

    const onChangeVehicleAddtion = (update: any) => onChange({ vehicleAddtion: { ...document.vehicleAddtion, ...update } })

    const onDocumentChangeDriver = (update: any, index: any) => {
        const drivers = document.drivers
        drivers[index] = { ...drivers[index], ...update }
        onChange({ drivers })
    }

    const onDocumentChangeCoverage = (data: any) => {
        if (data) {
            get('setting/meta/coverage/get/' + data?.id).then(d => {
                if (d.ok) onChange({ coverage: { id: d.data.id, name: d.data.name, data: d.data ? d.data.data : '' } })
            })
        }
        else onChange({ coverage: { id: '', name: '', data: '' } })
    }

    // ----- MEMO

    useEffect(() => {
        if (document && !document.dateDoc && document.photos?.length > 0) {
            if (document.photos.some((d: any) => d.tag === 'insurance'))
                onChange({ ...document, dateDoc: new Date() })
        }
    }, [document])

    const optWORKCODEs = useMemo(() => {
        if (!document || !document.company) return []

        const company = document.company && meta.COMPANYs.find((c: any) => c.id === document.company.id)
        if (company) {
            return company.workcodes.map((w: any) => ({
                id: w.code,
                text: `${w.code} : ${w.name}`,
            }))
        }
        return []
    }, [document?.company])

    // ----- RENDER

    if (!document) return <></>

    const { vehicleAddtion } = document

    return <FormContainer collapse={'book_insurance'} label={<div className="w-fill">
        <div className="l">ข้อมูลประกันภัย</div>
        {document.isNew
            ? <div className="mt-2 green l"><Icon name="star" solid/> งานใหม่</div>
            : <div className="mt-2 blue l"><Icon name="sync" solid/> งานต่ออายุ</div>}
    </div>} className={'docform-insurance'}
                          headerCollapse={<div className="un-form-header-text">
                              <span className="mr-2 red">{document.metaInsuretype?.name}</span>
                              <span className="mr-2">{document.company?.name}</span>
                          </div>}>
        <div className="row">
            <Select label="บริษัท" classBox="col w-4/12" options={meta.COMPANYs} noClear value={document.company} onChange={(_, company) => onCompanyChange({ company })}/>
            <Select label="รหัสแจ้งงาน" classBox="col w-3/12" options={optWORKCODEs} value={document.workcode} onChange={v => onChange({ workcode: v })}/>
            <Select label="ประเภทประกัน" classBox="col w-3/12" options={meta.INSURE_TYPEs} value={document.metaInsuretype} onChange={(_, v) => onChange({ metaInsuretype: v })}/>
            <Input label="ทุนประกันภัย" type="number" right classBox="col w-2/12" unit="บาท" value={document.moneyFund} onChange={v => onChange({ moneyFund: v })}/>
        </div>

        <div className="row">
            <Radio value={document.vehicleFixplace} classBox="col w-3/12" options={VEHICLE_FIXPLACEs} onChange={vehicleFixplace => onChange({ vehicleFixplace })}/>
            <Input label={`รายการตกแต่งเพิ่มเติม`} classBox="col flex-1" value={vehicleAddtion?.detail || ''} onChange={v => onChangeVehicleAddtion({ detail: v })}/>
            <Input label="ราคารายการตกแต่ง" type="number" right classBox="col w-2/12" unit="บาท" value={vehicleAddtion?.money || ''}
                   onChange={v => onChangeVehicleAddtion({ money: v })}/>
        </div>

        <div className="flex no-boxmargin _vehicle_usages">
            <Checkbox items={VEHICLE_ADDITION_OPTIONs} checked={vehicleAddtion?.options || []} onChange={v => onChangeVehicleAddtion({ options: v })}/>
            <Input label="อุปกรณ์เพิ่มเติมพิเศษอื่นๆ" className="flex-1 ml-4 _addition-mt" value={vehicleAddtion?.other} onChange={v => onChangeVehicleAddtion({ other: v })}/>
        </div>

        <div className="document-split"/>

        <div className="row">
            <InputDate icon label={'วันเริ่มคุ้มครอง'} classBox="col w-1/6" noClear value={document.dateStart}
                       onChange={v => onChange({ dateStart: v, dateEnd: changeDate(v, 1, 'y') })}/>
            <InputDate icon label={'วันสิ้นสุดคุ้มครอง'} classBox="col w-1/6" noClear value={document.dateEnd} onChange={v => onChange({ dateEnd: v })}/>
            <Input icon={'calendar'} label={'วันที่รับเอกสาร'} classBox="col w-1/6" readOnly value={document.dateDoc ? date(document.dateDoc) : ''}/>
            <Input label="เลขที่กรมธรรม์" classBox="col flex-1" value={document.insuranceNumber} onChange={v => onChange({ insuranceNumber: v })}/>
        </div>

        <div className="row">
            <Input label="ดีดัก" type="number" right classBox="col w-1/6" unit="บาท" value={document.moneyDeduct} onChange={v => onChange({ moneyDeduct: v })}/>
            <Checkbox text={'ระบุผู้ขับขี่'} classBox="col w-2/6" checked={document.isDriver}
                      onChange={v => onChange({ isDriver: !!v, drivers: v ? [{ name: '', birth: '' }, { name: '', birth: '' }] : [], })}/>
            <Radio label={'การออกกรมธรรม์'} classBox="col w-3/6" options={DOCUMENT_INSURANCE_ISSUE} value={document.insuranceIssue}
                   onChange={v => onChange({ insuranceIssue: v })}/>

            {(!!document.isDriver && document.drivers)
                && document.drivers.map((driver: any, xd: any) => <div key={'drivers_' + xd} className="col flex-1 flex">
                    <Input label={'คนที่ ' + (xd + 1)} className="w-fill" value={driver.name} onChange={v => onDocumentChangeDriver({ name: v }, xd)}/>
                    <InputDate label="วันเกิด" className="ml-2" fixWidth value={driver.birth} onChange={v => onDocumentChangeDriver({ birth: v }, xd)}/>
                </div>)}
        </div>
        <Select label="ความคุ้มครอง" url={'ac/coverage'} value={document.coverage} onChange={(_, v) => onDocumentChangeCoverage(v)}/>
        {!!document.coverage && !!document.coverage.data &&
            <CoverageView className="mb-6" money={document.moneyFund} moneyDeduct={document.moneyDeduct} data={document.coverage.data}/>}
    </FormContainer>
}
