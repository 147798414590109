import { changeDate } from 'unno-comutils'
import { DEFAUTL_VEHICLE_DATA } from '../pages/book/components/vehicle'

export const DEFAULT_DUE = { ignore: 1, start: '', end: '', due: '', next: '' }
export const DEFAULT_BANK = { ignore: 1, bank: '', branch: '', number: '', account: '', payway: [] }
export const DEFAULT_ADDRESS = { name: '', moo: '', soi: '', subdistrict: '', district: '', province: '', zipcode: '' }

export const DEFAULT_CUSTOMER = () => ({
    create_modal: 1,
    id: 0,
    photos: [], name: '', type: '', moneyDiscount: '', idcard: '', birth: '', gender: '', job: '', note: '',
    contactTel: '', contactFacebook: '', contactLine: '', contactEmail: '',
    addressHome: { ...DEFAULT_ADDRESS },
    addressSendmail: { ...DEFAULT_ADDRESS },
    vehicles: [],
})

export const DEFAULT_VEHICLE = { create_modal: 1, id: 0, photo: '', number: '', brand: '', generation: '', year: '', cc: '' }

export const DEFAULT_DOCUMENT_PAYLOT = () => ({ date: new Date(), money: '', note: '' })

export const DEFAULT_DOCUMENT = () => ({
    id: 0, isNew: 1,
    date: new Date(), note: '',
    metaInsuretype: '', insuranceNumber: '',
    metaVehicletype: '', vehicleLicense: '', vehicleNumber: '',
    dateStart: new Date(), dateEnd: changeDate(new Date(), 1, 'y'), dateDoc: '',
    company: '', workcode: '',
    moneyFund: '', moneyInsure: '', moneyStamptax: '', moneyTax: '', moneyTotal: '', moneyDiscount: '', moneyFee: '', moneyInterest: '', moneyNet: '', moneyFine: '',
    moneyDeduct: '', isDriver: 0,
    vehicleUsage: '', vehicleFixplace: 0, vehicleAddtion: { options: [], detail: '', money: '' },
    vehicleDate: '', vehicleProvice: '', vehicleCode: '', vehicleNumberEngine: '',
    vehicleData: DEFAUTL_VEHICLE_DATA(),
    paylots: [DEFAULT_DOCUMENT_PAYLOT()],
    coverage: null,
    photos: [],
    __paylotCount: 0, __payMeta: { discount: 0, fee: 0, interest: 0 }, __paylotFirst: 0, __paylotFirstRate: 0,
})

export const DEFAULT_DOCUMENT_CREATE = () => ({
    documentExpress: '', date: new Date(), customer: null, customerPay: null, agent: null, employee: null, sameCustomer: 0, documents: [DEFAULT_DOCUMENT()],
})

export const DEFAULT_SENDDATA = { id: null, mode: '' }

export const DEFAULT_ACCOUNT_COMPANY = { id: 0, date: new Date(), company: '', workcode: '', bank: '', type: '', note: '' }
export const DEFAULT_ACCOUNT_EMPLOYEE = { id: 0, date: new Date(), employee: '', bank: '', type: '', note: '' }
export const DEFAULT_ACCOUNT_AGENT = { id: 0, date: new Date(), agent: '', bank: '', type: '', note: '' }

export const DEFAULT_COMPANY_AGENTBROKER = { ignore: 1, code: '', name: '', number: '', note: '' }
export const DEFAULT_COMPANY_PROMOTION = { ignore: 1, dateStart: '', dateEnd: '', type: '', yields: {}, dues: [] }

export const DEFAULT_COMPANY = {
    id: 0, taxNumber: '', name: '', photos: '', note: '',
    contactTel: '', contactMobile: '', contactFax: '', contactEmail: '', contactLine: '',
    saleName: '', saleTel: '', financeName: '', financeTel: '',
    addressHome: { ...DEFAULT_ADDRESS }, addressSendmail: { ...DEFAULT_ADDRESS },
    yieldDues: [], yieldDatas: {},
    agentBrokers: [{ ...DEFAULT_COMPANY_AGENTBROKER }], banks: [{ ...DEFAULT_BANK }],
    insuranceCheckOld: { condition: '', request: '', datas: [] }
}

export const DEFAULT_AGENT = {
    id: 0,
    photos: '', number: '', name: '', nameFull: '', metaAgent: '', employeeParent: '',
    certificateNumber: '', certificateDate: '', certificateDateExpire: '', note: '', paycondType: '', moneyCredit: '', taxValue: '',
    contactData: { address: '', addressSend: '', contactEmail: '', contactFacebook: '', contactLine: '', contactTel: '' },
    banks: [{ ...DEFAULT_BANK }],
    employeeYields: [],
    agentYields: []
}

export const DEFAULT_EMPLOYEE = {
    id: 0, number: '', name: '', name_s: '', dateStart: '', dateEnd: '', position: '', certificateDateExpire: '', certificateNumber: '', note: '',
    photos: '',

    contactData: { address: '', addressSend: '', contactEmail: '', contactFacebook: '', contactLine: '', contactTel: '' },

    agents: [],
    banks: [{ ...DEFAULT_BANK }],
}
