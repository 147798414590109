import React from 'react'

import { num } from 'unno-comutils'
import DetailItem from './test/DetailItem'
import Button from 'unno-comutils/Button'
import { addressText } from '../utils'

export function CoverageView (props: any) {
    const { data, money, moneyDeduct } = props
    const n = (money: any) => !!money ? num(money, 0) : '-'

    return <div className={'coverage border rounded ' + (!!props.className && props.className)}>
        <div className={'coverage-detail -w-30'}>
            <h5>ความรับผิดชอบต่อบุคคลภายนอก</h5>
            <ol>
                <li>
                    <span className="_text">ความเสียหายต่อชีวิต ร่างกาย หรือ อานามัย เฉพาะส่วนเกินวงเงินสูงสุดตาม พ.ร.บ.</span>
                    <div className="_data">
                        <div className="_value">{n(data?.money)}</div>
                        <div className="_unit">บาท/คน</div>
                    </div>
                    <div className="_data">
                        <div className="_value">{n(data?.moneyTime)}</div>
                        <div className="_unit">บาท/ครั้ง</div>
                    </div>
                </li>
                <li>
                    <span className="_text">ความเสียหายต่อทรัพย์สิน</span>
                    <div className="_data">
                        <div className="_value">{n(data?.moneyAsset)}</div>
                        <div className="_unit">บาท/ครั้ง</div>
                    </div>
                    <ol>
                        <li>
                            <span className="_text">ความเสียหายส่วนแรก</span>
                            <div className="_data">
                                <div className="_value">{n(moneyDeduct)}</div>
                                <div className="_unit">บาท/ครั้ง</div>
                            </div>
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
        <div className={'coverage-detail -w-30'}>
            <h5>รถยนต์เสียหาย สูญหาย ไฟไหม้</h5>
            <ol>
                <li>
                    <span className="_text">ความเสียหายต่อรถยนต์</span>
                    <div className="_data">
                        <div className="_value">{n(money)}</div>
                        <div className="_unit">บาท/ครั้ง</div>
                    </div>
                    <ol>
                        <li>
                            <span className="_text">ความเสียหายส่วนแรก</span>
                            <div className="_data">
                                <div className="_value">{n(moneyDeduct)}</div>
                                <div className="_unit">บาท/ครั้ง</div>
                            </div>
                        </li>
                    </ol>
                </li>
                <li>
                    <span className="_text">รถยนต์สูญหาย/ไฟไหม้</span>
                    <div className="_data">
                        <div className="_value">{n(money)}</div>
                        <div className="_unit">บาท/ครั้ง</div>
                    </div>
                </li>
            </ol>
        </div>

        <div className={'coverage-detail flex-1'}>
            <h5>ความคุ้มครองตามเอกสารแนบท้าย</h5>
            <ol>
                <li>
                    <span className="_text">อุบัติเหตุส่วนบุคคล</span>
                    <ol>
                        <li>
                            <span className="_text">เสียชีวิต สูญเสียอวัยวะ ทุพพลภาพถาวร</span>
                            <div className="_data">
                                <div className={'_head'}>ก ) ผู้ขับขี่</div>
                                <div className={'_count'}>{n(data?.driverCount)}</div>
                                <div className={'_count-unit'}>คน</div>
                                <div className="_value">{n(data?.driverMoney)}</div>
                                <div className="_unit-long">บาท</div>
                            </div>
                            <div className="_data">
                                <div className={'_head'}>ข ) ผู้โดยสาร</div>
                                <div className={'_count'}>{n(data?.passengerCount)}</div>
                                <div className={'_count-unit'}>คน</div>
                                <div className="_value">{n(data?.passengerMoney)}</div>
                                <div className="_unit-long">บาท/คน</div>
                            </div>
                        </li>
                        <li>
                            <span className="_text">ทุพพลภาพชั่วคราว</span>
                            <div className="_data">
                                <div className={'_head'}>ก ) ผู้ขับขี่</div>
                                <div className={'_count'}>{n(data?.driverTempCount)}</div>
                                <div className={'_count-unit'}>คน</div>
                                <div className="_value">{n(data?.driverTempMoney)}</div>
                                <div className="_unit-long">บาท/สัปดาห์</div>
                            </div>
                            <div className="_data">
                                <div className={'_head'}>ข ) ผู้โดยสาร</div>
                                <div className={'_count'}>{n(data?.passengerTempCount)}</div>
                                <div className={'_count-unit'}>คน</div>
                                <div className="_value">{n(data?.passengerTempMoney)}</div>
                                <div className="_unit-long">บาท/คน/สัปดาห์</div>
                            </div>
                        </li>
                    </ol>
                </li>
                <li>
                    <span className="_text">ค่ารักษาพยาบาล</span>
                    <div className="_data">
                        <div className="_value">{n(data?.medical)}</div>
                        <div className="_unit-long">บาท/คน</div>
                    </div>
                </li>
                <li>
                    <span className="_text">การประกันตัวผู้ขับขี่</span>
                    <div className="_data">
                        <div className="_value">{n(data?.bail)}</div>
                        <div className="_unit-long">บาท/ครั้ง</div>
                    </div>
                </li>
            </ol>
        </div>
    </div>
}

export function CustomerView (props: any) {
    if (!props.customer || !props.customer.contactData) return null

    const customer = props.customer
    const contact = customer.contactData

    return <div className="customer-view-box">
        <Button className="btn-edit" info sm onClick={() => props.onEdit(customer.id)} m0={true}>แก้ไข</Button>
        <DetailItem label={'ที่อยู่'}>{addressText(contact.address)}</DetailItem>
        <div className="row">
            <DetailItem col={12} label={'เบอร์โทร'}>{contact.tel}</DetailItem>
        </div>
        <DetailItem label={'ประเภท'}> {customer.type?.name}</DetailItem>
    </div>
}

export function ReportEmpty () {
    return <div className="report-empty">ไม่พบข้อมูลรายงาน</div>
}

export function FileListView (props: { files: any }) {
    if (!props.files || !Array.isArray(props.files)) return null
    return <ul className="order-files-view m-0">
        {props.files.map((d: any, i: number) => <li key={'item_' + i}><a href={d.url} className="tclick" target="_blank">{d.name}</a></li>)}
    </ul>
}