import { forwardRef, ReactNode, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef'

import { clsNames, date, dbdate, debounce, num, todate } from 'unno-comutils'
import { Icon, IconActive, Wait } from 'unno-comutils/ui'
import Link from 'unno-comutils/Link'
import { IconName } from 'unno-comutils/var'
import { Select } from 'unno-comutils/form'
import { InputDatePopup } from 'unno-comutils/form/InputDate'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'

interface DatagridProps {
    name: string;

    wait?: boolean;

    heightWithRow?: boolean;
    columnReorder?: boolean;
    columnResize?: boolean;

    headerHeight?: number;
    headerAutoHeight?: boolean;

    wrapText?: boolean;
    singleClickEdit?: boolean;

    // TODO: implement
    headers?: any[];
    datas: any[];
    datasTotal?: any[];
    columns: (IDatagridColumns | IDatagridNestedColumns)[];

    // TODO: implement
    totalTop?: boolean;

    settings?: any;

    className?: string;
    checkbox?: [field: string, checked: any[], onCheck: (chk: any[]) => void, all?: boolean];

    onReady?: (el: any) => void;
    onRowDblClick?: (row: any) => void;

    onRowSort?: (rows: any[]) => void; // ถ้ามี callback ตัวนี้ จะทำให้ ตารางมีคุณสมบัติ rowsort

    onEdited?: (rowIndex: number, colName: string, row: any, rows: any[]) => void;
    onPaste?: (updateRows: any[], rows: any[]) => void;

    sort?: string; // current sort
    sortFrontend?: boolean;
    onSorted?: (sortString: string, sorts: ({ name: string, desc: boolean })[]) => void;

    emptyComponent?: ReactNode;

    enpRangeSelection?: boolean;
    enpGroup?: string[] | string;
    enpGroupExpanded?: number;
}

/** TODO
 * การ merge data
 * การ Edit
 *   - checkbox
 * columnButton
 *
 * ของน่าสนใจ
 * - tooltip row  -- https://www.ag-grid.com/react-data-grid/component-tooltip/
 *
 *
 * Enterprise Edition
 *  - ลากเซลได้ (enableRangeSelection)
 *  - Row Grouping (enpGroup + enpGroupExpanded)
 */

export interface IDatagridNestedColumns {
    label: string,
    children: (IDatagridColumns | IDatagridNestedColumns)[]
}

export interface IDatagridColumns {
    name: string,
    label?: string,
    text?: string,

    width: number,
    fill?: boolean,
    wrap?: boolean,

    pinned?: boolean | 'left' | 'right',

    format?: 'number' | 'number.2' | 'date' | 'date.st' | 'date.stt' | 'html' | string,
    style?: 'c' | 'r'
        | '-red' | '-pink' | '-purple' | '-blue' | '-sky' | '-green' | '-yellow' | '-orange' | '-brown' | '-grey' | '-grey-blue'
        | '-red-text' | '-purple-text' | '-blue-text' | '-green-text' | '-orange-text' | '-grey-text' | '-grey-blue-text'
        | string,

    sortable?: boolean,
    editable?: true | 'checkbox' | 'date' | string[] | { type: 'select.ac', action: string } | ReactNode,
    // https://www.ag-grid.com/react-data-grid/component-cell-editor/

    renderer?: (value: any, data: any) => any,
}

export const EWidth = {
    ROWNUM: 40,
    ICON: 33,
    ID: 70,
    ID_L: 90,
    DATE: 80,
    DATE_ST: 120,
    DATE_STT: 135,
}

// $grid-bgcolors: ["red", "pink", "purple", "blue", "sky", "green", "yellow", "orange", "brown", "grey", "grey-blue"];
// $grid-textcolors: ["red", "purple", "blue", "green", "orange", "grey"];

export function columnIconClick (props: {
    name: string,

    onClick?: (value: any, data: any) => void,
    href?: string | ((value: any, data: any) => string),
    targetBlank?: boolean,

    label?: string,
    icon?: IconName | string,
    style?: string,
    className?: string,
    pinned?: boolean | 'left' | 'right'
}) {
    return {
        name: props.name,
        label: props.label || '',
        style: props.style || 'c',
        width: EWidth.ICON,
        pinned: props.pinned,
        renderer: (value: any, data: any) => {
            if (props.onClick !== undefined)
                // @ts-ignore
                return <Icon name={props.icon || 'cog'} className={clsNames('tclick', props.className)} onClick={() => props.onClick(value, data)}/>

            if (props.href !== undefined)
                return <Icon name={props.icon || 'cog'} className={clsNames('tclick', props.className)}
                             href={typeof props.href === 'function' ? props.href(value, data) : props.href} targetBlank={props.targetBlank}/>

            return <Icon name={props.icon || 'cog'} className={clsNames('tclick', props.className)} targetBlank={props.targetBlank}/>
        }
    }
}

export function columnIconActive (props: {
    name: string;
    action: string | ((data: any) => string);
    onActived?: (active: boolean, data: any) => void;

    label?: string;
    iconOn?: IconName | string;
    iconOff?: IconName | string;
    style?: string;
    className?: string;
    pinned?: boolean | 'left' | 'right';
}) {
    return {
        name: props.name,
        label: props.label || '',
        style: props.style || 'c',
        width: EWidth.ICON,
        pinned: props.pinned,
        renderer: (value: any, data: any) => <IconActive noBotton active={!!value} iconOn={props.iconOn} iconOff={props.iconOff}
                                                         action={typeof props.action === 'function' ? props.action(data) : props.action}
                                                         className={clsNames('tclick', props.className)}
                                                         onActived={props.onActived ? (active: boolean) => props.onActived?.(active, data) : undefined}/>
    }
}

export function columnTextClick (props: {
    name: string,
    label: string,
    width: number,

    onClick?: (value: any, data: any) => void,
    href?: string | ((value: any, data: any) => string),
    targetBlank?: boolean,

    text?: (value: any, data: any) => string,

    fill?: boolean,

    style?: string
}) {
    return {
        name: props.name, text: props.label || '', style: props.style, width: props.width, fill: props.fill,
        renderer: (value: any, data: any) => {
            const display = props.text ? (typeof props.text === 'function' ? props.text(value, data) : props.text) : value

            if (props.onClick !== undefined)
                // @ts-ignore
                return <span className="tclick" onClick={() => props.onClick(value, data)}>{display}</span>

            if (props.href !== undefined)
                return <Link className="tclick" href={typeof props.href === 'function' ? props.href(value, data) : props.href} targetBlank={props.targetBlank}>{display}</Link>

            return <span className="tclick">{display}</span>
        }
    }
}

// ----- CORE

export default function Datagrid (props: DatagridProps) {
    const gridRef = useRef<any>()
    const contRef = useRef<any>()
    const editRef = useRef(false)

    const clearStateRef = useRef<any>()

    const pasteValues = useRef<any>([])
    const pasteStartRef = useRef(false)

    // ----- ACTION

    function showClearButton (show: boolean) {
        if (clearStateRef.current)
            clearStateRef.current.style.display = show ? 'block' : 'none'
    }

    function updateCheckbox (el: any) {
        if (props.checkbox && Array.isArray(props.checkbox[1]) && props.checkbox[1].length > 0) {
            if (props.checkbox[0]) {
                const field = props.checkbox[0]
                const selected = props.checkbox[1]

                const nodesToSelect: any = []
                el.api.forEachNode((node: any) => {
                    if (node.data && node.data[field] && selected.includes(node.data[field])) {
                        nodesToSelect.push(node)
                    }
                })
                el.api.setNodesSelected({ nodes: nodesToSelect, newValue: true })
            }
        }
    }

    // ----- EVENT

    function onGridReady (el: any) {
        const savedRaw = localStorage.getItem('TABLE_' + props.name)
        if (savedRaw) {
            const saved = JSON.parse(savedRaw)
            if (saved) {
                // TODO: ต้องใช้ timeout เพราะเหมือน grid ทำงานไม่ทัน  แถมระยะเวลา .75s มันเหมือนยังไม่พอ
                setTimeout(() => {
                    gridRef.current.api.applyColumnState({ state: saved, applyOrder: true })
                }, 750)
                showClearButton(true)
            }
        }

        if (props.onReady) props.onReady(el)
    }

    function onRowDataUpdated (el: any) {
        updateCheckbox(el)
    }

    // ----- ----- column

    const onColumnChange = debounce((el: any) => {
        if (el.source === 'uiColumnResized' || el.source === 'uiColumnMoved') {
            localStorage.setItem('TABLE_' + props.name, JSON.stringify(el.api.getColumnState()))
            showClearButton(true)
        }
    }, 200)

    function onColumnReset () {
        gridRef.current.api.resetColumnState()
        localStorage.removeItem('TABLE_' + props.name)
        showClearButton(false)
    }

    function onCheckChanged (el: any) {
        if (props.checkbox) {
            const [checkField, _, checkOnChange] = props.checkbox
            const rows = el.api.getSelectedNodes()
            checkOnChange(rows.length > 0 ? rows.map((p: any) => props.checkbox && (p.data[checkField] || p.data)) : [])
        }
    }

    function onCellDoubleClicked (el: any) {
        if (props.datas.length > el.rowIndex && props.onRowDblClick) {
            props.onRowDblClick(props.datas[el.rowIndex])
        }
    }

    function onRowDragEnd (el: any) {
        if (props.onRowSort)
            props.onRowSort(rowDatas(el))
    }

    function onCellEditingStarted (el: any) {
        editRef.current = true
    }

    function onCellEditingStopped (el: any) {
        editRef.current = false
        if (props.onEdited)
            props.onEdited(el.rowIndex, el.column.colId, el.data, rowDatas(el))
    }

    function onCellValueChanged (el: any) {
        if (pasteStartRef.current && el.source === 'paste') {
            const object = pasteValues.current.find((o: any) => o._row === el.rowIndex)
            if (object) {
                object[el.column.colId] = el.newValue
            }
            else {
                pasteValues.current.push({ _row: el.rowIndex, _data: el.data, [el.column.colId]: el.newValue })
            }
            //console.log('onCellValueChanged', el)
        }
    }

    function onPasteStart (el: any) {
        pasteStartRef.current = true
        pasteValues.current = []
        //console.log('onPasteEnd', el)
    }

    function onPasteEnd (el: any) {
        if (props.onPaste)
            props.onPaste(pasteValues.current, rowDatas(el))
        pasteStartRef.current = false
        pasteValues.current = []
    }

    function onSortChanged (el: any) {
        const currentSort = el.api.getColumnState().filter((s: any) => s.sort != null).map((s: any) => ({ name: s.colId, desc: s.sort === 'desc' }))
        if (props.onSorted) props.onSorted(currentSort.map((s: any) => s.name + (s.desc ? ':desc' : '')).join(','), currentSort)
    }

    // -----

    const onClickOutside = (e: any) => {
        if (contRef.current) {
            if (!contRef.current.contains(e.target) && !e.target.closest('.un-popup-date')) {
                gridRef.current.api.stopEditing()
            }
        }
    }

    const onClickOtherside = (e: any) => {
        if (editRef.current) {
            const classNameStopEditing = ['ag-center-cols-viewport', 'ag-header-cell', 'ag-header-cell-text', 'ag-cell-label-container']
            if (classNameStopEditing.some(s => e.target.classList.contains(s))) {
                gridRef.current.api.stopEditing()
            }
        }
    }

    // ----- ENTERPRICE

    function processCellForClipboard (el: any) {
        return typeof el.value === 'object' ? cellFormat(el) : el.value
    }

    // ---- MEMO

    useEffect(() => {
        // hack แก้ปัญหา พอ edit แล้ว ไปคลิ้กส่วนอื่น
        document.addEventListener('mousedown', onClickOutside)
        contRef.current.addEventListener('click', onClickOtherside)
        return () => {
            document.removeEventListener('mousedown', onClickOutside)
            if (contRef?.current)
                contRef.current.removeEventListener('click', onClickOtherside)
        }
    }, [])

    useEffect(() => {
        if (props.checkbox && gridRef.current && gridRef.current.api) {
            // ตอนนี้ ยังทำให้แค่ ล้างการ ติ๊กข้อมูลก่อน
            const rows = gridRef.current.api.getSelectedNodes()
            if (props.checkbox[1].length !== rows.length) {
                if (props.checkbox[1].length === 0) {
                    gridRef.current.api.deselectAll()
                }
            }
        }
    }, [gridRef, props.checkbox])

    // ---- MEMO DATA

    const { columns, columnsDic } = useMemo(() => {
        const groups = props.enpGroup ? (typeof props.enpGroup === 'string' ? [props.enpGroup] : props.enpGroup) : undefined
        const sorts = props.sort?.split(',').map((s: string) => s.split(':')) || []
        const columns: any = props.columns.map((col: any) => colFetch(col, sorts, { sortFrontend: !!props.sortFrontend, groups }))

        if (props.checkbox) {
            columns.unshift({
                field: '_checkbox',
                headerName: '',
                cellClass: 'c -row_checkbox',
                width: 40,
                pinned: 'left',
                resizable: false,
                headerCheckboxSelection: true,
                checkboxSelection: true
            })
        }

        if (props.onRowSort) {
            columns.unshift({
                field: '_reorder',
                headerName: '',
                cellClass: '-row_reorder',
                width: 32,
                pinned: 'left',
                rowDrag: true,
                resizable: false
            })
        }

        return { columns, columnsDic: colFetchDic(props.columns) }
    }, [props.columns, props.sort, props.enpGroup])

    const datas: any = useMemo(() => {
        console.log('useMemo datas')
        return props.datas.map((o: any) => {
            if (o._total !== undefined) o[columns[0].field] = o._total
            if (o._group !== undefined) o[columns[0].field] = o._group
            return cellObject(o, columnsDic)
        })
    }, [props.datas, columns, columnsDic])

    const datasTotal: any = useMemo(() => props.datasTotal && props.datasTotal.length > 0
        ? props.datasTotal.map((o: any) => {
            if (!o._total) o._total = o._text || true
            o[columns[0].field] = o._total
            return cellObject(o, columnsDic)
        })
        : undefined, [props.datasTotal])

    // ---- MEMO SETTING

    const settingColumn = useMemo(() => {
        return {
            sortable: false,
            resizable: props.columnResize,
            //floatingFilter: true,
            wrapHeaderText: true,
            autoHeaderHeight: props.headerAutoHeight || false,         // ถ้าใส่ไอ้นี่เป็น true มันจะไม่ Merge cell header ตอน nested header
        }
    }, [])

    return <div ref={contRef} className={clsNames('un-datagrid ag-theme-balham', props.className, props.heightWithRow ? '-no_fill' : '-fill')}>
        {props.wait && <Wait/>}
        <Icon ref={clearStateRef} style={{ display: 'none' }} button name={'broom'} className="_icon-clear" onClick={onColumnReset}/>
        <AgGridReact
            ref={gridRef}

            domLayout={props.heightWithRow ? 'autoHeight' : 'normal'}

            defaultColDef={settingColumn}
            columnDefs={columns}
            dataTypeDefinitions={{ ['object']: { baseDataType: 'object', extendsDataType: 'object', valueFormatter: (p: any) => p.newValue?.value || null } }}
            suppressMovableColumns={!props.columnReorder}

            noRowsOverlayComponent={props.emptyComponent || EmptyRow}

            rowData={datas}
            pinnedBottomRowData={datasTotal?.length > 0 ? datasTotal : undefined}

            enableCellTextSelection={!props.enpRangeSelection}
            rowSelection={props.checkbox ? 'multiple' : undefined}
            onSelectionChanged={onCheckChanged}
            suppressRowClickSelection={!props.enpRangeSelection}

            rowDragManaged={props.onRowSort !== undefined}
            animateRows={props.onRowSort !== undefined}
            onRowDragEnd={onRowDragEnd}

            onSortChanged={onSortChanged}

            singleClickEdit={props.singleClickEdit}
            onCellEditingStarted={onCellEditingStarted}
            onCellEditingStopped={onCellEditingStopped}

            onCellValueChanged={onCellValueChanged}
            onPasteStart={onPasteStart}
            onPasteEnd={onPasteEnd}

            onGridReady={onGridReady}
            onRowDataUpdated={onRowDataUpdated}

            onColumnMoved={onColumnChange}
            onColumnResized={onColumnChange}
            onCellDoubleClicked={onCellDoubleClicked}

            suppressContextMenu /* ปิด context menu ของ cell */
            enableRangeSelection={props.enpRangeSelection ? true : undefined}
            processCellForClipboard={processCellForClipboard}

            groupDisplayType={'groupRows'}
            groupDefaultExpanded={props.enpGroupExpanded === undefined ? 1 : props.enpGroupExpanded}
        />
    </div>
}

// ----- EDITOR

const EmptyRow = () => {
    return <div className="un-datagrid-empty">NO DATA</div>
}

const EditorText = forwardRef((props: any, ref) => {
    const [value, setValue] = useState(cellValue(props.value) || '')
    const refInput = useRef<any>(null)

    useEffect(() => {
        refInput.current.focus()
    }, [])

    const isNumberCell = useMemo(() => props.value?.format && props.value?.format.startsWith('number'), [props.value])

    useImperativeHandle(ref, () => ({ getValue: () => isNumberCell ? { value: num(value), format: props.value?.format } : { value, format: props.value?.format } }))

    return <input ref={refInput} type={isNumberCell ? 'number' : 'text'}
                  className={clsNames('un-datagrid-input', props.column.colDef.headerClass)}
                  value={value} onChange={(event: any) => setValue(event.target.value)}/>
})

const EditorDate = forwardRef((props: any, ref) => {
    const [value, setValue] = useState(cellValue(props.value) || '')
    const refInput = useRef<any>(null)

    useEffect(() => {
        setTimeout(() => {
            if (refInput.current) {
                refInput.current.focus()
                refInput.current.click()
            }
        }, 200)
    }, [])

    useImperativeHandle(ref, () => ({ getValue: () => ({ value: dbdate(value), format: props.value?.format }) }))

    return <InputDatePopup value={todate(value)} onChange={setValue}>
        {(value, onClick, ref) => <input ref={refInput} type={'text'} className={clsNames('un-datagrid-input', props.column.colDef.headerClass)}
                                         readOnly defaultValue={date(value, 'P')} onClick={onClick}/>}
    </InputDatePopup>
})

const EditorSelectAc = forwardRef((props: any, ref) => {
    const [value, setValue] = useState(cellValue(props.value) || '')

    useImperativeHandle(ref, () => ({ getValue: () => ({ value, format: props.value?.format }) }))

    return <Select className="un-datagrid-select_ac" url={props.editorParams.action} value={value} onChange={setValue}/>
})

const EditorSelect = forwardRef((props: any, ref) => {
    const [value, setValue] = useState(cellValue(props.value) || '')

    useImperativeHandle(ref, () => ({ getValue: () => ({ value, format: props.value?.format }) }))

    return <select className="un-datagrid-select" value={value} onChange={(event: any) => setValue(event.target.value)}>
        {!props.editorParams.find((s: string) => s == value) && <option value={value}>{value || '---'}</option>}
        {props.editorParams?.map((s: string, x: number) => <option key={'s_' + x} value={s}>{s}</option>)}
    </select>
})

// ----- UTILS

function colFetchDic (cols: any, dics: any = {}) {
    cols.forEach((col: any) => {
        if (col.format) {
            dics[col.name] = { format: col.format }
        }
        else if (col.children !== undefined && Array.isArray(col.children)) {
            colFetchDic(col.children, dics)
        }
    })
    return dics
}

function colFetch (col: IDatagridColumns | IDatagridNestedColumns | any, sorts: any, option: any) {
    if (col.children !== undefined) {
        return {
            headerName: col.label,
            children: col.children.map((col: any) => colFetch(col, sorts, option))
        }
    }

    const column: ColDef = {
        field: col.name,
        headerName: col.label || col.text || '',
        width: col.width,
        headerClass: col.style,

        //format: col.format,
        cellClass: (el: any) => {
            return clsNames(col.style, el.data?.__style, el.value?.style,
                '_row-' + el.rowIndex, '_col-' + el.column.colId,
                el.data?._total !== undefined && '-total' + (el.column.colId === el.api.columnModel.gridColumns[0].colId ? ' -total_cell' : ''),
                el.data?._group !== undefined && '-group' + (el.column.colId === el.api.columnModel.gridColumns[0].colId ? ' -group_cell' : ''))
        },

        valueParser: (p: any) => p.value?.value || p.value,
        valueFormatter: cellFormat,
        colSpan: cellSpan,

        suppressMenu: true, // ปิด context menu ของ column ใน header
    }

    const sort = sorts.find((s: any) => s[0] === col.name)
    if (sort) column.sort = sort.length > 1 && sort[1] === 'desc' ? 'desc' : 'asc'

    if (col.fill) {
        column.flex = 1
        column.minWidth = col.width
    }

    if (option?.groups && option.groups.indexOf(col.name) >= 0) {
        column.rowGroup = true
        column.hide = true
    }

    if (col.pinned)
        column.pinned = col.pinned === true ? 'left' : col.pinned

    if (col.wrap) {
        column.wrapText = true
        column.autoHeight = true
    }

    if (col.format === 'html' || col.type === 'html') {
        column.cellRenderer = (p: any) => <span dangerouslySetInnerHTML={{ __html: cellValue(p.value) }}/>
    }
    else if (col.renderer) {
        column.cellRenderer = (p: any) => col.renderer(cellValue(p.value), p.data)
    }

    if (col.sortable) {
        column.sortable = true
        if (!option.sortFrontend)
            column.comparator = () => 0 // ไม่ให้มันจัดเรียง เพื่อจะได้ไปใช้
    }

    if (col.editable) {
        column.editable = true

        if (typeof col.editable === 'object' && col.editable?.type) {
            column.cellEditorParams = { editorParams: col.editable }
            if (col.editable?.type === 'select.ac') {
                column.cellEditor = EditorSelectAc
            }
            else if (col.editable?.type === 'checkbox') {

            }
        }
        else if (Array.isArray(col.editable)) {
            column.cellEditorParams = { editorParams: col.editable }
            column.cellEditor = EditorSelect
        }
        else if (col.editable === 'checkbox') {
            column.cellEditor = 'agCheckboxCellEditor'
            column.cellRenderer = 'agCheckboxCellRenderer'
        }
        else if (col.editable === 'date') {
            column.cellEditor = EditorDate
        }
        else {
            column.cellEditor = EditorText
        }
    }

    return column
}

function rowDatas (el: any) {
    const outputs: any = []
    el.api.forEachNode((rowNode: any, index: any) => {
        const output = { ...rowNode.data }
        for (const item of Object.keys(output))
            output[item] = typeof rowNode.data[item] === 'object' && rowNode.data[item].value !== undefined ? rowNode.data[item].value : rowNode.data[item]
        outputs.push(output)
    })
    return outputs
}

function cellSpan (el: any) {
    if (el.data?._total !== undefined || el.data?._group !== undefined) {
        if (el.api.columnModel?.gridColumns?.[0]?.colId === el.column.colId) {
            const foundIndex = el.api.columnModel.gridColumns.findIndex((c: any, x: number) => el.data[c.colId] !== undefined && x > 0)
            return foundIndex < 0 ? el.api.columnModel.gridColumns.length : foundIndex
        }
    }
    return 1
}

function cellObject (data: any, columnsDic: any) {
    return Object.entries(data).reduce((s: any, d: any) => {
        s[d[0]] = columnsDic.hasOwnProperty(d[0])
            ? typeof d[1] === 'object' ? { ...d[1], ...columnsDic[d[0]] } : { value: d[1] || '', ...columnsDic[d[0]] }
            : cellValue(d[1])
        return s
    }, {})
}

export function cellValue (value: any) {
    if (value === null) return ''
    if (typeof value === 'object') {
        if (value.value !== undefined) return value.value || ''
        // ไอ้นี่เอาไว้แก้ ข้อมูลที่แปลงมาจาก รายงาน
        if (value.format !== undefined) return ''
    }
    return value
}

function cellFormat (p: any) {
    const value = cellValue(p?.value)
    const format = p.value?.format || p.value?.type || ''
    if (typeof format === 'string') {
        if (format.startsWith('number') && typeof value === 'number') {
            if (format === 'number') return num(value, 0) || value
            const perc = format.indexOf('.') > 0 ? num(format.substring(format.indexOf('.') + 1)) : 0
            return num(value, perc) || value
        }
        if (format.startsWith('date')) {
            if (format === 'date' || format === 'date.s') return date(value, 'S')
            if (format === 'date.st') return date(value, 'St')
            if (format === 'date.stt') return date(value, 'ST')
        }
    }
    return value
}
