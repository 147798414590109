import { changeDate, isEmpty, num, todate } from 'unno-comutils'
import { isValidDate } from 'unno-comutils/utils'

const _moneyCal = (money: any, fomula: any) => {
    const f = num(fomula)
    if (f === 0) return 0
    if (f < 1) return Math.round(money * f * 100) / 100
    return f
}

export const calMoneyInsure = (doc?: any, noPaylot?: any) => {
    console.log('cal -> calMoneyInsure')
    doc.moneyInsure = num(doc.moneyInsure)
    doc.moneyStamptax = Math.ceil(doc.moneyInsure * 0.004)
    doc.moneyTax = Math.round((doc.moneyInsure + doc.moneyStamptax) * 7) / 100
    doc.moneyTotal = doc.moneyInsure + doc.moneyStamptax + doc.moneyTax

    calMoneyDFI(doc, noPaylot)

    return doc
}

export const calMoneyTotal = (doc?: any, noPaylot?: any) => {
    console.log('cal -> calMoneyTotal')
    doc.moneyTotal = num(doc.moneyTotal)

    const m1 = doc.moneyTotal / 1.07
    const m2 = m1 / 1.004
    doc.moneyTax = Math.round(m1 * 0.07)
    doc.moneyInsure = Math.round(m2)
    doc.moneyStamptax = Math.ceil(m2 * 0.004)

    calMoneyDFI(doc, noPaylot)

    return doc
}

export const calMoneyDFI = (doc?: any, noPaylot?: any) => {
    console.log('cal -> calMoneyDFI')
    if (!doc.__paylotCount || !doc.__payMeta || !doc.__payMeta.rate) return doc

    doc.moneyDiscount = _moneyCal(doc.moneyInsure, doc.__payMeta.rate.discount)
    doc.moneyFee = _moneyCal(doc.moneyTotal, doc.__payMeta.rate.fee)
    doc.moneyInterest = _moneyCal(doc.moneyTotal * doc.__paylotCount, doc.__payMeta.rate.interest)

    calMoneyNet(doc, noPaylot)

    return doc
}

export const calMoneyNet = (doc?: any, noPaylot?: any) => {
    console.log('cal -> calMoneyNet')
    doc.moneyNet = num(doc.moneyTotal) + num(doc.moneyFee) + num(doc.moneyInterest)
    doc.moneyNet -= num(doc.moneyDiscount)
    if (!noPaylot && doc.paylots && doc.paylots.length)
        calPaylot(doc)
    return doc
}

export const calPaylot = (doc?: any) => {
    const c = doc.paylots.length
    let money = doc.moneyNet
    let moneyLot = Math.ceil(money / c)
    doc.paylots.forEach((p: any, i: any) => {
        if (i === c - 1 && doc.__payMeta.rate?.pays.some((p: any) => p.lot === -1 && !isEmpty(p.discount))) {
            const lotpay = doc.__payMeta.rate?.pays.find((p: any) => p.lot === -1)
            p.money = Math.ceil(money - _moneyCal(money, lotpay.discount))
        }
        else {
            const lotpay = doc.__payMeta.rate?.pays.find((p: any) => p.lot === i + 1)

            if (lotpay) {
                const use = Math.ceil(_moneyCal(doc.moneyNet, lotpay.value))
                moneyLot = Math.ceil((money - use) / (c - (i + 1)))

                p.money = use
                money -= use
            }
            else {
                //p.money = money > moneyLot ? moneyLot : Math.ceil(money)
                p.money = moneyLot
                money -= moneyLot
            }
        }
    })
    return doc
}

export const calPaylotDate = (doc: any, startDate: any) => {
    if (!isValidDate(startDate)) startDate = todate(startDate)

    const dd = startDate.getDate()
    const due = doc.__payMeta && doc.__payMeta.dues && doc.__payMeta.dues.length > 0
        ? doc.__payMeta.dues.find((d: any) => {
            const day = d.day.split('-')
            return (day.length === 1 && dd === num(day[0])) || (day.length === 2 && dd >= num(day[0]) && dd <= num(day[1]))
        }) : null

    let date = new Date(startDate.getTime())
    let lastDate = new Date(startDate.getTime())

    const paylot_c = doc.paylots.length

    if (due) {
        for (let i = 0; i < paylot_c;) {
            let paylot = doc.paylots[i]
            if (due.lots[i + 1]) {
                paylot.date = new Date(date.getTime())
                const fm = due.lots[i + 1]
                if (fm === '$' || fm === '+$') {
                    date = changeDate(date, fm === '+$' ? 2 : 1, 'm')
                    paylot.date = new Date(date.getTime())
                    paylot.date.setDate(0)
                }
                else {
                    const cd = _calPaylotDay(due.lots[i + 1], lastDate.getDate())
                    if (cd !== 0) {
                        paylot.date.setDate(cd)
                    }
                }
                lastDate = new Date(paylot.date.getTime())
                if (fm.endsWith('+')) {
                    lastDate = changeDate(lastDate, 1, 'm')
                    lastDate.setDate(0)
                }
                i++
            }
            else if (due.lots.n) {
                const nx = due.lots.n.split('|')
                for (let j = 0; j < nx.length; j++) {
                    const isTail = nx[j].endsWith('$')

                    let testDate = new Date(lastDate)
                    lastDate.setDate(_calPaylotDay(nx[j], lastDate.getDate()))

                    /*
                    console.log({
                        key: nx[j],
                        testDate: dbdate(testDate),
                        getDate: lastDate.getDate(),

                        calDate: _calPaylotDay(nx[j], lastDate.getDate()),
                        lastDate: dbdate(lastDate),
                    })
                    */
                    //console.log('test', nx[j].padStart(6, ' '), dbdate(testDate), dbdate(lastDate))
                    if (testDate >= lastDate) {
                        lastDate = changeDate(lastDate, isTail ? 2 : 1, 'm')
                        if (isTail)
                            lastDate.setDate(_calPaylotDay(nx[j], lastDate.getDate()))
                    }

                    // ---- บังคับให้เป็น วันสิ้นเดือน ของเดือนหน้า
                    if (nx[j] === '+$') {
                        lastDate = changeDate(lastDate, 2, 'm')
                        lastDate.setDate(0)
                    }

                    const k = i++
                    if (k < paylot_c) {
                        doc.paylots[k].date = new Date(lastDate)
                    }
                    else {
                        break
                    }
                }
            }
            else {
                break
            }
        }
    }
    return doc
}

const _calPaylotDay = (v: any, dd: any) => {
    const vv = `${v}`
    if (vv === '0') return 0
    if (vv === '$' || vv === '+$') return 0
    if (vv.startsWith('+')) return dd + num(vv)
    if (vv.startsWith('-')) return dd - num(vv)
    return num(vv)
}
