import React, { useEffect, useMemo, useRef, useState } from 'react'

import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import { clsNames, date, dbdate, dConfirm, debounce, dError, get, isEmpty, num, post, qsget, qsset, sameDate, todate, tSuccess, useUser } from 'unno-comutils'
import { Button, createPaging, Icon, IconDelete, Menu, Modal, PageTitle, Paging, Wait } from 'unno-comutils/ui'
import List, { ListBody, ListButton, ListContainer, ListHead } from 'unno-comutils/List'
import { FormContainer, Input, InputDate, InputSearchRow, Photo, Radio, Select } from 'unno-comutils/form'
import Datagrid, { columnIconClick, IDatagridColumns } from 'unno-comutils/Datagrid'

import { CUSTOMER_GENDERs } from '../service/var'
import { CUSTOMER_JOBs, CUSTOMER_STATEs } from '../service/var.meta'
import { DEFAULT_CUSTOMER } from '../service/var.default'
import { ContactForm2 } from '../components/contact'
import { hhash, hhashRemove, hhashUpdate, idCardMask } from '../utils'
import BookView from './book/view'
import { ChatLogHistory, Message, MessageWait, ProviderIcon, Room } from '../components/chat'
import { useMeta } from '../app'
import InputSearch from '../components/test/InputSearch'

const MENU_MERGEs = [
    { page: 'book', name: 'สมุดรายวัน', icon: 'file-signature' },
    { page: 'wait', name: 'สัญญา', icon: 'feather' },
    { page: 'vehicle', name: 'รถ', icon: 'car' },
    { page: 'chat', name: 'ห้องแชท', icon: 'comments' },
]

const MENU_RELATIONs = [
    { page: 'chat', name: 'ห้องแชท' },
    { page: 'book', name: 'สมุดรายวัน' },
    { page: 'book_wait', name: 'สัญญา' }
]

export function ButtonEdit (props: any) {
    const { value, mode } = props
    if (!value) {
        if (props.cellProperties.row < 1)
            props.TD.className = '-header'
        return <></>
    }
    props.TD.className = props.cellProperties.className
    return <button className="btn-cell un-button un-button-dark" onClick={() => props.edit(value.id)}>แก้ไข</button>
}

export default function Customer () {
    const meta = useMeta()
    const [wait, setWait] = useState(true)

    const [lists, setLists] = useState<any>([])

    const [form, setForm] = useState<any>(null)
    const [formMerge, setFormMerge] = useState<any>(null)
    const [formCustomerLine, setFormCustomerLine] = useState<any>(null)

    const [viewRelation, setViewRelation] = useState<any>(null)

    const [tags, setTags] = useState<any>([])
    const [search, setSearch] = useState('')
    const [searchType, setSearchType] = useState<any>(null)
    const [searchState, setSearchState] = useState<any>(null)

    const [paging, setPaging] = useState(createPaging(1))

    // ----- ACTION

    const loadList = debounce((page?: any) => {
        if (!page) page = paging
        setWait(true)
        const qs = { page: page?.page || 1, search, type: searchType, state: searchState }
        get('customer/list', qs).then(({ ok, datas, paging }) => {
            if (ok) {
                const tags = []

                if (searchType) tags.push({ text: meta.CUSTOMER_TYPEs.find((d: any) => d.id === searchType)?.name, color: 'blue', onClear: () => setSearchType(null) })
                if (!isEmpty(searchState)) tags.push({
                    text: CUSTOMER_STATEs.find((d: any) => d.id === searchState)?.name,
                    color: 'green',
                    onClear: () => setSearchState(null)
                })

                setTags(tags)
                setLists(datas)
                setPaging(paging)

                const h = hhash()
                if (!h) qsset(qs)
            }
        }).finally(() => setWait(false))
    }, 300)

    const deleteData = (id: any) => {
        dConfirm('ยืนยันการลบข้อมูล').then(() => {
            get('customer/drop', { id: id }).then(d => {
                if (d.ok) {
                    tSuccess('ลบข้อมูลเรียบร้อยแล้ว')
                    loadList()
                }
                else dError(d)
            })
        })
    }

    // ----- MEMO

    useEffect(() => {
        const qs = qsget()
        if (qs.search) setSearch(qs.search)
        if (qs.type) setSearchType(num(qs.type))
        if (qs.state) setSearchState(num(qs.state))
        if (qs.page) setPaging(createPaging(num(qs.page)))

        const h = hhash()
        if (h && h.type === 'customer') setForm(h.id)
        if (h && h.type === 'customer_detail') setViewRelation(h.id)
    }, [])

    useEffect(() => {
        loadList()
    }, [searchType, searchState])

    const tableDatas = useMemo(() => {
        return lists
    }, [lists])

    const tableColumns = useMemo<IDatagridColumns[]>(() => [
        { name: 'number', label: 'รหัสลูกค้า', width: 80, style: 'c', pinned: 'left' },
        { name: 'name', label: 'ชื่อ-นามสกุล', width: 250, pinned: 'left' },
        { name: 'type', label: 'ประเภทลูกค้า', width: 150, style: 'c' },
        { name: 'birth', label: 'วันเกิด', width: 100, style: 'c' },
        { name: 'age', label: 'อายุ', width: 80, style: 'c' },
        { name: 'gender', label: 'เพศ', width: 80, style: 'c' },
        { name: 'job', label: 'อาชีพ', width: 150, style: 'c' },
        { name: 'address', label: 'ที่อยู่', width: 450 },
        { name: 'province', label: 'จังหวัด', width: 120, style: 'c' },
        { name: 'tel', label: 'เบอร์โทร', width: 120, style: 'c' },
        { name: 'email', label: 'Email', width: 120, style: 'c' },
        { name: 'btn_line', label: 'Line', width: 80, style: 'c', renderer: (_, d) => d.line && <Button success onClick={() => setFormCustomerLine(d.id)}>line</Button> },
        columnIconClick({ name: 'btn_edit', icon: 'cog', pinned: 'right', onClick: (_, p: any) => setForm(p.id), })
    ], [])

    // ----- RENDER

    return <>
        <PageTitle title="ลูกค้า" icon="user-crown">
            <InputSearch tags={tags} value={search} onChange={setSearch} onRefresh={() => loadList()} onPressEnter={loadList} refreshButton={{ label: 'ตกลง' }}>
                <InputSearchRow label="ประเภท" children={<Select options={meta.CUSTOMER_TYPEs} value={searchType} onChange={v => setSearchType(v)}/>}/>
                <InputSearchRow label="สถานะ" children={<Select options={CUSTOMER_STATEs} value={searchState} onChange={v => setSearchState(v)}/>}/>
            </InputSearch>
            <Button info className="ml-4" onClick={() => setFormMerge(0)}>รวมข้อมูล</Button>
            <Button success className="ml-2" onClick={() => setForm(0)}>เพิ่มรายการใหม่</Button>
        </PageTitle>

        <Datagrid wait={wait} wrapText name={'customer'} datas={tableDatas} columns={tableColumns}/>

        <Paging paging={paging} onChange={loadList}/>

        <CustomerForm id={form} onSave={() => loadList()} onClose={() => setForm(null)}/>
        <CustomerLineForm id={formCustomerLine} onClose={() => setFormCustomerLine(null)}/>

        <RelationView id={viewRelation} onClose={() => setViewRelation(null)}/>
        <MergeForm open={formMerge} onSave={loadList} onClose={() => setFormMerge(null)}/>
    </>
}

function CustomerLineForm (props: any) {
    const [datas, setDatas] = useState<any>([])

    // ----- ACTION

    const loadData = () => {
        get('customer/line/' + props.id).then((d: any) => {
            if (d.ok) {
                setDatas(d.datas)
            }
        })
    }

    const deleteData = (data: any) => {
        dConfirm('ยืนยันการลบข้อมูลนี้ !?').then(() => {
            post('customer/line_delete/' + props.id, { uid: data.uid }).then((d: any) => {
                if (d.ok) {
                    loadData()
                    tSuccess('ลบข้อมูลเรียบร้อยแล้ว')
                }
            })
        })
    }

    // ----- RENDER

    return <Modal title={'ข้อมูล Line'} open={props.id !== null} onClose={props.onClose} onOpenEnd={loadData} onCloseEnd={() => setDatas([])}>
        <ListContainer>
            <ListHead>
                <div className={'w-fill'}>ชื่อ</div>
                <div className={'w-datetime r'}>สร้างเมื่อ</div>
                <div className={'w-icon'}/>
            </ListHead>
            {datas.map((d: any, index: number) => {
                return <List key={'item_' + index}>
                    <div className={'w-fill'}>{d.profile.displayName}</div>
                    <div className={'w-datetime r'}>{date(d.time, 'St')}</div>
                    <IconDelete onClick={() => deleteData(d)}/>
                </List>
            })}
        </ListContainer>
    </Modal>
}

function RelationView (props: any) {
    const [menu, setMenu] = useState(MENU_RELATIONs[0])

    const onClose = () => {
        hhashRemove()
    }

    return <Modal title={'รายละเอียด'} md open={props.id !== null} onClose={props.onClose} onCloseEnd={onClose}>
        <Menu items={MENU_RELATIONs} value={menu.page} onChange={(_, p) => setMenu(p)} fixMargin/>
        <div className={'height-500'}>
            {(() => {
                if (menu.page === 'chat') return <RelationViewRoom id={props.id}/>
                if (menu.page === 'book') return <RelationViewBook id={props.id} mode={'book'}/>
                if (menu.page === 'book_wait') return <RelationViewBook id={props.id} mode={'wait'}/>
            })()}
        </div>
    </Modal>
}

function RelationViewRoom (props: any) {
    const { id } = props
    const user = useUser()

    const [room, setRoom] = useState<any>(null)
    const [rooms, setRooms] = useState<any>(null)
    const [messages, setMessages] = useState<any>([])

    const [wait, setWait] = useState<any>(true)
    const [waitMessage, setWaitMessage] = useState<any>(null)

    const msgRef = useRef<any>()

    // ----- ACTION

    const _sortMessage = (a: any, b: any) => b.timestamp - a.timestamp

    const loadRooms = () => {
        setRooms(null)
        get('chat/rooms', { customer: id }).then(({ ok, datas }) => {
            if (ok) {
                setRooms(datas.map((d: any) => ({ ...d })))
                hhashUpdate('customer_detail,' + props.id)
            }
        })
    }

    const loadChat = debounce((room: number, set?: boolean) => {
        if (set) setWait(true)
        get('chat/history/' + room).then(d => {
            if (d.ok && set) {
                setRoom(d.room)
                setMessages(d.datas.sort(_sortMessage))
            }
            else setMessages([])
        }).finally(() => {
            setWait(false)
            setWaitMessage(null)
        })
    }, 500)

    const loadHistory = debounce(() => {
        if (messages.length > 0) {
            setWaitMessage({ type: 'loading' })
            get('chat/history/' + room?.id, { last: messages[messages.length - 1].time }).then(d => {
                if (d.ok) {
                    if (d.datas) {
                        setMessages((prev: any) => {
                            const output = [...prev]
                            d.datas.forEach((d: any) => {
                                if (!messages.some((m: any) => m._id === d._id))
                                    output.push(d)
                            })
                            return output.sort(_sortMessage)
                        })
                    }
                }
            }).finally(() => setWaitMessage(null))
        }
    }, 250)

    // ----- MEMO

    useEffect(() => {
        loadRooms()
    }, [props.id])

    useEffect(() => {
        if (msgRef.current) {
            msgRef.current.addEventListener('scroll', (e: any) => {
                if (e.target.scrollHeight > e.target.clientHeight && Math.abs(e.target.scrollHeight + e.target.scrollTop - e.target.clientHeight) < 20) {
                    loadHistory()
                }
            })
        }
    }, [msgRef.current])

    const msgItems = useMemo(() => {
        if (!!messages && !!room) {
            const ms = messages.filter((d: any) => (!isEmpty(d.text) || !isEmpty(d.sticker) || !isEmpty(d.files)))
            if (ms.length > 0) {
                const x = ms.findIndex((m: any) => m.mode !== 'office')
                if (x >= 0 && ms[x].me && todate(ms[x].time) < todate(room.messageReaded)) ms[x].readed = true

                return ms.reverse()
                    .reduce((s: any[], o: any) => {
                        if (s.length === 0 || (s.length > 0 && s[s.length - 1].time && !sameDate(s[s.length - 1].time, o.time, 'd')))
                            s.push({ type: 'spliter', text: date(o.time, 'S') })
                        if (o.mode === 'office' && o.user && o.user.id === user.id) o.me = true
                        s.push(o)
                        return s
                    }, [])
                    .reverse()
            }
        }
        return []
    }, [room, messages])

    // ----- RENDER

    if (!rooms) return <Wait/>
    if (rooms.length === 0) return <></>
    return <div className={'row'}>
        <div className={'col w-5/12'}>
            {rooms?.map((room: any) => <Room key={'r' + room.id} room={room} current={room} onClick={() => loadChat(room.id, true)}/>)}
        </div>
        <ListBody className={'col w-7/12 height-500 chat-message-box'}>
            {room && wait && <Wait/>}
            <div ref={msgRef} className="_message">
                {waitMessage && waitMessage.type !== 'loading' && <MessageWait data={waitMessage}/>}
                {msgItems.map((d: any, i: number) => <Message data={d} room={room} key={d.type && d.type === 'spliter' ? 'spt_' + d.text : 'room_' + d._id}/>)}
            </div>
        </ListBody>
    </div>
}

function RelationViewBook (props: any) {
    const { id, mode } = props

    const [documents, setDocuments] = useState<any>(null)

    const [view, setView] = useState<any>(null)
    const [wait, setWait] = useState<any>(true)

    // ----- ACTION

    const loadDocuments = () => {
        setDocuments(null)
        setWait(true)
        get(mode === 'wait' ? 'book_wait/list' : 'book/list', { customer: props.id }).then(({ ok, datas }) => {
            if (ok) {
                setDocuments(datas.map((d: any) => ({ ...d })))
                hhashUpdate('customer_detail,' + props.id)
            }
        }).finally(() => setWait(false))
    }

    // ----- MEMO

    useEffect(() => {
        loadDocuments()
    }, [props.id, props.mode])

    // ----- RENDER

    return <>
        <ListContainer>
            <ListHead>
                <div className={'w-date r'}>วันที่แจ้ง</div>
                <div className={'w-20 c'}>ลูกค้า</div>
                <div className={'w-24 c'}>ประเภท</div>
                <div className={'w-fill'}>ทะเบียน</div>
                <div className={'w-date r'}>วันหมดอายุ</div>
            </ListHead>
            <ListBody wait={wait}>
                {!!documents && documents.map((d: any) => <List key={'list-' + d.id}>
                    <ListButton fill onClick={() => setView(d.id)}>
                        <div className={'w-date r blue'}>{date(d.date)}</div>
                        {d.customerId === id ? <div className={'w-20 c green'}>เจ้าของ</div>
                            : (d.customerPayId === id ? <div className={'w-20 c red'}>ผู้ผ่อน</div> : <div className={'w-20 c grey'}>?</div>)}
                        <div className={'w-24 c grey-blue'}>{d.insuretypeName}</div>
                        <div className={'w-fill'}>{d.vehicleLicense}</div>
                        <div className={'w-date r'}>{date(d.dateEnd)}</div>
                    </ListButton>
                </List>)}
            </ListBody>
        </ListContainer>
        <BookView get={mode == 'wait'} id={view} onClose={() => setView(null)}/>
    </>
}

function MergeForm (props: any) {
    const [saveWait, setSaveWait] = useState<any>(false)

    const [customerFrom, setCustomerFrom] = useState<any>(null)
    const [customerTo, setCustomerTo] = useState<any>(null)

    // ----- ACTION

    const saveData = () => {
        setSaveWait(true)
        dConfirm('ยืนยันการรวมข้อมูล !?').then(() => {
            post('customer/save_merge', { from: customerFrom?.id, to: customerTo?.id }).then(d => {
                if (d.ok) {
                    props.onSave()
                    props.onClose()
                }
                else dError(d)
            }).finally(() => setSaveWait(false))
        }).finally(() => setSaveWait(false))
    }

    // ----- EVENT

    const onOpenEnd = () => {
        if (props.open > 0) {
            get('customer/get/' + props.open).then(({ ok, data }) => {
                if (ok)
                    setCustomerFrom({ id: data.id, name: data.name })
            })
        }
    }

    // ----- RENDER

    return <Modal title={'รวมข้อมูล'} lg open={props.open !== null} onClose={props.onClose} onOpenEnd={onOpenEnd}
                  footer={<Button error fullWidth onClick={saveData} loading={saveWait}>ย้ายข้อมูล และลบทิ้ง</Button>}>
        <div className={'flex'}>
            <MergeView label={'ย้ายจาก'} customer={customerFrom} onChange={setCustomerFrom}/>
            <Icon name={'arrow-right'} className={'red my-auto icon-merge'}/>
            <MergeView label={'ไปที่'} customer={customerTo} onChange={setCustomerTo}/>
        </div>
    </Modal>
}

function MergeView (props: any) {
    const user = useUser()

    const { label, customer, onChange } = props

    const [data, setData] = useState<any>({})
    const [menu, setMenu] = useState<any>(MENU_MERGEs[0])

    const [room, setRoom] = useState<any>(null)
    const [rooms, setRooms] = useState<any>([])
    const [messages, setMessages] = useState<any>([])

    const [wait, setWait] = useState<any>(true)
    const [waitMessage, setWaitMessage] = useState<any>(null)

    const msgRef = useRef<any>()

    // ----- ACTION

    const loadChat = debounce((room: number, set?: boolean, msgId?: string) => {
        if (set) setWait(true)
        get('chat/history/' + room).then(d => {
            if (d.ok && set) {
                setRoom(d.room)
                setMessages(d.datas.sort(_sortMessage))
            }
            else setMessages([])
        }).finally(() => {
            setWait(false)
            setWaitMessage(null)
        })
    }, 500)

    // ----- EVENT

    const _sortMessage = (a: any, b: any) => b.timestamp - a.timestamp

    // ----- MEMO

    useEffect(() => {
        if (customer) {

            get('customer/get/' + customer?.id, { deep: 1 }).then(({ ok, ...d }) => {
                if (ok) setData(d)
            })

            get('chat/rooms', { customer: customer?.id }).then(({ ok, datas }) => {
                if (ok) setRooms(datas.map((d: any) => ({ ...d })))
            })

        }
        else setData({})
    }, [customer])

    const msgItems = useMemo(() => {
        if (!!messages && !!room) {
            const ms = messages.filter((d: any) => (!isEmpty(d.text) || !isEmpty(d.sticker) || !isEmpty(d.files)))
            if (ms.length > 0) {
                const x = ms.findIndex((m: any) => m.mode !== 'office')
                if (x >= 0 && ms[x].me && todate(ms[x].time) < todate(room.messageReaded)) ms[x].readed = true

                return ms.reverse()
                    .reduce((s: any[], o: any) => {
                        if (s.length === 0 || (s.length > 0 && s[s.length - 1].time && !sameDate(s[s.length - 1].time, o.time, 'd')))
                            s.push({ type: 'spliter', text: date(o.time, 'S') })
                        if (o.mode === 'office' && o.user && o.user.id === user.id) o.me = true
                        s.push(o)
                        return s
                    }, [])
                    .reverse()
            }
        }
        return []
    }, [room, messages])

    const renderBook = (datas: any) => {
        if (!datas) return null
        return <ListContainer>
            <ListHead>
                <div className="w-20 r">วันที่</div>
                <div className="w-24">ทะเบียน</div>
                <div className="w-20 c">ประเภท</div>
                <div className="w-fill">บริษัทประกัน</div>
            </ListHead>
            <ListBody scroll>{datas.map((d: any) => <List key={'d' + d.id}>
                <div className={'w-20 r grey-blue'}>{date(d.date)}</div>
                <div className={'w-24 text-overflow blue'}>{d.vehicleLicense}</div>
                <div className={'w-20 c text-overflow green'}>{d.metaInsuretype?.name}</div>
                <div className={'w-fill'}>{d.company?.name}</div>
            </List>)}</ListBody>
        </ListContainer>
    }

    const renderVehicle = (datas: any) => {
        if (!datas) return null
        return <ListContainer>
            <ListHead>
                <div className="w-24">ทะเบียน</div>
                <div className="w-fill">ภาพ</div>
            </ListHead>
            <ListBody scroll>{datas.map((d: any) => <List key={'d' + d.index} className={'sm'}>
                <div className={'w-20 blue'}>{d.license}</div>
                <div className={'w-fill flex'}>
                    {d.photos.map((p: any, x: number) => <a key={'p' + x} href={p.url} className="detail-item-photo" target={'_blank'}>
                        <div className="_img" style={{ backgroundImage: `url("${encodeURI(p.url)}")` }}/>
                    </a>)}
                </div>
            </List>)}</ListBody>
        </ListContainer>
    }

    const renderChat = () => {
        if (rooms.length === 0) return <></>
        return <div className={'row'}>
            <div className={'col w-5/12'}>
                {rooms?.map((room: any) => <Room key={'r' + room.id} room={room} current={room} onClick={() => loadChat(room.id, true)}/>)}
            </div>
            <ListBody scroll className={'col w-7/12 height-500 chat-message-box'}>
                <div ref={msgRef} className="_message">
                    {waitMessage && waitMessage.type !== 'loading' && <MessageWait data={waitMessage}/>}
                    {msgItems.map((d: any) => <Message data={d} room={room} key={d.type && d.type === 'spliter' ? 'spt_' + d.text : 'room_' + d._id}/>)}
                </div>
            </ListBody>
        </div>
    }

    return <div className={'customer-merge'}>
        <Select m0 label={label} value={customer} url={'ac/customer'} onChange={(_, v) => onChange(v)}/>
        {!!customer && <>
            <Menu className="my-2" layout={'tab'} items={MENU_MERGEs} value={menu.page} onChange={(_, v) => setMenu(v)}/>
            <div className="page-content">{(() => {
                if (menu.page === 'book') return renderBook(data?.docs)
                if (menu.page === 'wait') return renderBook(data?.docWaits)
                if (menu.page === 'vehicle') return renderVehicle(data?.data?.vehicles)
                if (menu.page === 'chat') return renderChat()
            })()}</div>
        </>}
    </div>
}

export function CustomerForm (props: any) {
    const user = useUser()

    const [wait, setWait] = useState(false)
    const [form, setForm] = useState<any>(null)

    const [chatlog, setChatlog] = useState<any>(null)

    // ----- ACTION

    const loadData = () => {
        if (props.id > 0) {
            setWait(true)
            get('customer/get/' + props.id).then(({ ok, data }) => {
                if (ok) {
                    setForm(data)
                    hhashUpdate('customer,' + data.id)
                }
                else props.onClose()
            }).finally(() => setWait(false))
        }
        else setForm(DEFAULT_CUSTOMER())
    }

    const saveData = (c: any) => {
        const saveData = {
            ...form,
            idcard: form.idcard.replace(/\D/g, ''),
            type: form.type?.id,
            job: form.job?.id,
            birth: dbdate(form.birth),
        }
        post('customer/save/' + props.id, saveData).then(d => {
            if (d.ok) {
                tSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
                props.onSave(d.customer)
                props.onClose()
            }
            else dError(d)
        }).finally(c)
    }

    const deleteData = () => {
        dConfirm('ยืนยันการลบข้อมูล').then(() => {
            get('customer/drop', { id: props.id }).then(d => {
                if (d.ok) {
                    tSuccess('ลบข้อมูลเรียบร้อยแล้ว')
                    props.onSave()
                    props.onClose()
                }
                else dError(d)
            })
        })
    }

    // ----- EVENT

    const onChange = (update: any) => setForm((prev: any) => ({ ...prev, ...update }))

    const onClose = () => {
        hhashRemove()
        setForm({})
    }

    // ----- MEMO

    useEffect(() => {
        if (props.infoChat) loadData()
    }, [props.infoChat, props.data])

    // ----- RENDER

    const modalProps: any = {
        title: 'ลูกค้า',
        lg: true,
        open: props.id !== null,
        noCloseBackdrop: true,
        onOpenEnd: loadData,
        onCloseEnd: onClose,
        onClose: props.onClose,

        className: 'customer-form',

        footerSave: !!form && saveData,
        footerDrop: props.id > 0 && deleteData
    }

    if (props.sideBar) {
        modalProps.noBackdrop = true
        modalProps.openEffect = 'right'
        modalProps.startPosition = { right: 0, top: 0 }
        modalProps.className = 'chat-sidebar'
    }

    return <>
        <Modal {...modalProps}>
            {wait && <Wait/>}
            {!!form ? <CustomerPureForm data={form} onChange={onChange}/> : <Wait block/>}
            {!!form && user.admin && form?.rooms && <div className="system-info-form mt-8">
                <div className="_header">ห้องแชท</div>
                <ListContainer>
                    {form?.rooms?.map((room: any, index: any) => <List key={'list_' + index}>
                        <div className={'w-12 grey-blue'}>#{room.id}</div>
                        <div className={'w-80 tcut'}><ProviderIcon name={room.provider?.provider}/> {room.key}</div>
                        <div className={'w-fill grey tcut'}>{room.messageLast}</div>
                        <div className={'w-datetime grey-blue r'}>{date(room.updateTime, 'St')}</div>
                        <Button className={'w-20'} dark onClick={() => setChatlog(room)}>ประวัติ</Button>
                    </List>)}
                </ListContainer>
            </div>}
        </Modal>
        <ChatLogHistory room={chatlog} onClose={() => setChatlog(null)}/>
    </>
}

export function CustomerPureForm (props: any) {
    const meta = useMeta()
    const { data, onChange } = props

    const [formCrop, setFormCrop] = useState<any>(null)

    return <>
        <FormContainer label={'ข้อมูลลูกค้า'} className="_customer-from-container">
            <div className="flex-on _input-flex-row">
                <Select label={'ประเภทลูกค้า'} className={'w-60'} options={meta.CUSTOMER_TYPEs} value={data.type} onChange={(_, v) => onChange({ type: v })}/>
                <Input label={'ชื่อ-สกุล'} className={'w-fill ml-4 _input-name'} value={data.name} onChange={name => onChange({ name })}/>
                <div className="flex-break-hidden"/>
                <InputDate label={'วันเกิด'} className="ml-4" icon fixWidth value={data.birth} onChange={birth => onChange({ birth })}/>
                <Radio label={'เพศ'} className={'ml-4 _input-gender'} options={CUSTOMER_GENDERs} value={data.gender} onChange={v => onChange({ gender: v })}/>
            </div>

            <div className="flex _input-photo-stack mb-6">
                <div className="document-customer-photo">
                    <div className="_label">บัตรประชาชน</div>
                    <Photo m0 tag="idcard" value={data.photos || ''} onChange={v => onChange({ photos: v })}/>
                    <Input m0 sm label={'เลขบัตรประชาชน'} className={'_input-idcard'} center value={idCardMask(data.idcard)} onChange={idcard => onChange({ idcard })}/>
                    {data.photos?.some((f: any) => f.tag === 'idcard')
                        && <Icon button name="crop" className={clsNames('_icon-crop', data.photos.find((f: any) => f.tag === 'idcard').crop && '-croped')}
                                 onClick={() => setFormCrop(data)}/>}
                    <InputDate label={'วันหมดอายุบัตรประชาชน'} className={'mt-5'} m0 sm icon value={data.dateLicenseExpire} onChange={v => onChange({ dateLicenseExpire: v })}/>
                </div>
                <div className="document-customer-photo">
                    <div className="_label">ใบขับขี่</div>
                    <Photo tag="drivingLicense" value={data.photos || ''} onChange={v => onChange({ photos: v })}/>
                </div>
            </div>
            <Radio label={'อาชีพ'} value={data.job?.id} options={CUSTOMER_JOBs} onChange={(_, job) => onChange({ job })}/>
        </FormContainer>
        <ContactForm2 slim={props.slim} onChange={onChange} data={data}/>
        <CustomerPhotoCropForm data={formCrop} onSave={(v: any) => onChange({ photos: v })} onClose={() => setFormCrop(null)}/>
    </>
}

function CustomerPhotoCropForm (props: any) {
    const { data, onClose } = props

    const [src, setSrc] = useState('')
    const [crop, setCrop] = useState<any>({})

    // ----- ACTION

    const onloadData = () => {
        const photo = data.photos && data.photos.find((d: any) => d.tag === 'idcard') || []
        if (photo) {
            setSrc(photo.url)
            if (photo.crop) {
                setCrop({
                    x: photo.crop.x,
                    y: photo.crop.y,
                    width: photo.crop.w,
                    height: photo.crop.h,
                    unit: '%'
                })
            }
        }
    }

    const saveData = (c: any) => {
        const { x, y, width, height } = crop
        const saveData = {
            x: num(x.toFixed(4)),
            y: num(y.toFixed(4)),
            w: num(width.toFixed(4)),
            h: num(height.toFixed(4)),
        }
        const photos = data.photos.map((d: any) => d.tag === 'idcard' ? ({ ...d, crop: saveData }) : d)
        props.onSave(photos)
        props.onClose()
        c()
    }

    // ----- RENDER

    return <Modal title="crop ภาพบัตรประชาชน" open={data !== null} onClose={onClose} onOpenEnd={onloadData} footerSave={saveData}>
        {/*@ts-ignore*/}
        <ReactCrop crop={crop} onChange={setCrop} onComplete={(c: any, x: any) => setCrop(x)}><img src={src}/></ReactCrop>
    </Modal>
}

export function CustomerBookForm (props: any) {
    const [data, setData] = useState(props.customer)
    const [edited, setEdited] = useState(false)

    const onChange = (update: any) => {
        if (!edited) setEdited(true)

        const dataUpdate = { ...data, ...update }
        setData(dataUpdate)
        props.onChange(dataUpdate)
    }

    useEffect(() => {
        setData(props.customer)
    }, [props.customer.id])

    return <div className={clsNames('document-customer-form', edited && '-update')}>
        <CustomerPureForm slim data={data} onChange={onChange}/>
    </div>
}
