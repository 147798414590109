import React, { useEffect, useMemo, useRef, useState } from 'react'

import { date, dbdate, dConfirm, debounce, dError, get, isDev, num, post, qsget, qsset, round, tSuccess } from 'unno-comutils'
import { Button, createPaging, Icon, Modal, PageScroll, PageTitle, Paging, Wait } from 'unno-comutils/ui'
import { fromRangeParam, Input, InputDate, InputDateRange, InputSearch, InputSearchRow, Select } from 'unno-comutils/form'

import { hhash, hhashRemove, hhashUpdate } from '../../utils'
import { createTags } from '../../service/search'
import { RevenueProcess } from './employee'
import Datagrid, { cellValue, columnIconClick, IDatagridColumns } from 'unno-comutils/Datagrid'
import { DEFAULT_ACCOUNT_AGENT } from '../../service/var.default'
import { PAYWAY_TYPEs } from '../../service/var.meta'

export default function Agent () {
    const [wait, setWait] = useState(false)

    const [datas, setDatas] = useState<any>([])
    const [form, setForm] = useState<any>(null)

    const [tags, setTags] = useState<any>([])
    const [searchDate, setSearchDate] = useState<any>('')
    const [searchAgent, setSearchAgent] = useState<any>('')

    const [paging, setPaging] = useState(createPaging(1))

    // ----- ACTION

    const loadList = debounce((page?: any) => {
        setWait(true)
        if (!page) page = paging
        const qs = { page: page?.page || 1, date: dbdate(searchDate), agent: searchAgent?.id, }
        get('account/agent/list', qs).then(({ ok, datas, paging }) => {
            if (ok) {
                setTags(createTags({ searchDate, setSearchDate, searchAgent, setSearchAgent }))
                setDatas(datas)
                setPaging(paging)

                const h = hhash()
                if (!h) qsset(qs)
            }
        }).finally(() => setWait(false))
    }, 500)

    // ----- MEMO

    useEffect(() => {
        const qs = qsget()
        if (qs.agent) get('app/qs', { agent: qs.agent }).then(d => d.ok && d.agent && setSearchAgent(d.agent))
        if (qs.date) setSearchDate(fromRangeParam(qs.date))
        if (qs.page) setPaging(createPaging(num(qs.page)))

        const h = hhash()
        if (h && h.type === 'agent') setForm(h.id)
    }, [])

    useEffect(() => {
        loadList()
    }, [searchDate, searchAgent])

    const tableDatas = useMemo(() => {
        return datas.map((d: any) => ({
                ...d,
                date: date(d.date, 'S'),
                agent: d.agent?.name || '??',
                moneyNet: d.agentMoney - d.agentMoneyTax
            })
        )
    }, [datas])

    const tableColumns = useMemo<IDatagridColumns[]>(() => [
        { name: '_rownum', label: '', width: 60, style: 'c' },
        { name: 'date', label: 'วันที่', width: 120, style: 'r -blue-text' },
        { name: 'agent', label: 'ตัวแทน', width: 150, },
        { name: 'count', label: 'รายการ', width: 60, style: 'c' },
        { name: 'agentMoney', label: 'ค่าตอบแทน', width: 100, style: 'r -orange-text', format: 'number.2' },
        { name: 'agentMoneyTax', label: 'หักภาษี', width: 90, style: 'r -grey-blue-text', format: 'number.2' },
        { name: 'moneyNet', label: 'ค่าตอบแทน', width: 100, style: 'r -green-text', format: 'number.2' },
        { name: 'note', label: 'หมายเหตุ', width: 200, fill: true },
        {
            name: 'btn_print',
            label: '',
            width: 40,
            pinned: 'right',
            renderer: (_: any, d: any) => <Icon button name="print" href={'/d/account_agent.pdf?id=' + d.id} targetBlank/>
        },
        {
            name: 'btn_image',
            label: '',
            width: 40,
            pinned: 'right',
            renderer: (_: any, d: any) => <Icon button name="image-polaroid" href={'/d/account_agent.pdf?id=' + d.id + '&img=1'} targetBlank/>
        },
        columnIconClick({ name: 'btn_edit', icon: 'cog', pinned: 'right', onClick: (_, p: any) => setForm(p.id) })
    ], [])

    // ----- RENDER

    return <>
        <PageTitle icon="person-sign" title="ตัดจ่ายตัวแทน">
            <InputSearch tags={tags} onRefresh={() => loadList()}>
                <InputSearchRow label="วันที่" children={<InputDateRange icon fixWidth value={searchDate} onChange={v => setSearchDate(v)}/>}/>
                <InputSearchRow label="ตัวแทน" children={<Select value={searchAgent} url="/ac/agent" onChange={(_, v) => setSearchAgent(v)}/>}/>
            </InputSearch>
            <Button success className="ml-3" onClick={() => setForm(0)}>เพิ่มรายการใหม่</Button>
        </PageTitle>

        <Datagrid wait={wait} wrapText name={'account_agent'} datas={tableDatas} columns={tableColumns}/>

        <Paging paging={paging} onChange={loadList}/>
        <AgentForm id={form} onSave={() => loadList()} onClose={() => setForm(null)}/>
    </>
}

const DEFAULT_AGENT_SUM = () => ({ nodes: 0, agentMoney: 0, agentMoneyTax: 0 })

export function AgentForm (props: any) {
    const [wait, setWait] = useState(false)
    const [agent, setAgent] = useState<any>({})

    const [checked, setChecked] = useState<any>([])

    const [form, setForm] = useState<any>({})
    const [documents, setDocuments] = useState<any>([])

    const [sum, setSum] = useState(DEFAULT_AGENT_SUM())

    const [revenueLoading, setRevenueLoading] = useState(false)
    const [revenueDatas, setRevenueDatas] = useState<any>(null)
    const [revenueProcess, setRevenueProcess] = useState(null)

    const [run, setRun] = useState(0)

    const readOnly = !!props.readOnly

    const gridRef = useRef<any>()

    // ----- ACTION

    const loadData = () => {
        if (props.id > 0) {
            setWait(true)
            get('account/agent/get/' + props.id).then(({ ok, data }) => {
                if (ok) {
                    const { documents, ...o } = data
                    setForm(o)
                    agentLoad(o.agent?.id)
                    hhashUpdate('agent,' + data.id)
                }
                else props.onClose()
            }).finally(() => setWait(false))
        }
        else {
            if (isDev) {
                setForm({ ...DEFAULT_ACCOUNT_AGENT, agent: { id: 1106, name: 'กิ๊บ' } })
                agentLoad(1106)
                setTimeout(() => {
                    //console.log('onCheckAll')
                    //onCheckAll(true)
                }, 2000)
            }
            else {
                setRevenueDatas(null)
                setForm({ ...DEFAULT_ACCOUNT_AGENT })
            }
        }
    }

    const saveData = (c: any) => {
        const saveData = {
            agent: form.agent?.id,
            type: form.type,
            bank: form.bank?.id,
            date: date(form.date, 'DB'),
            note: form.note,
            documents: gridRef.current.api.getSelectedNodes().map((d: any) => ({
                id: d.data.id,
                agentMoney: cellValue(d.data.agentMoney),
                agentMoneyTax: cellValue(d.data.agentMoneyTax)
            }))
        }
        //console.log(saveData.documents)
        //return c()
        post('account/agent/save/' + props.id, saveData).then(d => {
            if (d.ok) {
                tSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
                props.onSave(d.id)
                props.onClose()
            }
            else dError(d)
        }).finally(c)
    }

    const deleteData = () => {
        dConfirm('ยืนยันการลบข้อมูล').then(() => {
            get('account/agent/delete/' + props.id).then(d => {
                if (d.ok) {
                    tSuccess('ลบข้อมูลเรียบร้อยแล้ว')
                    props.onSave()
                    props.onClose()
                }
                else dError(d)
            })
        })
    }

    const agentLoad = (id?: any) => {
        setWait(true)
        get('setting/agent/get/' + id).then(({ ok, data }) => {
            if (ok) {
                setAgent(data)
            }
        }).finally(() => setWait(false))
    }

    const docLoad = () => {
        if (agent?.id > 0) {
            setWait(true)
            get('account/agent/documents', { id: props.id, agent: agent?.id }).then(({ ok, documents }) => {
                if (ok) {
                    const __documents = documents.map((d: any) => ({
                        ...d,
                        date: date(d.date),
                        dateStart: date(d.dateStart),
                        customer: d.customer?.name || '',
                        agent: d.agent?.name || '',
                        metaInsuretype: d.metaInsuretype?.name || '',
                        isNew: d.isNew ? 'ใหม่' : 'ต่ออายุ',
                        btnEdit: { id: d.id },
                    }))
                    const ccs = __documents.filter((d: any) => d.checked).map((d: any) => d.id)
                    setDocuments(__documents)
                    setChecked(ccs)
                }
            }).finally(() => setWait(false))
        }
    }

    const calSum = debounce(() => {
        const nodes = gridRef.current && gridRef.current.api.getSelectedNodes()
        const nodeSelecteds = nodes.map((d: any) => ({
            id: d.data.id,
            agentMoney: cellValue(d.data.agentMoney),
            agentMoneyTax: cellValue(d.data.agentMoneyTax)
        })) || []

        const agentMoney = nodeSelecteds.reduce((sum: any, d: any) => sum + num(d.agentMoney), 0)
        const agentMoneyTax = nodeSelecteds.reduce((sum: any, d: any) => sum + num(d.agentMoneyTax), 0)

        setSum({ nodes: nodes.length, agentMoney, agentMoneyTax })
    }, 300)

    const calRevenue = debounce(() => {
        const docs = gridRef.current.api.getSelectedNodes().map((d: any) => d.data.id)
        //console.log('docs select = ', docs)
        if (docs && docs.length > 0) {
            setRevenueLoading(true)
            post('account/agent/cal_revenue/' + agent.id, { docs }).then(d => {
                setRevenueDatas(d.datas || [])
                if (d.datas && d.datas.length > 0) {
                    for (const g of d.datas) {
                        if (g.docs && g.docs.length > 0) {
                            for (const h of g.docs) {
                                if (h.moneyProcess >= 0) {
                                    gridRef.current.api.forEachNode((rowNode: any) => {
                                        if (rowNode.data.id === h.id) {
                                            rowNode.setDataValue('agentMoney', round(h.moneyProcess, 2))
                                            rowNode.setDataValue('agentMoneyTax', round(h.moneyProcessTax, 2))
                                            calSum()
                                        }
                                    })
                                }
                            }
                        }
                    }
                }
            }).finally(() => setRevenueLoading(false))
        }
        else {
            setRevenueDatas(null)
            calSum()
        }
    }, 300)

    // ----- EVENT

    const onChange = (update: any) => {
        if (update.hasOwnProperty('agent')) {

            setSum(DEFAULT_AGENT_SUM())

            update.bank = ''
            agentLoad(update.agent.id)
        }
        setForm((prev: any) => ({ ...prev, ...update }))
    }

    const onClose = () => {
        hhashRemove()
        setForm([])

        // ----- clear

        setDocuments([])
        setChecked([])
        setRevenueDatas(null)
        setSum(DEFAULT_AGENT_SUM())
    }

    // ----- DATATABLE

    function onGridReady (el: any) {
        gridRef.current = el
    }

    function onUnCheck (ids: any) {
        if (!readOnly) {
            const nodes: any = []
            gridRef.current.api.forEachNode((node: any) => {
                if (node.data && ids.indexOf(node.data.id) >= 0) nodes.push(node)
            })
            gridRef.current.api.setNodesSelected({ nodes })
        }
    }

    function onCheckNew (chk: any) {
        setChecked(chk)
        calRevenue()
    }

    function onEditedDocument (rowIndex: number, colName: string, row: any, rows: any) {
        const value = row[colName]?.value || row[colName]
        if (gridRef.current.api) {
            gridRef.current.api.forEachNode((rowNode: any, index: number) => {
                if (rowIndex === index) {
                    rowNode.setDataValue(colName, num(value))
                    if (colName === 'agentMoney') {
                        get('book/cal_agent/' + rowNode.data.id, { agentMoney: value }).then((d: any) => {
                            d.updates.forEach((u: any) => {
                                rowNode.setDataValue(u.name, num(u.value))
                                calSum()
                            })
                        })
                    }
                    calSum()
                }
            })
        }
    }

    // ----- MEMO

    const tableColumns = useMemo<IDatagridColumns[]>(() => {
        return [
            { name: 'rownum', label: '#', width: 40, style: 'c', pinned: 'left' },
            { name: 'dateStart', label: 'เริ่มคุ้มครอง', width: 110, style: 'c' },
            { name: 'insuranceNumber', label: 'กรมธรรม์เลขที่', width: 180, style: 'c' },
            { name: 'vehicleLicense', label: 'ทะเบียน', width: 110, style: 'c' },
            { name: 'customer', label: 'ชื่อลูกค้า', width: 200 },
            { name: 'metaInsuretype', label: 'ประเภทประกัน', width: 110, style: 'c' },
            { name: 'moneyInsure', label: 'เบี้ยประกันภัยสุทธิ', width: 120, style: 'r', format: 'number.2' },
            { name: 'moneyTotal', label: 'เบี้ยประกันภัยรวม', width: 120, style: 'r', format: 'number.2' },
            { name: 'moneyNet', label: 'ยอดเงินรวม', width: 120, style: 'r', format: 'number.2' },
            { name: 'paylotMoneyPay', label: 'ยอดลูกค้าชำระ', width: 120, style: 'r', format: 'number.2' },
            { name: 'agentMoney', label: 'ค่าตอบแทน', width: 120, style: 'r -blue', format: 'number.2', editable: !readOnly },
            { name: 'agentMoneyTax', label: 'หักภาษี ณ ที่จ่าย', width: 120, style: 'r -blue', format: 'number.2', editable: !readOnly },
            { name: 'note', label: 'หมายเหตุ', width: 250 }
        ]
    }, [readOnly])

    const tableDatas = useMemo(() => {
        return documents.map((d: any, i: number) => ({ ...d, rownum: i + 1 }))
    }, [documents, readOnly])

    const PAYWAY_TYPE_OPTIONs = useMemo(() => PAYWAY_TYPEs.filter((x: any) => x.payagent) || [], [props])

    const propMeodal = readOnly ? {} : { footerSave: saveData, footerDrop: props.id > 0 && deleteData }

    useEffect(() => {
        docLoad()
    }, [agent])

    // ----- RENDER

    return <>
        <Modal title="ตัดจ่ายตัวแทน" full noCloseBackdrop open={props.id !== null} onClose={props.onClose} onOpenEnd={loadData} onCloseEnd={onClose} {...propMeodal}>
            {wait && <Wait/>}
            {readOnly
                ? <div className="flex it-start">
                    <Input readOnly icon="calendar-alt" label="วันที่ตัดจ่าย" className="w-40" center value={date(form.date)}/>
                    <Input readOnly label="ตัวแทน" className="w-52 ml-4" value={form.agent?.name}/>
                    <Input readOnly label="วิธีชำระ" className="w-52 ml-4" value={PAYWAY_TYPE_OPTIONs.find((d: any) => d.id === form.type)?.name}/>
                    <Input readOnly label="หมายเหตุ" className="ml-4 w-fill" value={form.note}/>
                </div>
                : <div className="flex it-start">
                    <InputDate icon label="วันที่ตัดจ่าย" fixWidth noClear value={form.date} onChange={date => onChange({ date })}/>
                    <Select label="ตัวแทน" className="w-52 ml-4" noClear url={'ac/agent'} value={form.agent} onChange={(v, agent) => onChange({ agent, bank: '' })}/>
                    <Select label="วิธีชำระ" className="w-52 ml-4" noClear options={PAYWAY_TYPE_OPTIONs} value={form.type} onChange={v => onChange({ type: v })}/>
                    <Input label="หมายเหตุ" className="ml-4 w-fill" multiline defValue={form.note} onBlur={note => onChange({ note })}/>
                </div>}

            <Button onClick={() => setRun(p => p + 1)}>{num(run)}</Button>

            <div className="flex -mt-2">
                <div className="form-datatable w-fill">
                    <div className="_header">
                        <span className="_title">รายการ</span>
                        <span className="ml-auto">เลือกแล้ว</span>
                        <strong className="mx-2 red">{num(sum.nodes)}</strong>
                        <span>รายการ , รวมยอดค่าตอบแทน</span>
                        <strong className="mx-2 red">{num(sum.agentMoney, 2)}</strong>
                        <span>บาท, รวมยอดหักภาษี</span>
                        <strong className="mx-2 red">{num(sum.agentMoneyTax, 2)}</strong>
                        <span>บาท</span>
                    </div>

                    <Datagrid wait={wait} wrapText
                              className={'height-300'}
                              name={'account_agent_form'}
                              datas={tableDatas}
                              columns={tableColumns}
                              onReady={onGridReady}
                              onEdited={onEditedDocument}
                              checkbox={['id', checked, onCheckNew]}/>

                </div>
                <div className="w-80 border rounded ml-4 relative flex-on">
                    {revenueLoading && <Wait icon={'asterisk'} color="orange"/>}
                    <PageScroll>{revenueDatas?.map((r: any, x: any) => <div key={'_' + x} className="account-employee-revenue">
                        <div className="_header">
                            <div className="_title">{r.name}</div>
                            <div className="_stats">{num(r.stats.money, 2)} ({num(r.stats.count, 0)})</div>
                            <ol className="_processs">
                                {r.process.map((p: any, x2: any) => <li key={'_' + x2}>
                                    <div className="_name">{p.name}</div>
                                    <div className="_cond-name">{p.condName}</div>
                                    <div className="_result" onClick={() => setRevenueProcess(p)}>
                                        <div className="_stats">{num(p.money, 2)} ({num(p.count, 0)})</div>
                                        <div className="_money">{'~> ' + num(p.moneyProcess, 2)}</div>
                                    </div>
                                </li>)}
                            </ol>
                            {!!r.noGroups && r.noGroups.length > 0 &&
                                <Button m0 sm error fullWidth className="mt-4" onClick={() => onUnCheck(r.noGroups)}>ไม่เข้าเงื่อนไข {r.noGroups.length} รายการ</Button>}
                        </div>
                    </div>)}
                    </PageScroll>
                </div>
            </div>

            <div className="flex flex-row my-4">
                <div className="sumbox ml-auto">
                    <label className="_label">ค่าตอบแทน</label>
                    <span className="_value">{num(sum.agentMoney, 2)}</span>
                </div>
                <div className="sumbox-fm ml-4"><Icon name="minus grey"/></div>
                <div className="sumbox ml-4">
                    <label className="_label">หักภาษี ณ ที่จ่าย</label>
                    <span className="_value">{num(sum.agentMoneyTax, 2)}</span>
                </div>
                <div className="sumbox-fm ml-4"><Icon name="equals grey"/></div>
                <div className="sumbox ml-4">
                    <label className="_label">รวมรายจ่าย</label>
                    <span className="_value">{num(sum.agentMoney - sum.agentMoneyTax, 2)}</span>
                </div>
            </div>
        </Modal>
        <RevenueProcess tax data={revenueProcess} onClose={() => setRevenueProcess(null)}/>
    </>
}
