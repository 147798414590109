import React, { useEffect, useMemo, useState } from 'react'

import { date, dbdate, dConfirm, debounce, dError, get, isEmpty, num, post, qsget, qsset, todate, tSuccess, useUser } from 'unno-comutils'
import { Button, createPaging, Icon, Modal, PageTitle, Paging } from 'unno-comutils/ui'
import { diffDate, isValidDate } from 'unno-comutils/utils'
import { Checkbox, FormContainer, fromRangeParam, Input, InputDateRange, InputSearchRow, Radio, Select, textRange } from 'unno-comutils/form'
import Dropdown, { DropdownItem } from 'unno-comutils/Dropdown'
import Datagrid, { columnIconClick, IDatagridColumns } from 'unno-comutils/Datagrid'

import { hhash, hhashRemove, hhashUpdate } from '../../utils'
import { TextNew } from '../../components/book'
import { ButtonEdit } from '../../components/cell'
import { CUSTOMER_GENDERs, DOCUMENT_RENEW_OPTIONs, DOCUMENT_TYPE_OPTIONs } from '../../service/var'
import { documentChange, documentFormat } from '../../service/book'
import BookView, { PrintView } from './view'
import Paylot from './paylot'

import { FormAttach, FormBook, FormCustomer, FormInsurance, FormPay, FormVehicle, ViewPaylot } from './components'
import { onChangeCustomer } from './components/customer'
import { FormEtc } from './components/etc'
import { calVehicleTax } from './components/vehicle'
import { FormOlddoc } from './components/olddoc'
import { calMoneyInsure } from '../../service/calculator'
import InputSearch from '../../components/test/InputSearch'

export const calAge = (date: any, birth: any) => {
    const d = todate(date)
    if (!isValidDate(d)) return '-'
    const b = todate(birth)
    if (!isValidDate(d)) return '-'
    const valDiff = diffDate(b, d, 'y')
    return !isNaN(valDiff) ? valDiff : '-'
}

export const isAct = (document: any) => (document && document.metaInsuretype && document.metaInsuretype.name.includes('พรบ')) === true

export function dataBookSave (form: any) {

    const vehicleData = {
        brand: form.carBrand,
        generation: form.carGeneration,
        year: form.carYear,
        subGeneration: form.carSubGeneration,
        cc: form.carCc
    }

    const data = {
        state: form.state?.id || 0,

        flag: form.flag || 0,
        date: dbdate(form.date),
        dateStart: dbdate(form.dateStart),
        dateEnd: dbdate(form.dateEnd),
        dateDoc: dbdate(form.dateDoc),
        documentAct: !isAct(form) ? form.documentAct?.id : 0,

        agent: form.agent?.id || 0,
        employee: form.employee?.id || 0,
        company: form.company?.id || 0,

        customer: form.customer?.id || 0,
        customerPay: !form.sameCustomer ? form.customerPay?.id : form.customer?.id || 0,
        customerPaySame: !!form.sameCustomer,

        dateLicenseExpire: dbdate(form.customer?.dateLicenseExpire),

        isNew: form.isNew ? 1 : 0,
        isInsureSend: form.isInsureSend ? 1 : 0,

        lookup: form.lookup,

        workcode: form.workcode,
        note: form.note,

        insuranceNumber: form.insuranceNumber,
        insuranceIssue: form.insuranceIssue,

        metaPaylot: form.metaPaylot?.id || 0,
        metaInsuretype: form.metaInsuretype?.id || 0,
        metaVehicletype: form.metaVehicletype?.id || 0,

        moneyFund: form.moneyFund,
        moneyInsure: form.moneyInsure,
        moneyStamptax: form.moneyStamptax,
        moneyTax: form.moneyTax,
        moneyFee: form.moneyFee,
        moneyInterest: form.moneyInterest,
        moneyDiscount: form.moneyDiscount,
        moneyFine: form.moneyFine,
        moneyDeduct: form.moneyDeduct,

        coverage: form.coverage,
        drivers: !!form.drivers && form.drivers.map((d: any) => ({ name: d.name, birth: dbdate(d.birth) })),
        isDriver: form.isDriver,

        vehicleData,
        vehicleDate: dbdate(form.vehicleDate),
        vehicleNumber: form.vehicleNumber,
        vehicleNumberEngine: form.vehicleNumberEngine,
        vehicleLicense: form.vehicleLicense,
        vehicleProvice: form.vehicleProvice?.id || form.vehicleProvice || 0,
        vehicleFixplace: form.vehicleFixplace,
        vehicleUsage: form.vehicleUsage,
        vehicleAddtion: form.vehicleAddtion,

        vehicleTax: form.vehicleTax,

        vehicleCheckDate: dbdate(form.vehicleCheckDate),
        vehicleCheckNote: form.vehicleCheckNote,

        oldData: form.oldData ? {
            ...form.oldData,
            metaInsuretype: form.oldData.metaInsuretype?.id || form.oldData.metaInsuretype || 0,
            company: form.oldData.company?.id || form.oldData.company || 0,
            dateStart: dbdate(form.oldData.dateStart),
            dateEnd: dbdate(form.oldData.dateEnd)
        } : null,

        photos: form.photos,
        paylots: form.paylots,

        check: form?.check,
        __payMeta: form.__payMeta,
    } as any
    if (form.sideUpdate) data._sideUpdate = form.sideUpdate

    return data
}

export default function Book () {
    const user = useUser()

    const [meta, setMeta] = useState(null)

    const [wait, setWait] = useState(false)
    const [tags, setTags] = useState<any>([])
    const [lists, setLists] = useState([])

    const [searchFrom, setSearchFrom] = useState('car')
    const [search, setSearch] = useState('')

    const [searchDate, setSearchDate] = useState<any>('')
    const [searchAgent, setSearchAgent] = useState<any>('')
    const [searchEmployee, setSearchEmployee] = useState<any>('')
    const [searchNew, setSearchNew] = useState(0)
    const [searchDateEnd, setSearchDateEnd] = useState<any>('')
    const [searchRenew, setSearchRenew] = useState(-1)

    const [formView, setFormView] = useState(null)
    const [fromEdit, setFormEdit] = useState<any>(null)
    const [formMini, setMiniData] = useState<any>(null)
    const [formPrint, setFormPrint] = useState(0)

    const [paging, setPaging] = useState(createPaging(1))

    // ----- ACTION

    const loadList = debounce((page?: any) => {
        if (!page) page = paging
        const qs = {
            page: page?.page || 1,

            search,
            sfrom: searchFrom,

            date: dbdate(searchDate),
            dateEnd: dbdate(searchDateEnd),
            agent: searchAgent?.id,
            employee: searchEmployee?.id,
            isNew: searchNew,
            isRenew: searchRenew,
        }
        setWait(true)
        get('book/list', { ...qs, wait: 0 }).then(({ ok, datas, paging }) => {
            if (ok) {

                const h = hhash()
                if (!h) qsset(qs)

                const tags = []
                if (searchDate) tags.push({ color: 'blue', text: textRange(searchDate), onClear: () => setSearchDate('') })
                if (searchDateEnd) tags.push({ color: 'orange', text: 'วันสิ้นสุด ' + textRange(searchDateEnd), onClear: () => setSearchDateEnd('') })
                if (searchAgent) tags.push({ color: 'grey-blue', icon: 'user-secret', text: searchAgent.name, onClear: () => setSearchAgent('') })
                if (searchEmployee) tags.push({ color: 'red', icon: 'user-tie', text: searchEmployee.name, onClear: () => setSearchEmployee('') })
                if (searchNew) tags.push({ color: 'green', text: DOCUMENT_TYPE_OPTIONs.find(d => d.value === searchNew)?.text })
                if (searchRenew >= 0) tags.push({ color: 'green', text: DOCUMENT_RENEW_OPTIONs.find(d => d.value === searchRenew)?.text })
                setTags(tags)

                setLists(datas)
                setPaging(paging)
            }
        }).finally(() => setWait(false))
    }, 500)

    const dHash = () => {
        const h = hhash()
        if (h && h.type === 'view') setFormView(h.id)
        if (h && h.type === 'edit') setFormEdit(h.id)
    }

    const bookRevoke = (id: any) => {
        dConfirm('ยืนยันเรียกคืนการยกเลิก').then(() => {
            get('revoke/restore/' + id).then(d => {
                if (d.ok) {
                    tSuccess('เรียกคืนการยกเลิกข้อมูลเรียบร้อยแล้ว')
                    setFormView(null)
                    loadList()
                }
            })
        })
    }

    const deleteData = (id: any) => {
        dConfirm('ยืนยันการลบข้อมูลนี้').then(() => {
            get('book/drop/' + id).then(d => {
                if (d.ok) { saveData() }
                else dError(d)
            })
        })
    }

    // ----- EVENT

    const saveData = () => {
        setFormEdit(null)
        setMiniData(null)
        loadList()
    }

    // ----- MEMO

    useEffect(() => {
        const qs = qsget()
        const { agent, employee } = qs

        if (qs.search) setSearch(qs.search)
        setSearchFrom(qs.sfrom || 'car')

        if (qs.page) setPaging(createPaging(num(qs.page)))

        if (agent || employee) {
            get('app/qs', { agent, employee }).then(d => {
                if (d.ok) {
                    if (d.agent) setSearchAgent(d.agent)
                    if (d.employee) setSearchEmployee(d.employee)
                }
            })
        }

        if (qs.date) setSearchDate(fromRangeParam(qs.date))
        if (qs.dateEnd) setSearchDateEnd(fromRangeParam(qs.dateEnd))
        if (qs.isNew) setSearchNew(num(qs.isNew))

        get('app/meta', { meta: 1, company: 1, customer: 1 }).then(({ ok, ...meta }) => { if (ok) { setMeta(meta) } })

        dHash()
        //if (isDev) setFormEdit(117086)
        //if (isDev) setForm(0)
        //if (isDev) setMiniData(147013)

    }, [])

    useEffect(() => {
        loadList()
    }, [searchFrom, searchDate, searchAgent, searchEmployee, searchNew, searchRenew, searchDateEnd])

    const tableDatas = useMemo(() => {
        return lists.map((d: any) => ({
            ...d,

            date: date(d.date),
            dateStart: date(d.dateStart, 'S'),
            dateEnd: date(d.dateEnd, 'S'),

            customer: d.customerName,
            customerAge: !!d.date && !!d.customerPayBirth ? calAge(d.date, d.customerPayBirth) : '-',
            customerGender: !!d.customerPayGender ? (CUSTOMER_GENDERs.find(g => g.id === d.customerPayGender)?.name || '-') : '-',

            agent: d.agentName,
            employee: d.employeeName,
            company: d.companyName,

            metaInsuretype: d.insuretypeName,
            metaVehicletype: d.vehicletypeName,
        }))
    }, [lists])

    const tableColumns = useMemo<IDatagridColumns[]>(() => {

        const columns: IDatagridColumns[] = [
            { name: '_rownum', label: '#', width: 50, style: 'c', pinned: 'left' },
            columnIconClick({ name: 'btn_view', icon: 'search', pinned: 'left', onClick: (_, p: any) => setFormView(p.id) }),
            { name: 'date', label: 'วันที่แจ้งงาน', width: 100, style: 'c', },
            { name: 'customer', label: 'ชื่อลูกค้า', width: 250 },
            user.allow('book.wait.docprint')
            && { name: 'btn_print', label: '', width: 60, renderer: (_: any, d: any) => <Button onClick={() => setFormPrint(d.id)}>พิมพ์</Button> },
            {
                name: 'rooms',
                label: 'ห้องแชท',
                width: 70,
                renderer: (_: any, d: any) => d.rooms.map((room: any) =>
                    <Icon key={'room_' + room.id} className={'mx-1'}
                          name={room.provider.provider === 'fb' ? 'facebook blue' : 'line green'}
                          brand href={'/chat/' + room.id} targetBlank/>)
            },
            { name: 'customerGender', label: 'เพศผู้ผ่อน', width: 70, style: 'c' },
            { name: 'customerAge', label: 'อายุผู้ผ่อน', width: 70, style: 'c' },
            { name: 'customerType', label: 'ประเภทลูกค้า', width: 150, style: 'c' },

            { name: 'employee', label: 'พนักงาน', width: 80, style: 'c' },
            { name: 'agent', label: 'ตัวแทน', width: 80, style: 'c' },

            { name: 'vehicleLicense', label: 'ทะเบียน', width: 120, style: 'c', },
            { name: 'metaInsuretype', label: 'ประเภทประกัน', width: 150, style: 'c' },

            { name: 'carBrand', label: 'ยี่ห้อ', width: 100, style: 'c' },
            { name: 'carGeneration', label: 'รุ่น', width: 150 },
            { name: 'carSubGeneration', label: 'รุ่นย่อย', width: 200 },
            { name: 'carYear', label: 'ปี', width: 70, style: 'c' },

            { name: 'insuranceNumber', label: 'เลขที่กรมธรรม์', width: 200 },
            { name: 'company', label: 'บริษัทประกัน', width: 270 },
            { name: 'dateStart', label: 'เริ่มคุ้มครอง', width: 100, style: 'c' },
            { name: 'dateEnd', label: 'สิ้นสุดคุ้มครอง', width: 100, style: 'c' },
            { name: 'moneyTotal', label: 'เบี้ยประกันภัยรวม', width: 110, style: 'r', format: 'number.2' },
            { name: 'paytype', label: 'วิธีชำระ', width: 80, style: 'c' },
            { name: 'isNew', label: 'ประเภท', width: 80, style: 'c', renderer: (d: any) => <TextNew value={d} hot-renderer/> },
        ].filter(Boolean)

        if (user.allow('book.day.edit')) {
            columns.push({
                name: 'btn_form',
                text: '',
                style: 'c',
                pinned: 'right',
                width: 80,
                renderer: (_: any, d: any) => <ButtonEdit value={d} edit={setFormEdit} mode="mini" editMini={setMiniData} hot-renderer/>
            })
        }

        return columns
    }, [user])

    // ----- RENDER

    const urlParam = qsset({
        search,
        searchMode: searchFrom,
        date: dbdate(searchDate),
        dateEnd: dbdate(searchDateEnd),
        agent: searchAgent?.id,
        employee: searchEmployee?.id,
        isNew: searchNew,
    }, true)

    const filePdf: any = 'file-pdf red'
    const fileSpreadsheet: any = 'file-spreadsheet green'

    return <>
        <PageTitle icon="file-signature" title="สมุดรายวัน">

            <Radio value={searchFrom} options={[{ id: 'car', name: 'ทะเบียนรถ' }, { id: 'customer', name: 'ชื่อลูกค้า' }]} onChange={setSearchFrom}/>

            <InputSearch tags={tags} value={search} onChange={setSearch} onRefresh={() => loadList()} onPressEnter={loadList} refreshButton={{label: 'ตกลง'}}>
                <InputSearchRow label="วันที่แจ้งงาน" children={<InputDateRange icon fixWidth value={searchDate} onChange={v => setSearchDate(v)}/>}/>
                <InputSearchRow label="ตัวแทน" children={<Select value={searchAgent} url="/ac/agent" onChange={(_, v) => setSearchAgent(v)}/>}/>
                <InputSearchRow label="พนักงาน" children={<Select value={searchEmployee} url="/ac/employee" onChange={(_, v) => setSearchEmployee(v)}/>}/>
                <InputSearchRow label="ประเภทงาน" children={<Radio options={DOCUMENT_TYPE_OPTIONs} value={searchNew} onChange={v => setSearchNew(v)}/>}/>
                <InputSearchRow label="วันที่สิ้นสุด" children={<InputDateRange value={searchDateEnd} onChange={d => setSearchDateEnd(d)}/>}/>
                <InputSearchRow label="ต่ออายุ" className="_no-wrap" children={<Radio options={DOCUMENT_RENEW_OPTIONs} value={searchRenew} onChange={v => setSearchRenew(v)}/>}/>
            </InputSearch>

            {user.allow('report.book') && searchDate && <Dropdown right button={<Button secondary icon={'download'} className="ml-4"/>}>
                <DropdownItem icon={filePdf} label={'สรุปสมุดรายวัน'} href={'/d/book.pdf?' + urlParam} targetBlank/>
                <DropdownItem icon={fileSpreadsheet} label={'สรุปสมุดรายวัน'} href={'/d/book.xlsx?' + urlParam} targetBlank/>
                <DropdownItem icon={fileSpreadsheet} label={'ส่งออกสมุดรายวัน'} href={'/a/book/book_export?' + urlParam} targetBlank/>
            </Dropdown>}
        </PageTitle>

        <Datagrid wait={wait} name={'book_data'} datas={tableDatas} columns={tableColumns}/>

        <Paging paging={paging} onChange={loadList}/>

        <BookView id={formView} onClose={() => setFormView(null)} onRevoke={bookRevoke}/>
        <PrintView id={formPrint} isPrint={true} onClose={() => setFormPrint(0)}/>

        {user.allow('book.day.edit') && <>
            <BookEdit meta={meta} id={fromEdit} onSave={saveData} onClose={() => setFormEdit(null)}/>
            <BookEditMini id={formMini} onSave={saveData} onClose={() => setMiniData(null)}/>
        </>}
    </>
}

export function BookEdit (props: any) {
    const { id, meta } = props

    const user = useUser()

    const [paylotData, setPaylotData] = useState(null)
    const [document, setDocument] = useState<any>(null)
    const [sideUpdate, setSideUpdate] = useState<any>(null)

    // ----- ACTION

    const loadData = () => {
        get('book/get/' + id).then(({ ok, data }) => {
            if (ok) {
                documentFormat(data)
                data.__paylotCount = data.paylotCount
                setDocument((prev: any) => ({ ...prev, ...data }))
                setSideUpdate(null)
                hhashUpdate('edit,' + data.id)
            }
            else {
                if (id == 0) {
                    setDocument({})
                }
                else {
                    props.onClose()
                }
            }
        })
    }

    const saveData = (c: any) => {
        let data = document
        data.sideUpdate = sideUpdate
        const saveData = dataBookSave(data)
        //console.log('saveData -> ', saveData)
        //return c()
        post('book/update/' + id, { ...saveData, verify: 'verify_book' }).then(d => {
            if (d.ok) {
                tSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
                props.onSave(d.id)
            }
            else dError(d)
        }).finally(c)

    }

    const deleteData = () => {
        dConfirm('ยืนยันการลบข้อมูลนี้').then(() => {
            get('book/drop/' + id).then(d => {
                if (d.ok) props.onSave(d.id)
                else dError(d)
            })
        })
    }

    // ----- EVENT

    const onEditPaylot = (id: any) => setPaylotData(id)

    const onChange = (update: any, method = '') => {
        setDocument((prev: any) => !!method ? documentChange(prev, update, method, {
            COMPANYs: props.meta.COMPANYs,
            formDate: prev.date
        }) : { ...prev, ...update })
    }

    const onClose = () => {
        hhashRemove()
        setDocument(null)
    }

    const onPaylotSave = (id: any) => {
        loadData()
        setPaylotData(null)
    }

    // ----- RENDER

    return <>
        <Modal icon={'pencil'} title={'แก้ไขเอกสาร'} xl open={id !== null} onClose={() => props.onClose()}
               onOpenEnd={loadData} onCloseEnd={() => onClose()} footerSave={saveData} footerDrop={deleteData}>
            <FormBook document={document} onChange={onChange}/>
            <FormCustomer document={document} onChange={onChange} onChangeCustomer={(customer: any) => onChangeCustomer(customer, setSideUpdate)}/>
            <FormVehicle document={document} onChange={onChange}/>
            <FormOlddoc oldData={document?.oldData} onChange={onChange}/>
            <FormInsurance document={document} onChange={onChange}/>
            <FormPay document={document} onChange={onChange}/>
            <ViewPaylot document={document} datas={document?.paylots || []} onEdit={user.allow('document.paylot.edit') ? onEditPaylot : null}/>
            <FormAttach document={document} meta={meta} onChange={onChange}/>
            <FormEtc document={document} meta={meta} onChange={onChange}/>
        </Modal>

        <Paylot id={paylotData} onSave={onPaylotSave} onClose={() => setPaylotData(null)}/>
    </>
}

export function BookEditMini (props: any) {
    const [document, setDocument] = useState<any>({})

    // ----- ACTION

    const loadData = () => {
        if (props.id > 0) {
            get('book/get/' + props.id).then(({ ok, data }) => {
                if (ok) {
                    setDocument({
                        id: data.id,
                        dateDoc: data.dateDoc,
                        documentAct: data.documentAct,
                        insuranceNumber: data.insuranceNumber,
                        photos: data.photos,
                        vehicleTax: data.vehicleTax,
                        vehicleNumber: data.vehicleNumber,

                        carBrand: data.carBrand,
                        carGeneration: data.carGeneration,
                        carSubGeneration: data.carSubGeneration,
                        carYear: data.carYear,
                        carCc: data.carCc,

                        note: data.note,
                        isInsureSend: data.isInsureSend,
                        moneyInsure: data.moneyInsure,
                        moneyStamptax: data.moneyStamptax,
                        moneyTax: data.moneyTax,
                        metaInsuretype: data.metaInsuretype,
                        vehicleLicense: data.vehicleLicense
                    })
                }
                else props.onClose()
            })
        }
        else setDocument({})
    }

    const saveData = (c: any) => {
        const doc = {
            id: document.id,
            dateDoc: dbdate(document.dateDoc),
            documentAct: !isAct(document) ? document.documentAct?.id : 0,
            insuranceNumber: document.insuranceNumber,
            photos: document.photos,
            vehicleTax: document.vehicleTax,
            vehicleNumber: document.vehicleNumber,
            note: document.note,
            isInsureSend: document.isInsureSend ? 1 : 0,
            moneyInsure: document.moneyInsure,
            moneyStamptax: document.moneyStamptax,
            moneyTax: document.moneyTax,
        }
        post('book/update_mini/' + doc.id, doc, { to: 'number' }).then(d => {
            if (d.ok) {
                tSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
                props.onSave(d.id)
            }
            else dError(d)
        }).finally(c)
    }

    const checkVehicleNumber = debounce((number) => {
        if (isEmpty(number)) {
            onChange({ isNew: true })
        }
        else {
            get('book/check', { id: document.id, vehicleNumber: number, metaInsuretype: document.metaInsuretype?.id || document.metaInsuretype || 0 }).then(async d => {
                if (d.ok) {
                    onChange({ isNew: d.isNew })
                }
                else {
                    onChange({ isNew: true })
                }
            })
        }
    }, 3000)

    // ----- EVENT

    const onChange = (update: any) => setDocument({ ...document, ...update })

    const onVehicleNumber = (number: any) => {
        onChange({ vehicleNumber: number })
        checkVehicleNumber(number)
    }

    const onVehicleChangeTax = (value: boolean, document: any) => {
        onChange({ vehicleTax: value ? (document.carCc ? calVehicleTax({ type: 1, cc: document.carCc, weight: '', year: document.carYear }) : 0) : 0 })
    }

    // ----- RENDER

    return <Modal className="book-edit-mini-form" title={'แก้ไขข้อมูลกรมธรรม์'} lg noCloseBackdrop open={props.id !== null} onClose={props.onClose}
                  onOpenEnd={loadData} onCloseEnd={() => setDocument({})} footerSave={saveData} footerDrop={() => props.onDrop(props.id)}>

        <FormContainer collapse={'book_vehicle'} label={'ข้อมูลรถ'} className={'docform-vehicle'}>
            <Input label="เลขตัวถัง" classBox="w-1/3" value={document.vehicleNumber} onChange={onVehicleNumber}/>
        </FormContainer>

        <FormContainer collapse={'book_insurance'} label={'ข้อมูลประกันภัย'} className={'docform-insurance'} classControl="row">
            <Input label={'เลขที่กรมธรรม์'} classBox={'col w-1/4'} value={document.insuranceNumber || ''} onChange={v => onChange({ insuranceNumber: v })}/>
            <div className="col w-1/4 flex-on">
                <Checkbox text={'ภาษี'} checked={document.vehicleTax > 0} onChange={v => onVehicleChangeTax(v, document)}/>
                {document.vehicleTax > 0 &&
                    <Input right className={'ml-1 w-fill'} type={'number'} apppend={'บาท'} value={document.vehicleTax} onChange={v => onChange({ vehicleTax: v })}/>}
            </div>

            {!isAct(document) && <Select classBox="col w-2/4" label={'พรบ.'} url={`ac/document_act/${document.id}?license=${document.vehicleLicense}`}
                                         renderText={(d: any) => `(${date(d.date, 'S')}) ${d.metaInsuretype?.name} , ${d.vehicleLicense} , ${d.insuranceNumber}`}
                                         value={document.documentAct} onChange={(_, v) => onChange({ documentAct: v })}/>}
        </FormContainer>

        <FormContainer collapse={'book_pay'} label={'การชำระเงิน'} className={'docform-pay'}>
            <div className="row">
                <Input key={'mt_' + document.moneyInsure} label="เบี้ยประกันภัยสุทธิ" classBox="col w-2/12 un-input-text-mb" unit="บาท" right defValue={document.moneyInsure}
                       onBlur={v => onChange(calMoneyInsure({ ...document, moneyInsure: v }))}/>

                <Input readOnly label="อากร (0.4%)" classBox="col w-2/12" unit="บาท" right value={num(document.moneyStamptax, 2)}/>
                <Input readOnly label="ภาษี (7%)" classBox="col w-2/12" unit="บาท" right value={num(document.moneyTax, 2)}/>
            </div>

            <Input label="หมายเหตุ" multiline={3} value={document.note} onChange={v => onChange({ note: v })}/>
        </FormContainer>

        <FormAttach document={document} onChange={onChange}/>

        <FormContainer collapse={'book_etc'} label={'อื่นๆ'} className={'docform-etc'}>
            <Checkbox m0 text={'บริษัทประกันจัดส่งกรมธรรม์'} className={'ml-3'} checked={document.isInsureSend} onChange={v => onChange({ isInsureSend: v })}/>
        </FormContainer>

    </Modal>
}
