import React, { useMemo } from 'react'

import { Checkbox, FormContainer, Select } from 'unno-comutils/form'
import { Button } from 'unno-comutils/ui'
import { dConfirm, dError, get, post } from 'unno-comutils'
import { CustomerBookForm } from '../../customer'
import { visBlock } from '../wait'

export function onChangeCustomer (customer: any, stateSideUpdate: (prev: any) => void) {
    customer.job = customer.job?.id || customer.job || 0
    customer.type = customer.type?.id || customer.type || 0

    stateSideUpdate((prev: any) => {
        if (!prev) prev = {}
        if (!prev.customers) prev.customers = []
        prev.customers.some((c: any) => (c.id !== undefined && c.id === customer.id) || (c._create !== undefined && c._create === customer._create))
            ? prev.customers = prev.customers.map((c: any) => (c.id !== undefined && c.id === customer.id) || (c._create !== undefined && c._create === customer._create) ? customer : c)
            : prev.customers.push(customer)
        return prev
    })
}

export function FormCustomer (props: { document: any, onChange: any, onChangeCustomer: (d: any) => void, force?: boolean, wait?: boolean }) {
    const { document, force, onChange, onChangeCustomer } = props

    const onCustomer = (customer: any, met: any) => {
        if (met === 'customer' || met === 'customerPay') {
            get('customer/get/' + customer?.id).then(d => {
                if (d.ok) {
                    onChange({ [met]: d.data })
                }
            })
        }
    }

    const onCustomerEdit = (update: any, met: any) => {
        if (met === 'customer') {
            const cUpdate = { ...document.customer, ...update }
            onChangeCustomer(cUpdate)
            onChange({ ...document, customer: cUpdate })
        }
        else {
            const cUpdate = { ...document.customerPay, ...update }
            onChangeCustomer(cUpdate)
            onChange({ ...document, customerPay: cUpdate })
        }
    }

    const onCustomerSave = (type: string) => {
        dConfirm('ยืนยันการสร้าง ลูกค้าใหม่').then(() => {
            const customer = { name: 'ลูกค้าที่ยังไม่ระบุชื่อ' } as any
            post('customer/create/', customer).then(d => {
                if (d.ok) {
                    customer.id = d.id
                    customer.number = 'C' + d.id
                    onChange({ [type]: customer }, type)
                }
                else dError(d)
            })
        })
    }

    // ----- MEMO

    const visibleBlock = useMemo(() => !props.wait || visBlock(document), [document])

    // ----- RENDER

    if (!document) return <></>

    return <FormContainer collapse={'book_customer'} label={'ข้อมูลผู้ทำประกัน'}
                          headerCollapse={<div className="un-form-header-text">
                              <span className="mr-2">{document.customer?.name}</span>
                              {document.sameCustomer ? <span className="blue">ลูกค้าเป็นผู้ผ่อน</span> : (document.customerPay &&
                                  <span className="blue">ผู้ผ่อน {document.customerPay?.name}</span>)}
                          </div>}>
        <div className="docform-customer">
            <div className="docform-customer-form">
                <div className="_header">
                    <div className="_label">ลูกค้า</div>
                    {!force && <>
                        <div className="_div"/>
                        <Select m0 url={'ac/customer'} className="w-fill" value={document.customer} onChange={(_, v) => onCustomer(v, 'customer')}/>
                        <Button m0 icon="plus" success className="btn-icon ml-2" onClick={() => onCustomerSave('customer')}/>
                    </>}
                </div>
                {(document.customer?.number || force) && (visibleBlock
                    ? <CustomerBookForm customer={document.customer || {}} onChange={(v: any) => onCustomerEdit(v, 'customer')}/>
                    : <CustomerBookView customer={document.customer || {}}/>)}
            </div>
            <div className="docform-customer-form">
                <div className="_header">
                    <div className="_label">ผู้ผ่อน</div>
                    <Checkbox m0 text="เหมือนลูกค้า" className="mr-2" checked={document.sameCustomer || false} onChange={v => onChange({ sameCustomer: !!v })}/>
                    {!document.sameCustomer && !force && <>
                        <div className="_div"/>
                        <Select m0 url={'ac/customer'} className="w-fill" value={document.customerPay} onChange={(_, v) => onCustomer(v, 'customerPay')}/>
                        <Button m0 icon="plus" success className="btn-icon ml-2" onClick={() => onCustomerSave('customerPay')}/>
                    </>}
                </div>
                {!document.sameCustomer && (document.customerPay?.number || force) && (visibleBlock
                    ? <CustomerBookForm customer={document.customerPay || {}} onChange={(v: any) => onCustomerEdit(v, 'customerPay')}/>
                    : <CustomerBookView customer={document.customerPay || {}}/>)}
            </div>
        </div>
    </FormContainer>
}

function CustomerBookView (props: any) {
    const { customer } = props
    if (!customer) return <></>

    return <div className="document-customer-view">
        <div className="_item">
            <div className="_name">ชื่อ</div>
            <div className="_value">{customer.name}</div>
        </div>
    </div>
}