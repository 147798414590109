import React, { useMemo } from 'react'
import { FormContainer, InputDate, Select } from 'unno-comutils/form'
import { DOCUMENT_STATEs } from '../../../service/var.meta'

export function FormBook (props: { document: any, onChange: (d: any) => void, noExpress?: boolean, wait?: boolean }) {
    const { document, onChange } = props

    const documentStates = useMemo(() => DOCUMENT_STATEs.filter((o: any) => o.id < 100) || [], [])

    if (!document) return <></>

    return <FormContainer collapse={'book_book'} label={'ข้อมูลสมุดรายวัน'} className={'docform-book'}>
        <div className="flex docform-book-input">
            <InputDate icon noClear fixWidth label="วันที่" value={document.date} onChange={v => onChange({ date: v })}/>
            <Select label="ตัวแทน" url={'ac/agent'} className="ml-4 w-1/6" value={document.agent} onChange={(id, data) => onChange({ agent: data })}/>
            <Select label="พนักงาน" url={'ac/employee'} className="ml-4 w-1/6" value={document.employee} onChange={(id, data) => onChange({ employee: data })}/>
            {!props.noExpress && <Select label="ใบเสร็จเร่งด่วน" className={'w-2/6 ml-auto'} url={'ac/document_express'}
                                         value={document.express} onChange={(_, data) => onChange({ express: data, customer: data?.customer })}/>}
        </div>
        {!!props.wait && <div className="docform-book-input">
            <Select label="สถานะ" noClear options={documentStates} value={document.state} onChange={(_, data) => onChange({ state: data })}/>
        </div>}
    </FormContainer>
}
